import React, { useContext, useEffect } from "react";
import "./UpdateDeliverableModal.css";
import { useForm } from "react-hook-form";
import { updateDeliverableApi } from "../../../../../../api/FolderApi";
import Input from "../../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import AppContext from "../../../../../../context/AppContext";

function UpdateDeliverableModal({ deliverable, defaultValue, onSuccess = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliverableName: deliverable.name,
    },
  });
  const { setModalVisible, createNotification } = useContext(AppContext);

  useEffect(() => {
    setFocus("deliverableName");
  }, [setFocus]);

  async function onSubmit(data) {
    try {
      await updateDeliverableApi(deliverable.uuid, data.deliverableName);

      createNotification(<>Le livrable a été modifié avec succès</>);

      onSuccess();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la modification du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='info-update-livrable'>
        <p>Modifiez le nom du livrable.</p>
      </div>
      <Input
        useForm={{ register, setValue }}
        error={errors.deliverableName?.message}
        name='deliverableName'
        label='Nom du livrable'
        placeholder='Nouveau nom du livrable'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Enregistrer
        </FilledButton>
      </div>
    </form>
  );
}

export default UpdateDeliverableModal;
