import { Link } from "react-router-dom";
import { useResources } from "../../../context/ResourcesContext";
import DropdownMenu from "../../molecules/dropdownMenu/DropdownMenu";
import Checkbox from "../../molecules/formComponents/checkbox/Checkbox";
import "./ResourceTable.css";
import React, { useEffect, useRef, useState } from "react";
import Copy from "../../atoms/icons/account/copy/Copy";
import Shop from "../../atoms/icons/header/shop/Shop";
import Factory from "../../atoms/icons/header/factory/Factory";
import Building from "../../atoms/icons/header/building/Building";
import Unarchive from "../../atoms/icons/account/unarchive/Unarchive";
import Archive from "../../atoms/icons/account/archive/Archive";
import Check from "../../atoms/icons/general/check/Check";
import ProgressCircle from "../../atoms/progressCircle/ProgressCircle";
import Toggle from "../../molecules/formComponents/toggle/Toggle";
import Bracket from "../../atoms/icons/general/bracket/Bracket";

function ResourceTable({
  tableColumns,
  resources,
  resourceTypeDescription,
  openResourceInNewTab,
  resetOrderByTrigger,
  noResultText = "Aucun résultat",
  useCheckbox,
  checkboxesArray,
  setCheckboxesArrayFunction,
  onResourceClick,
  dropdownMenu, // let null if not used
  canDropdownMenuOverlapContainer,
  selectedResource, // used only with dropdownMenu
  useBrackets = true,
  setSelectedResourceFunction = () => {}, // used only with dropdownMenu
  getColumnContentFunction = () => {},
  getOrderedResourcesFunction = resources => resources.map(resource => resource),
  toggleVisitingCardDefaultFunction = () => {}, // used only width visitingCards
  className = "",
}) {
  const resourcesContainerRef = useRef(null);
  const { getResourceLink, copyUuidToClipboard } = useResources();

  const [orderBy, setOrderBy] = useState({
    key: null,
    order: null,
  });

  useEffect(() => {
    setOrderBy({
      key: null,
      order: null,
    });
  }, [resetOrderByTrigger]);

  function toggleColumnBracket(key) {
    if (orderBy.key === key) {
      if (orderBy.order === "asc") return setOrderBy({ key, order: "desc" });

      return setOrderBy({ key: null, order: null });
    }

    setOrderBy({ key, order: "asc" });
  }

  function getItemTypeClassName(type) {
    switch (type?.id) {
      case 1:
        return "blue-text";
      case 2:
        return "grey-text";
      case 3:
        return "gold-text";
    }
  }

  return (
    <div className={`crm-table-container ${className}`}>
      <div className='crm-thead'>
        {useCheckbox && <Checkbox name='checkbox-all' className='crm-checkbox' />}
        {tableColumns?.map(
          column =>
            column.visible && (
              <div
                className={`crm-column-${column.key} ${column.showBracket ? "cursor-pointer" : ""}`}
                key={column.id}
                {...(column.showBracket && { onClick: () => toggleColumnBracket(column.key) })}>
                {column.label}
                {column.showBracket && useBrackets && (
                  <Bracket
                    rotation={orderBy.key == column.key ? (orderBy.order === "asc" ? "180deg" : "0deg") : "90deg"}
                    width='12px'
                    className='ml-xs'
                    color='var(--blue)'
                  />
                )}
              </div>
            ),
        )}
        {dropdownMenu && <div className='crm-column-actions'>Actions</div>}
      </div>
      {dropdownMenu && (
        <DropdownMenu
          canDropdownMenuOverlapContainer={canDropdownMenuOverlapContainer}
          itemsContainerRef={resourcesContainerRef}
          selectedResource={selectedResource}
          closeDropdown={() => setSelectedResourceFunction(null)}>
          {dropdownMenu}
        </DropdownMenu>
      )}
      <div className='crm-tbody' ref={resourcesContainerRef}>
        {!getOrderedResourcesFunction(resources, orderBy)?.length && (
          <p className='centered text-lg m-auto'>{noResultText}</p>
        )}
        {getOrderedResourcesFunction(resources, orderBy)?.map((item, index) => (
          <Link
            {...(onResourceClick && { onClick: e => onResourceClick(e, item) })}
            {...(resourceTypeDescription !== "visitingCards" && openResourceInNewTab && { target: "_blank" })}
            className='crm-table-row'
            key={item.uuid}
            to={getResourceLink(item, resourceTypeDescription)}>
            {useCheckbox && (
              <Checkbox
                className='crm-checkbox crm-clickable-column'
                checked={checkboxesArray?.includes(item.uuid)}
                onChange={e => setCheckboxesArrayFunction(e, item)}
              />
            )}
            {tableColumns?.map(column => {
              if (!column.visible) return null;
              switch (column.key) {
                case "uuid":
                  return (
                    <div
                      key={column.id}
                      className='crm-row-column crm-column-uuid crm-clickable-column'
                      onClick={e => copyUuidToClipboard(e, item.uuid)}>
                      <Copy width='18px' color='var(--white)' onClick={e => copyUuidToClipboard(e, item.uuid)} />
                    </div>
                  );
                case "type":
                  return (
                    <div
                      title={item.type?.description.split(" ")[0]}
                      className={`crm-row-column crm-tag crm-column-${column.key} crm-${getItemTypeClassName(item.type)}`}
                      key={column.id}>
                      {item.type?.id === 1 ? (
                        <Shop width='20px' color='#b9bafd' className='mr-sm' />
                      ) : item.type?.id === 2 ? (
                        <Factory width='20px' color='#cac8c8' className='mr-sm' />
                      ) : (
                        <Building width='20px' color='var(--light-gold)' className='mr-sm' />
                      )}
                      {item.type?.description.split(" ")[0]}
                    </div>
                  );
                case "archived":
                  return (
                    <div className={`crm-row-column crm-tag crm-column-archive`} key={column.id}>
                      {item.archived ? (
                        <Unarchive width='20px' color='var(--red)' />
                      ) : (
                        <Archive width='20px' color='var(--green)' />
                      )}
                    </div>
                  );
                case "validated":
                  return item.steps_status?.completed_steps === item.steps_status?.total_steps ? (
                    <div className='crm-row-column crm-column-archive crm-estimation-validated' key={column.id}>
                      <Check width='11px' color='var(--white)' />
                    </div>
                  ) : (
                    <ProgressCircle
                      key={column.id}
                      className='crm-row-column crm-tag crm-clickable-columncrm-column-archive'
                      totalSteps={item.steps_status?.total_steps}
                      currentStep={item.steps_status?.completed_steps}
                    />
                  );
                case "is_default":
                  return (
                    <div
                      className='crm-row-column crm-column-is_default'
                      onClick={e => toggleVisitingCardDefaultFunction(e, item)}
                      key={column.id}>
                      <Toggle checked={item.is_default} />
                    </div>
                  );
                default:
                  return (
                    <div
                      className={`crm-row-column crm-column-${column.key}`}
                      key={column.id}
                      title={getColumnContentFunction(column.key, item)}>
                      {getColumnContentFunction(column.key, item)}
                    </div>
                  );
              }
            })}
            {dropdownMenu && (
              <div
                className='crm-column-actions crm-clickable-column'
                onClick={e => setSelectedResourceFunction(e, item, index, resourcesContainerRef)}>
                <Bracket width='14px' rotation={selectedResource?.item?.uuid === item.uuid ? "0deg" : "180deg"} />
              </div>
            )}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default ResourceTable;
