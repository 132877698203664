import React, { useState, useEffect, useContext } from "react";
import "./FolderDetail.css";
import { archiveFolderApi } from "../../../../../api/FolderApi";
import AppContext from "../../../../../context/AppContext";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Folder from "../../../../../components/atoms/icons/folder/folder/Folder";
import Deliverable from "../../../../../components/atoms/icons/folder/deliverable/Deliverable";
import FolderInfo from "./tabs/folderInfo/FolderInfo";
import FolderDeliverables from "./tabs/folderDeliverables/FolderDeliverables";
import Emc from "../../../../../components/atoms/icons/header/emc/Emc";
import Rapport from "../../../../../components/atoms/icons/header/rapport/Rapport";
import Dashboard from "../../../../../components/atoms/icons/folder/dashboard/Dashboard";
import Chart from "../../../../../components/atoms/icons/header/chart/Chart";
import { useParams } from "react-router-dom";
import CrmResourcesMenu from "../../../../../components/crmComponents/crmResourcesMenu/CrmResourcesMenu";
import Loader from "../../../../../components/organisms/loader/Loader";
import FolderResources from "./tabs/folderResources/FolderResources";
import { useResources } from "../../../../../context/ResourcesContext";

function FolderDetail() {
  const tabs = [
    {
      label: "Contenu du dossier",
      Icon: Dashboard,
      name: "info",
    },
    {
      label: "Estimations",
      Icon: Emc,
      name: "estimations",
    },
    {
      label: "Plans de financement",
      Icon: Rapport,
      name: "financingPlans",
    },
    {
      label: "Simulations de prêt",
      Icon: Chart,
      name: "creditSimulations",
    },
    {
      label: "Livrables",
      Icon: Deliverable,
      name: "deliverables",
    },
  ];
  const { createNotification } = useContext(AppContext);
  const { fetchUserSingleResource } = useResources();
  const { tabParam, subTabParam } = useParams();

  const [folder, setFolder] = useState(null);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [folderUpdateTrigger, setFolderUpdateTrigger] = useState(0);

  useEffect(() => {
    if (tabParam === "mes-dossiers") fetchFolder(subTabParam);
  }, [tabParam, subTabParam, folderUpdateTrigger]);

  async function fetchFolder(folderUuid) {
    const folder = await fetchUserSingleResource(folderUuid, "folders");

    setFolder(folder);
    setIsLoaderVisible(false);
  }

  async function archiveFolder(folderId, archive) {
    try {
      await archiveFolderApi(folderId, archive);
      await fetchFolder(subTabParam);

      createNotification(<>Le dossier a été {archive ? "archivé" : "désarchivé"} avec succès</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {archive ? "de l'archivage" : "du désarchivage"} du dossier. Veuillez réessayer
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function selectTab(tabName) {
    setActiveTab(tabs.find(tab => tab.name === tabName));
  }

  return (
    <section className='container'>
      <div className='crm-client-view-container'>
        <CrmResourcesMenu
          title={
            <>
              <Folder />
              {folder?.name}
            </>
          }>
          {tabs.map(tab => (
            <div
              key={tab.name}
              className={`crm-resource-menu-item ${activeTab.name === tab.name ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}>
              <tab.Icon width='23px' />
              {tab.label}
            </div>
          ))}

          <FilledButton
            bgColor='#FDB7A9'
            color='var(--dark-blue)'
            hoverColor='#FDB7A9'
            className='w-100 mt-lg'
            onClick={() => archiveFolder(subTabParam, !folder?.archived)}>
            {folder?.archived ? "Désarchiver le dossier" : "Archiver le dossier"}
          </FilledButton>
        </CrmResourcesMenu>
        <div className='crm-items-container folder-items-container'>
          <Loader visible={isLoaderVisible} className='folder-loader' preventScrolling={false} />
          {activeTab.name === "info" ? (
            <FolderInfo
              folder={folder}
              triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              selectTab={selectTab}
            />
          ) : activeTab.name === "deliverables" ? (
            <FolderDeliverables folder={folder} triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)} />
          ) : (
            <FolderResources
              folder={folder}
              resources={folder ? folder[activeTab.name] : []}
              activeTab={activeTab}
              triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
            />
          )}
        </div>
      </div>
      {/* {folder && (
        <div className='folder-detail-page-container'>
          <div className='folder-content'>
            {selectedTab === "info" ? (
              <FolderInfo
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
                setSelectedTab={setSelectedTab}
              />
            ) : selectedTab === "estimations" ? (
              <FolderEstimations folder={folder} triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)} />
            ) : selectedTab === "financingPlan" ? (
              <FolderFinancingPlan
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              />
            ) : selectedTab === "creditSimulation" ? (
              <FolderCreditSimulation
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              />
            ) : (
              <FolderDeliverables
                folder={folder}
                triggerFolderUpdate={() => setFolderUpdateTrigger(prev => prev + 1)}
              />
            )}
          </div>
        </div>
      )} */}
    </section>
  );
}

export default FolderDetail;
