import { useWindowSize } from "@uidotdev/usehooks";
import { useResources } from "../../../context/ResourcesContext";
import Hr from "../../molecules/hr/Hr";
import {
  CreditSimulationDropdownMenu,
  CreditSimulationTableColumns,
  filterCreditSimulations,
  getCreditSimulationColumnContent,
} from "../resourcesComponents/CreditSimulationsComponents";
import {
  EstimationsDropdownMenu,
  EstimationTableColumns,
  filterEstimations,
  getEstimationColumnContent,
} from "../resourcesComponents/EstimationsComponents";
import {
  filterFinancingPlans,
  FinancingPlanDropdownMenu,
  FinancingPlanTableColumns,
  getFinancingPlanColumnContent,
} from "../resourcesComponents/FinancingPlansComponents";
import ResourceTable from "../resourceTable/ResourceTable";
import "./DeliverableContent.css";
import React, { useContext, useEffect, useState } from "react";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import CreateDeliverableModal from "../../forms/modals/account/createDeliverableModal/CreateDeliverableModal";
import AppContext from "../../../context/AppContext";

function DeliverableContent({ deliverable, folder, triggerFolderUpdate = () => {} }) {
  const {
    downloadResource,
    showRemoveAllResourcesFromDeliverableModal,
    formatTableColumns,
    isResourceMasculine,
    showRemoveResourceFromDeliverableModal,
    getResourceName,
    getTypesTable,
  } = useResources();
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const resourceTypes = getTypesTable([2, 3, 4]);
  const tabComponents = {
    tableColumns: {
      estimations: EstimationTableColumns,
      financingPlans: FinancingPlanTableColumns,
      creditSimulations: CreditSimulationTableColumns,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
    },
    filter: {
      estimations: filterEstimations,
      financingPlans: filterFinancingPlans,
      creditSimulations: filterCreditSimulations,
    },
    dropdownMenu: {
      estimations: EstimationsDropdownMenu,
      financingPlans: FinancingPlanDropdownMenu,
      creditSimulations: CreditSimulationDropdownMenu,
    },
  };
  const { width } = useWindowSize();

  const [dropdownItems, setDropdownItems] = useState({});
  const [tableColumns, setTableColumns] = useState(null);

  useEffect(() => {
    const result = {};

    resourceTypes.forEach(
      type =>
        (result[type.description] = formatTableColumns(tabComponents.tableColumns[type.description], width).filter(
          column => column.key !== "archived",
        )),
    );

    setTableColumns(result);
  }, [width]);

  function downloadItemPdf(resource, typeDescription) {
    downloadResource(resource, typeDescription);
  }

  function setDropdownItem(key, value) {
    setDropdownItems(prev => ({ ...prev, [key]: value }));
  }

  function setActiveItem(key, e, item, index, itemsContainerRef) {
    if (!e) return setDropdownItem(key, null);

    e.stopPropagation();
    e.preventDefault();

    if (item === dropdownItems[key]?.item) return setDropdownItem(key, false);

    setDropdownItem(key, {
      item: item,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 60,
    });
  }

  function getTypeTitle(type) {
    let result = getResourceName(type.description, true);
    result = result[0].toUpperCase() + result.slice(1);

    return <>{result} du livrable&nbsp;:</>;
  }

  function showImportResourcesModal(type) {
    const tmpFolder = { ...folder };
    const deliverableResourceUuids = deliverable[type.description].map(resource => resource.uuid);

    tmpFolder[type.description] = tmpFolder[type.description].filter(
      resource => !deliverableResourceUuids.includes(resource.uuid),
    );

    setModalContent({
      title: "Importer des " + getResourceName(type.description),
      content: (
        <CreateDeliverableModal
          folder={tmpFolder}
          onSuccess={triggerFolderUpdate}
          deliverableUuid={deliverable.uuid}
          isUpdate={true}
          updatingTypes={[type.index]}
        />
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  return (
    <div className='deliverable-content-container'>
      {tableColumns &&
        resourceTypes.map(type => (
          <div className='deliverable-type-container' key={type.index}>
            <Hr />
            {getTypeTitle(type)}
            <div className='deliverable-type-content'>
              {deliverable[type.description].length ? (
                <ResourceTable
                  tableColumns={tableColumns[type.description]}
                  dropdownMenu={tabComponents.dropdownMenu[type.description]({
                    openInNewTab: true,
                    activeItem: dropdownItems[type.description]?.item,
                    downloadItemPdf: resource => downloadItemPdf(resource, type.description),
                    showRemoveResourceFromDeliverableModal: resource =>
                      showRemoveResourceFromDeliverableModal(
                        resource,
                        deliverable.uuid,
                        type.description,
                        triggerFolderUpdate,
                      ),
                  })}
                  setSelectedResourceFunction={(...params) => setActiveItem(type.description, ...params)}
                  canDropdownMenuOverlapContainer={true}
                  useBrackets={false}
                  resources={deliverable[type.description]}
                  openResourceInNewTab
                  resourceTypeDescription={type.description}
                  selectedResource={dropdownItems[type.description]}
                  getColumnContentFunction={tabComponents.getColumnContent[type.description]}
                />
              ) : (
                <p className='centered'>
                  {`Aucun${isResourceMasculine(type.description) ? "" : "e"} ${getResourceName(type.description)} associé${isResourceMasculine(type.description) ? "" : "e"}`}
                </p>
              )}
              <div className='deliverable-type-buttons'>
                <UnfilledButton className='add-estimation-button' onClick={() => showImportResourcesModal(type)}>
                  Ajouter un{isResourceMasculine ? "" : "e"} {getResourceName(type.description)}
                </UnfilledButton>
                {deliverable[type.description].length > 0 && (
                  <UnfilledButton
                    className='add-estimation-button'
                    bgColor='var(--dark-red)'
                    onClick={() =>
                      showRemoveAllResourcesFromDeliverableModal(
                        deliverable.uuid,
                        type.description,
                        triggerFolderUpdate,
                      )
                    }>
                    Supprimer tou{isResourceMasculine ? "" : "te"}s les {getResourceName(type.description, true)} du
                    livrable
                  </UnfilledButton>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default DeliverableContent;
