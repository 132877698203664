import "./ConseilsProsArticle.css";
import React, { useEffect, useState, useRef, useContext, Fragment } from "react";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";
import { Link, useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../../components/molecules/pageTitle/PageTitle";
import DOMPurify from "dompurify";
import ReactDOMServer from "react-dom/server";
import HtmlToReact, { Parser } from "html-to-react";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import Cross from "../../../components/atoms/icons/general/cross/Cross";
import RapportsColorsCTA from "../../../components/atoms/icons/conseilsPros/rapportsColorsCTA/RapportsColorsCTA";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import { useWindowScroll, useWindowSize } from "@uidotdev/usehooks";
import ExpendableGroup from "../../../components/organisms/expendableGroup/ExpendableGroup";
import Eye from "../../../components/atoms/icons/general/eye/Eye";
import PDF from "../../../assets/reportExamples/exemple_rapport_fdc.pdf";
import Check from "../../../components/atoms/icons/general/check/Check";
import Linkedin from "../../../components/atoms/icons/share/linkedin/Linkedin";
import Facebook from "../../../components/atoms/icons/share/facebook/Facebook";
import Twitter from "../../../components/atoms/icons/share/twitter/Twitter";
import Email from "../../../components/atoms/icons/share/email/Email";
import InfiniteThumbnailsRow from "../../../components/organisms/infiniteThumbnailsRow/InfiniteThumbnailsRow";
import AppContext from "../../../context/AppContext";
import { fetchAllConseilsProsApi, fetchConseilProByUrlApi } from "../../../api/ToolsApi";
import { createDownloadLink, getPrettyText } from "../../../utils/Utils";
import UnfilledButton from "../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import { useModals } from "../../../context/ModalsContext";
import { useAuth } from "../../../context/AuthContext";

function ConseilsProsArticle() {
  const { getUuid } = useAuth();
  const {
    createNotification,
    setModalContent,
    setModalVisible,
    setBreadcrumbsSpecialValue,
    setAppLoaderVisible,
    setBannerConfig,
    setHeaderSEOSpecialValue,
  } = useContext(AppContext);
  const [article, setArticle] = useState({
    id: "",
    title: "",
    content: "",
    created_at: "",
    updated_at: "",
    image_path: "",
    image_alt: "",
    title_doc: "",
    file_name: "",
    url: "",
  });
  const [articleAnchors, setArticleAnchors] = useState([]);
  const [hasOutilCTAShown, setHasOutilCTAShown] = useState(0);
  const [windowScroll] = useWindowScroll();
  const [FAQ, setFAQ] = useState({
    title: "",
    content: [],
  });
  const [suggestions, setSuggestions] = useState([]);
  const [activeLink, setActiveLink] = useState(null);
  const [isScrollPrevent, setIsScrollPrevent] = useState(false);

  const summaryRef = useRef(null);
  const outilCTASliderRef = useRef(null);
  const articleRef = useRef(null);
  const { width } = useWindowSize();
  const magnet = document.getElementById("leadMagnet");
  const navigate = useNavigate();
  const { articleUrl } = useParams();
  const { showTarifsModal } = useModals();
  const { hasResourcesAccess } = useAuth();

  useEffect(() => {
    setAppLoaderVisible(true);
    fetchAllData();

    setBannerConfig({
      title: <>Conseils de pros</>,
      subtitle: (
        <Link className='link-with-arrow' to='/conseils-pros'>
          <ArrowSm /> Accéder la liste des conseils
        </Link>
      ),
    });

    setTimeout(() => {
      if (document.getElementById("rapport_container"))
        document.getElementById("rapport_container").addEventListener("click", openRapport);
    }, 2000);

    return () => {
      if (document.getElementById("rapport_container"))
        document.getElementById("rapport_container")?.removeEventListener("click", openRapport);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setAnchorsFromArticle();
    }, 500);

    setBreadcrumbsSpecialValue({ to: article.url, name: article.title });
  }, [article]);

  useEffect(() => {
    setHeaderSEOSpecialValue({
      title: article.meta_title,
      description: article.meta_description,
      author: "EstimerMonCommerce.fr",
      follow: "follow",
      robots: "index, follow",
      image: "https://images.pickapic.live/get/7fe873b7-3377-42ae-b76a-4b40d123950e-1638786656.jpeg",
      url: "/conseils-pros/" + article.url,
      breadcrumb: [
        { name: "Conseils de pros sur la cession, acquisition et optimisation", item: "/conseils-pros" },
        { name: article.meta_title, item: "/conseils-pros/" + article.url },
      ],
      faq: FAQ ? FAQ.content : null,
    });
  }, [article, FAQ]);

  useEffect(() => {
    if (magnet && !hasOutilCTAShown && windowScroll.y >= magnet.offsetTop) {
      setHasOutilCTAShown(true);
      showOutilCTA();
    }

    const tmp = document.querySelector(".article-aside li.active");
    if (tmp && activeLink !== tmp) setActiveLink(tmp);
  }, [windowScroll]);

  useEffect(() => {
    handleSummaryScroll();
  }, [activeLink]);

  async function fetchAllData() {
    try {
      const tag = await fetchArticle();
      await fetchSuggestions(tag);

      setAppLoaderVisible(false);
    } catch (error) {
      navigate("/404");
    }
  }

  function getPrettyArticle(article) {
    const processNodeDefinitions = HtmlToReact.ProcessNodeDefinitions();
    const processingInstructions = [
      {
        shouldProcessNode: function (node) {
          return node.name && node.name === "img";
        },
        processNode: function (node) {
          return React.createElement("img", {
            id: node.attribs.id,
            src: `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${article.url}/images/${
              node.attribs.src.split("/").reverse()[0]
            }`,
            className: node.attribs.class,
            alt: node.attribs.alt,
            onError: ({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
              currentTarget.alt = "Image non disponible";
            },
          });
        },
      },
      {
        shouldProcessNode: function (node) {
          return node.name && node.name === "hr";
        },
        processNode: function (node) {
          return React.createElement("hr", { className: "hr-article" });
        },
      },
      {
        shouldProcessNode: function (node) {
          return node.name && node.name === "button";
        },
        processNode: function (node) {
          return React.createElement(
            "a",
            { target: "_blank", className: "button filled-button article-button", href: "/debut-parcours" },
            node.children[0].data,
          );
        },
      },
      {
        shouldProcessNode: function (node) {
          return (
            node.name &&
            node.attribs &&
            node.attribs.class &&
            node.name === "div" &&
            node.attribs.class.includes("container")
          );
        },
        processNode: function (node) {
          return React.createElement("div", { id: "cta_container" }, <EstimationCTA />);
        },
      },
      {
        shouldProcessNode: function () {
          return true;
        },
        processNode: processNodeDefinitions.processDefaultNode,
      },
    ];
    const isValidNode = function () {
      return true;
    };

    const htmlToReactParser = new Parser();
    const reactComponent = htmlToReactParser.parseWithInstructions(
      article.content,
      isValidNode,
      processingInstructions,
    );
    article.content = ReactDOMServer.renderToStaticMarkup(reactComponent);

    return article;
  }

  async function fetchArticle() {
    try {
      const article = (await fetchConseilProByUrlApi(articleUrl)).data;
      if (!article) return navigate("/404");

      article.title = getPrettyText(article.title);

      setFaqFromArticle(article.faq);

      delete article.faq;
      setArticle(getPrettyArticle(article));

      return article.tag;
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de l'article</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function setFaqFromArticle(faq) {
    setFAQ({
      title: getPrettyText(faq.faq_title),
      content: [
        {
          title: getPrettyText(faq.faq_question_1?.question),
          answer: getPrettyText(faq.faq_question_1?.answer),
        },
        {
          title: getPrettyText(faq.faq_question_2?.question),
          answer: getPrettyText(faq.faq_question_2?.answer),
        },
        {
          title: getPrettyText(faq.faq_question_3?.question),
          answer: getPrettyText(faq.faq_question_3?.answer),
        },
      ],
    });
  }

  async function fetchSuggestions(tag) {
    try {
      const suggestions = (await fetchAllConseilsProsApi("0", "10", tag)).data.conseils_pros;

      setSuggestions(
        suggestions.map(article => ({
          title: getPrettyText(article.title),
          alt: article.header_img_alt,
          tag: article.tag,
          picture: `${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${article.url}/miniature/${article.header_img}`,
          article_url: `/conseils-pros/${article.url}`,
        })),
      );
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des suggestions</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function handleSummaryScroll() {
    if (!activeLink || !summaryRef.current) return;

    const childRect = activeLink.getBoundingClientRect();
    const containerRect = summaryRef.current.getBoundingClientRect();

    if (childRect.top < containerRect.top) summaryRef.current.scrollTop += childRect.top - containerRect.top - 30;
    if (childRect.bottom > containerRect.bottom)
      summaryRef.current.scrollTop += childRect.bottom - containerRect.bottom + 20;
  }

  function showOutilCTA() {
    if (outilCTASliderRef.current) {
      outilCTASliderRef.current.style.display = "block";
      outilCTASliderRef.current.style.right = "20px";
    }
  }

  function hideOutilCTA() {
    if (outilCTASliderRef.current) {
      outilCTASliderRef.current.style.opacity = "0";
    }
    setTimeout(() => {
      if (outilCTASliderRef.current) outilCTASliderRef.current.style.display = "none";
    }, 500);
  }

  function setAnchorsFromArticle() {
    const children = [
      ...Array.from(articleRef.current.children[0].children),
      ...Array.from(articleRef.current.children),
    ];
    const titles = children.filter(child => child.localName === "h2");

    setArticleAnchors(
      titles.map((title, index) => {
        title.setAttribute("id", getTitleIdFromName(title.innerHTML));
        const top = title.offsetTop + 400;
        return {
          name: getPrettyText(title.innerHTML),
          link: getTitleIdFromName(title.innerHTML),
          top: top,
          bottom: titles.length > index + 1 ? titles[index + 1].offsetTop + 400 : top + 1000000,
        };
      }),
    );
  }

  function getTitleIdFromName(name) {
    return name
      .split(" ")
      .join("-")
      .replaceAll(/[^a-zA-Z0-9- ]/g, "");
  }

  function openRapport(e) {
    e.preventDefault();
    window.open(PDF, "_blank");
  }

  function preventAutoScroll() {
    setIsScrollPrevent(true);
    setTimeout(() => {
      setIsScrollPrevent(false);
    }, 1000);
  }

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function downlaodPracticalTool(article) {
    if (!hasResourcesAccess.tools)
      return showTarifsModal(
        "Télécharger un outil pratique",
        <>
          <p>Profitez de tous nos outils pratiques avec l'offre la mieux adaptée à vos besoins</p>
          <img
            className='lead-magnet-preview'
            src={`${process.env.REACT_APP_API_URL}assets/OutilsPratiques/${article.lead_magnet_url}/preview/${article.lead_magnet_img}`}
          />
        </>,
        `/conseils-pros/${article.url}`,
      );

    const link = createDownloadLink(
      `${process.env.PUBLIC_URL}/assets/OutilsPratiques/${article.lead_magnet_url}/file/${article.lead_magnet_file}`,
    );

    if (!link)
      return createNotification(
        <>Impossible de télécharger cet outil pratique. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );

    link.click();
    link.remove();
  }

  const EstimationCTA = () => (
    <div className='my-xl'>
      <h3 className='text-xl outfit-bold centered mb-md'>Estimez en ligne une affaire</h3>
      <div className='estimation-cta'>
        <a href={PDF} className='estimation-cta-img-container' id='rapport_container'>
          <div
            className='button inversion-button article-button text-lg'
            style={{
              "--padding": "3px 10px",
              "--hover-color": "var(--white)",
              "--bg-color": "var(--blue)",
            }}>
            <Eye />
          </div>
          <img src={require("../../../assets/reportExamples/exemple_rapport_fdc.webp")} />
        </a>
        <div className='flex f-column space-between gap-sm mx-auto'>
          <p className='cta-title centered'>Découvrez nos modules d'estimation&nbsp;:</p>
          <div className='mx-auto'>
            <p>
              <span>
                <Check />
                &nbsp;&nbsp;Fonds de commerce
              </span>
              <br />
              <span>
                <Check />
                &nbsp;&nbsp;Titres de société
              </span>
              <br />
              <span>
                <Check />
                &nbsp;&nbsp;Murs commerciaux
              </span>
            </p>
          </div>
          <div className='centered'>
            <a className='button filled-button article-button text-md' href='/debut-parcours'>
              Démarrer mon estimation
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className='page-container'>
      <section className='container'>
        <PageTitle className='article-title' title={article.title} />
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
            currentTarget.alt = "Image non disponible";
          }}
          alt={article.header_img_alt}
          className='article-main-picture mb-md'
          src={`${process.env.REACT_APP_API_URL}/assets/ConseilsPros/${articleUrl}/miniature/${article.header_img}`}
        />
        <div className='centered mb-md'>
          <p className='text-sky-blue outfit-bold'>
            Publié le {article.created_at}
            {article.updated_at && (
              <span className='text-sky-blue outfit-bold'> et mis à jour le {article.updated_at}</span>
            )}
          </p>
        </div>
        <div className='outil-cta-slider' ref={outilCTASliderRef}>
          <div className='outil-cta-container'>
            <Cross width='20px' className='outil-cta-close' onClick={hideOutilCTA} />
            <div className='flex f-column gap-md centered'>
              <p className='centered'>
                Découvrez notre
                <br />
                <span className='outfit-bold'>{article.lead_magnet_name}</span>
              </p>
              <RapportsColorsCTA />
              <FilledButton
                onClick={() => downlaodPracticalTool(article)}
                padding='9px 15px'
                bgColor='var(--light-blue)'
                hoverColor='var(--light-blue)'
                color='var(--dark-blue)'>
                Télécharger notre outil
              </FilledButton>
            </div>
          </div>
        </div>
        <div className='flex space-between relative'>
          <aside className='article-aside' ref={summaryRef}>
            <h4 className='outfit-bold'>Dans cet article</h4>
            <ul className='article-nav f-column gap-md flex'>
              {articleAnchors.map((anchor, key) => (
                <li
                  key={key}
                  className={
                    !isScrollPrevent && windowScroll.y >= anchor.top && windowScroll.y < anchor.bottom ? "active" : ""
                  }>
                  <HashLink onClick={preventAutoScroll} to={"#" + anchor.link}>
                    {anchor.name}
                  </HashLink>
                </li>
              ))}
            </ul>
          </aside>
          <div className='conseils-pros-container'>
            <div className='article-container' ref={articleRef}>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }} />
              <hr className='hr-article' />
              <h2>F.A.Q</h2>
              <p>{FAQ.title}</p>
              <div className={width > 576 ? "mx-lg" : ""}>
                <ExpendableGroup
                  data={FAQ.content.map(row => {
                    return { title: row.title, content: <p>{row.answer}</p> };
                  })}
                />
              </div>
            </div>
          </div>
          <div className='aside-container'>
            <aside className='article-aside'>
              <h4 className='outfit-bold'>Découvrez nos outils</h4>
              <p className='mb-md'>
                Des outils pratiques pour l'estimation, la cession et l'acquisition de fonds, titres et murs commerciaux
              </p>
              <Link className='link link-with-arrow mb-md' to='/outils-pratiques'>
                Je découvre les outils pratiques&nbsp;
                <ArrowSm rotation='180deg' />
              </Link>
              <Link className='link link-with-arrow mb-lg' to='/outils-pratiques'>
                J'estime une affaire ou des murs&nbsp;
                <ArrowSm rotation='180deg' />
              </Link>
              <h4 className='outfit-bold'>Partagez cet article !</h4>
              <p>Cet article vous a plu&nbsp;? Partagez-le&nbsp;!</p>
              <div className='row space-between mt-sm'>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='#0077B5'
                  hoverColor='#0077B5'
                  target='_blank'
                  to={
                    "https://www.linkedin.com/sharing/share-offsite/?url=" +
                    process.env.REACT_APP_URL +
                    "conseils-pros/" +
                    article.url
                  }
                  rel='nofollow'>
                  <Linkedin />
                </FilledButton>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='#1761AB'
                  hoverColor='#1761AB'
                  target='_blank'
                  to={
                    "https://www.facebook.com/sharer/sharer.php?u=" +
                    process.env.REACT_APP_URL +
                    "conseils-pros/" +
                    article.url
                  }
                  rel='nofollow'>
                  <Facebook />
                </FilledButton>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='#2D9AFF'
                  hoverColor='#2D9AFF'
                  target='_blank'
                  to={"https://twitter.com/share?url=" + process.env.REACT_APP_URL + "conseils-pros/" + article.url}
                  rel='nofollow'>
                  <Twitter />
                </FilledButton>
                <FilledButton
                  padding='5px'
                  borderRadius='7px'
                  bgColor='var(--gold)'
                  hoverColor='var(--gold)'
                  target='_blank'
                  to={
                    "mailto:?Subject=Article%20EstimerMonCommerce.fr&body=Partage%20d%27un%20article%20sur%20EstimerMonCommerce.fr%20:%20" +
                    process.env.REACT_APP_URL +
                    "conseils-pros/" +
                    article.url
                  }
                  rel='nofollow'>
                  <Email />
                </FilledButton>
              </div>
            </aside>
            <div className='top-scroller-container article-top-scroller'>
              <div className='top-scroller' onClick={scrollToTop} />
            </div>
          </div>
        </div>
        <FilledButton className='mid-page-button' to='/debut-parcours'>
          Je démarre mon estimation
        </FilledButton>
      </section>
      <InfiniteThumbnailsRow
        title={<PageTitle title='DANS LA MÊME CATÉGORIE :' margin='20px auto' />}
        data={suggestions}
        margin='-15px auto 50px'
      />
    </div>
  );
}

export default ConseilsProsArticle;
