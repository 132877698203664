import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import AppContext from "../../../../../../context/AppContext";
import { duplicateFolderApi } from "../../../../../../api/FolderApi";
import Input from "../../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";

function DuplicateFolderModal({ resourceUuid, defaultValue, onSuccess = async () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    setValue("folder_name", defaultValue);
  }, [defaultValue]);

  async function duplicateFolder(values) {
    setIsButtonLoading(true);
    try {
      await duplicateFolderApi(resourceUuid, values.folder_name);

      createNotification(<>Le dossier a été dupliqué avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la duplication du dossier. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      onSuccess();
      setModalVisible(false);
      setIsButtonLoading(false);
    }
  }
  return (
    <form onSubmit={handleSubmit(duplicateFolder)}>
      <Input
        useForm={{ register, setValue }}
        error={errors?.folder_name?.message}
        name='folder_name'
        label='Nom du dossier'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton isLoading={isButtonLoading} padding='10px 25px' type='submit'>
          Dupliquer le dossier
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateFolderModal;
