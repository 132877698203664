import { createContext, useContext, useState } from "react";
import AppContext from "./AppContext";
import { archiveEstimationApi, fetchEstimationByIdApi, fetchUserEstimationsApi } from "../api/EstimationApi";
import {
  addCreditSimulatorToDeliverableApi,
  addCreditSimulatorToFolderApi,
  addEstimationToDeliverableApi,
  addEstimationToFolderApi,
  addFinancingPlanToDeliverableApi,
  addFinancingPlanToFolderApi,
  archiveFolderApi,
  deleteAllCreditSimulatorsFromDeliverableApi,
  deleteAllCreditSimulatorsFromFolderApi,
  deleteAllEstimationsFromDeliverableApi,
  deleteAllEstimationsFromFolderApi,
  deleteAllFinancingPlansFromDeliverableApi,
  deleteAllFinancingPlansFromFolderApi,
  deleteCreditSimulatorFromDeliverableApi,
  deleteCreditSimulatorFromFolderApi,
  deleteEstimationFromDeliverableApi,
  deleteEstimationFromFolderApi,
  deleteFinancingPlanFromDeliverableApi,
  deleteFinancingPlanFromFolderApi,
  downloadDeliverableApi,
  downloadFolderApi,
  fetchDeliverablesByFolderIdApi,
  fetchFolderByIdApi,
  fetchUserFoldersApi,
} from "../api/FolderApi";
import { archiveFinancingPlanApi, fetchAllFinancingPlanApi, fetchFinancingPlanByIdApi } from "../api/FinancingPlanApi";
import {
  archiveCreditSimulationApi,
  deleteCreditSimulationApi,
  fetchAllCreditSimulationsApi,
  fetchCreditSimulationByIdApi,
} from "../api/CreditSimulatorApi";
import {
  deleteVisitingCardApi,
  fetchVisitingCardByIdApi,
  fetchVisitingCardsByUuidApi,
  updateVisitingCardApi,
} from "../api/VisitingCardApi";
import {
  copyToClipboard,
  createDownloadLink,
  getDuplicateItemName,
  normalizeString,
  stringToDate,
} from "../utils/Utils";
import DuplicateFolderModal from "../components/forms/modals/account/folders/duplicateFolderModal/DuplicateFolderModal";
import DuplicateEstimationModal from "../components/forms/modals/account/tools/duplicateEstimationModal/DuplicateEstimationModal";
import DuplicateFinancingPlanModal from "../components/forms/modals/account/tools/duplicateFinancingPlanModal/DuplicateFinancingPlanModal";
import DuplicateCreditSimulatorModal from "../components/forms/modals/account/tools/duplicateCreditSimulatorModal/DuplicateCreditSimulator";
import {
  downloadCreditSimulatorPdfApi,
  downloadEstimationPdfApi,
  downloadFinancingPlanPdfApi,
} from "../api/DownloadApi";
import Emc from "../components/atoms/icons/header/emc/Emc";
import Rapport from "../components/atoms/icons/header/rapport/Rapport";
import Chart from "../components/atoms/icons/header/chart/Chart";
import DuplicateDeliverableModal from "../components/forms/modals/account/deliverables/duplicateDeliverableModal/DuplicateDeliverableModal";
import UnfilledButton from "../components/molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../components/molecules/buttons/filledButton/FilledButton";
import RemoveResourceFromFolderModal from "../components/forms/modals/account/folders/removeResourceFromFolderModal/RemoveResourceFromFolderModal";
import RemoveResourceFromDeliverableModal from "../components/forms/modals/account/deliverables/removeResourceFromDeliverableModal/RemoveResourceFromDeliverableModal";
import DeleteVisitingCardModal from "../components/forms/modals/account/tools/deleteVisitingCardModal/DeleteVisitingCardModal";

const ResourcesContext = createContext();

export function useResources() {
  return useContext(ResourcesContext);
}

export function ResourcesProvider({ children }) {
  const { setIsDownloadNotificationLoading, createNotification, setModalVisible, setModalContent } =
    useContext(AppContext);

  const typesTable = [
    {
      index: 1,
      description: "folders",
      isMasculine: true,
      fetchAllFunction: fetchUserFolders,
      fetchSingleFunction: fetchFolderByUuid,
      DuplicateModal: DuplicateFolderModal,
      archiveFunction: archiveFolderApi,
      downloadFunction: downloadFolderApi,
    },
    {
      index: 2,
      description: "estimations",
      isMasculine: false,
      icon: Emc,
      fetchAllFunction: fetchUserEstimations,
      fetchSingleFunction: fetchEstimationByUuid,
      DuplicateModal: DuplicateEstimationModal,
      archiveFunction: archiveEstimationApi,
      downloadFunction: downloadEstimationPdfApi,
      addToFolderFunction: addEstimationToFolderApi,
      removeResourceFromFolderFunction: deleteEstimationFromFolderApi,
      removeAllFromFolderFunction: deleteAllEstimationsFromFolderApi,
      addToDeliverableFunction: addEstimationToDeliverableApi,
      removeResourceFromDeliverableFunction: deleteEstimationFromDeliverableApi,
      removeAllFromDeliverableFunction: deleteAllEstimationsFromDeliverableApi,
    },
    {
      index: 3,
      description: "financingPlans",
      isMasculine: true,
      icon: Rapport,
      fetchAllFunction: fetchUserFinancingPlans,
      fetchSingleFunction: fetchFinancingPlanByUuid,
      DuplicateModal: DuplicateFinancingPlanModal,
      archiveFunction: archiveFinancingPlanApi,
      downloadFunction: downloadFinancingPlanPdfApi,
      addToFolderFunction: addFinancingPlanToFolderApi,
      removeResourceFromFolderFunction: deleteFinancingPlanFromFolderApi,
      removeAllFromFolderFunction: deleteAllFinancingPlansFromFolderApi,
      addToDeliverableFunction: addFinancingPlanToDeliverableApi,
      removeResourceFromDeliverableFunction: deleteFinancingPlanFromDeliverableApi,
      removeAllFromDeliverableFunction: deleteAllFinancingPlansFromDeliverableApi,
    },
    {
      index: 4,
      description: "creditSimulations",
      isMasculine: false,
      icon: Chart,
      fetchAllFunction: fetchUserCreditSimulations,
      fetchSingleFunction: fetchCreditSimulationByUuid,
      DuplicateModal: DuplicateCreditSimulatorModal,
      archiveFunction: archiveCreditSimulationApi,
      downloadFunction: downloadCreditSimulatorPdfApi,
      addToFolderFunction: addCreditSimulatorToFolderApi,
      removeResourceFromFolderFunction: deleteCreditSimulatorFromFolderApi,
      removeAllFromFolderFunction: deleteAllCreditSimulatorsFromFolderApi,
      addToDeliverableFunction: addCreditSimulatorToDeliverableApi,
      removeResourceFromDeliverableFunction: deleteCreditSimulatorFromDeliverableApi,
      removeAllFromDeliverableFunction: deleteAllCreditSimulatorsFromDeliverableApi,
    },
    {
      index: 5,
      description: "visitingCards",
      isMasculine: false,
      fetchAllFunction: fetchUserVisitingCards,
      fetchSingleFunction: fetchVisitingCardByUuid,
    },
    {
      index: 6,
      description: "deliverables",
      isMasculine: false,
      DuplicateModal: DuplicateDeliverableModal,
      downloadFunction: downloadDeliverableApi,
    },
  ];

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  // Utils

  function getTypesTable(resourcesIndex, withIcon) {
    const result = [];

    typesTable.forEach(type => {
      if (resourcesIndex.includes(type.index))
        result.push({
          index: type.index,
          description: type.description,
          isMasculine: type.isMasculine,
          ...(withIcon ? { Icon: type.icon } : {}),
        });
    });

    return result;
  }

  function getResourceTypeFromDescription(resourceDescription) {
    const type = typesTable.find(type => type.description === resourceDescription);

    if (!type) {
      console.warn("Le type ", resourceDescription, " n'existe pas (fetchUserResource)");
      return false;
    }

    return type;
  }

  function getResourceName(resourceDescription, isPlural, withSpecifier) {
    switch (resourceDescription) {
      case "folders":
        return (withSpecifier ? "le" + (isPlural ? "s " : " ") : "") + "dossier" + (isPlural ? "s" : "");
      case "estimations":
        return (withSpecifier ? "l" + (isPlural ? "es " : "'") : "") + "estimation" + (isPlural ? "s" : "");
      case "financingPlans":
        return (
          (withSpecifier ? "le" + (isPlural ? "s " : " ") : "") + "plan" + (isPlural ? "s" : "") + " de financement"
        );
      case "creditSimulations":
        return (
          (withSpecifier ? "l" + (isPlural ? "es " : "a ") : "") + "simulation" + (isPlural ? "s" : "") + " de prêt"
        );
      case "visitingCards":
        return (withSpecifier ? "l" + (isPlural ? "es " : "a ") : "") + "carte" + (isPlural ? "s" : "") + " de visite";
      default:
        return "";
    }
  }

  function isResourceMasculine(resourceDescription) {
    return typesTable.find(type => type.description === resourceDescription).isMasculine;
  }

  function getResourceLink(resource, resourceDescription) {
    switch (resourceDescription) {
      case "estimations":
        return `/synthese/${resource.uuid}`;
      case "financingPlans":
        return `/plan-financement/${resource.type?.id === 1 ? "fdc" : "murs"}/${resource.uuid}`;
      case "creditSimulations":
        return `/simulateur-credit/${resource.uuid}`;
      case "folders":
        return `/mon-compte/mes-dossiers/${resource.uuid}`;
      case "visitingCards":
        return `?visiting_card_id=${resource.uuid}`;
      case "clients":
        return `/admin/crm/clients/${item.uuid}`;
    }
  }

  function copyUuidToClipboard(e, id) {
    e.preventDefault();
    e.stopPropagation();

    copyToClipboard(id, <>La référence a été copiée dans votre presse-papier avec succès.</>, createNotification);
  }

  // Fetch resources

  async function fetchUserAllResources(
    userUuid,
    resourcesDescription = ["folders", "estimations", "financingPlans", "creditSimulations", "visitingCards"],
  ) {
    const promises = typesTable.map(async type => {
      if (!resourcesDescription.includes(type.description)) return null;
      const result = await type.fetchAllFunction(userUuid);
      return { [type.description]: result };
    });

    const resultsArray = await Promise.all(promises);
    const results = resultsArray.filter(result => result).reduce((acc, curr) => ({ ...acc, ...curr }), {});

    return results;
  }

  async function fetchUserResource(userUuid, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return [];

    return await type.fetchAllFunction(userUuid);
  }

  async function fetchUserFolders(userUuid) {
    let result = [];
    try {
      let res = (await fetchUserFoldersApi(userUuid)).data;

      res = res.sort((a, b) => stringToDate(b.folder.data.date_creation) - stringToDate(a.folder.data.date_creation));

      result = res.map(folder => formatFolder(folder.folder));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des dossiers. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchUserEstimations(userUuid) {
    let result = [];
    try {
      let res = (await fetchUserEstimationsApi(userUuid)).data;

      res = [...res["Fonds de commerce"], ...res["Murs commerciaux"], ...res["Titres de société"]];
      res.sort((a, b) => stringToDate(b.date_creation_estimation) - stringToDate(a.date_creation_estimation));

      result = res.map(estimation => formatEstimation(estimation));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des estimations. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchUserFinancingPlans(userUuid) {
    let result = [];
    try {
      const res = (await fetchAllFinancingPlanApi(userUuid)).data;

      result = [...res.financing_plan_fdc, ...res.financing_plan_murs];

      result.sort((a, b) => stringToDate(b.date_creation) - stringToDate(a.date_creation));

      result = result.map(financingPlan => formatFinancingPlan(financingPlan));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des plans de financement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchUserCreditSimulations(userUuid) {
    let result = [];
    try {
      result = (await fetchAllCreditSimulationsApi(userUuid)).data;
      result.sort((a, b) => stringToDate(b.date_creation) - stringToDate(a.date_creation));
      result = result.map(simulation => formatCreditSimulation(simulation));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des simulations de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchUserVisitingCards(userUuid) {
    let result = [];

    try {
      const res = (await fetchVisitingCardsByUuidApi(userUuid)).data;

      res.sort((a, b) => stringToDate(b.date_creation_visiting_card) - stringToDate(a.date_creation_visiting_card));

      result = res.map(card => ({
        ...card,
        uuid: card.id,
        name: card.card_name,
        card_type_id: card.card_type === "created_card" ? 1 : 0,
        date_creation: card.date_creation_visiting_card?.slice(0, 16),
        date_last_update: card.date_update_visiting_card?.slice(0, 16),
      }));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des cartes de visite. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchFolderDeliverables(folderUuid) {
    let result = [];

    try {
      const res = (await fetchDeliverablesByFolderIdApi(folderUuid)).data;
      res.sort(
        (a, b) => stringToDate(b.deliverable.data.date_creation) - stringToDate(a.deliverable.data.date_creation),
      );

      result = res.map(item => formatDeliverable(item.deliverable));
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des livrables. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return result;
    }
  }

  // Fetch resource by UUID

  async function fetchUserSingleResource(resourceUuid, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      return await type.fetchSingleFunction(resourceUuid);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
      return false;
    }
  }

  async function fetchFolderByUuid(folderUuid) {
    let result = null;

    try {
      const folder = (await fetchFolderByIdApi(folderUuid)).data.folder;

      result = formatFolder(folder);
      result.deliverables = await fetchFolderDeliverables(folderUuid);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération du dossier. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchEstimationByUuid(estimationUuid) {
    let result = null;

    try {
      const estimation = (await fetchEstimationByIdApi(estimationUuid)).data;

      result = formatEstimation(estimation);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de l'estimation. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchFinancingPlanByUuid(financingPlanUuid) {
    let result = null;

    try {
      const financingPlan = (await fetchFinancingPlanByIdApi(financingPlanUuid)).data;

      result = formatFinancingPlan(financingPlan);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération du plan de financement. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchCreditSimulationByUuid(creditSimulationUuid) {
    let result = null;

    try {
      const creditSimulation = (await fetchCreditSimulationByIdApi(creditSimulationUuid)).data.credit_simulator;

      result = formatCreditSimulation(creditSimulation);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  async function fetchVisitingCardByUuid(visitingCardUuid) {
    let result = null;

    try {
      const visitingCard = (await fetchVisitingCardByIdApi(visitingCardUuid)).data;

      result = {
        ...visitingCard,
        uuid: visitingCard.id,
        name: visitingCard.card_name,
        card_type_id: visitingCard.card_type === "created_card" ? 1 : 0,
        date_creation: visitingCard.date_creation_visiting_card?.slice(0, 16),
        date_last_update: visitingCard.date_last_update_visiting_card?.slice(0, 16),
      };
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération dela carte de visite. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      return result;
    }
  }

  // Add resource to folder / deliverable

  async function importResourceToFolder(resourceUuid, folderUuid, resourceDescription, onSuccess = () => {}) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.addToFolderFunction(folderUuid, resourceUuid);

      onSuccess();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'importation de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function importResourceToDeliverable(resourceUuid, deliverableUuid, resourceDescription, onSuccess = () => {}) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.addToDeliverableFunction(deliverableUuid, resourceUuid);

      onSuccess();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'importation de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Remove resource from folder / deliverable

  function showRemoveResourceFromFolderModal(resource, folderUuid, resourceDescription, onSuccess = () => {}) {
    setModalContent({
      title: <>⚠️ ATTENTION</>,
      content: (
        <RemoveResourceFromFolderModal
          resourceDescription={resourceDescription}
          removeFunction={() => removeResourceFromFolder(folderUuid, resource, resourceDescription)}
          onSuccess={onSuccess}
        />
      ),
    });

    setModalVisible(true);
  }

  async function removeResourceFromFolder(folderUuid, resource, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.removeResourceFromFolderFunction(folderUuid, resource.uuid, true);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showRemoveResourceFromDeliverableModal(
    resource,
    deliverableUuid,
    resourceDescription,
    onSuccess = () => {},
  ) {
    setModalContent({
      title: <>⚠️ ATTENTION</>,
      content: (
        <RemoveResourceFromDeliverableModal
          resourceDescription={resourceDescription}
          removeFunction={() => removeResourceFromDeliverable(deliverableUuid, resource, resourceDescription)}
          onSuccess={onSuccess}
        />
      ),
    });

    setModalVisible(true);
  }

  async function removeResourceFromDeliverable(deliverableUuid, resource, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.removeResourceFromDeliverableFunction(deliverableUuid, resource.uuid, true);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showRemoveAllResourcesFromFolderModal(folderUuid, resourceDescription, onSuccess = () => {}) {
    setModalContent({
      title: <>⚠️ ATTENTION</>,
      content: (
        <RemoveResourceFromFolderModal
          resourceDescription={resourceDescription}
          onSuccess={onSuccess}
          removeFunction={() => removeAllResourcesFromFolder(folderUuid, resourceDescription)}
        />
      ),
    });

    setModalVisible(true);
  }

  async function removeAllResourcesFromFolder(folderUuid, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.removeAllFromFolderFunction(folderUuid, true);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showRemoveAllResourcesFromDeliverableModal(deliverableUuid, resourceDescription, onSuccess = () => {}) {
    setModalContent({
      title: <>⚠️ ATTENTION</>,
      content: (
        <RemoveResourceFromDeliverableModal
          resourceDescription={resourceDescription}
          onSuccess={onSuccess}
          removeFunction={() => removeAllResourcesFromDeliverable(deliverableUuid, resourceDescription)}
        />
      ),
    });

    setModalVisible(true);
  }

  async function removeAllResourcesFromDeliverable(deliverableUuid, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.removeAllFromDeliverableFunction(deliverableUuid, true);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du retrait de la ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Show duplicate resources modal

  function showDuplicateResourceModal(resource, resourceDescription, onSuccess = () => {}) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    setModalContent({
      title: "Dupliquer " + getResourceName(resourceDescription, false, true),
      content: (
        <type.DuplicateModal
          defaultValue={getDuplicateItemName(resource.name)}
          onSuccess={onSuccess}
          resourceUuid={resource.uuid}
        />
      ),
    });

    setModalVisible(true);
  }

  // Archive resource

  async function archiveResource(resource, shouldArchive, resourceDescription, onSuccess = () => {}) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      await type.archiveFunction(resource.uuid, shouldArchive);
      await onSuccess();
      createNotification(<>La ressource a été {shouldArchive ? "archivée" : "désarchivée"} avec succès.</>);
    } catch (error) {
      createNotification(
        <>
          Une erreur est survenue lors {shouldArchive ? "de l'archivage" : "du désarchivage"} de la ressource. Veuillez
          réessayer.
        </>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Delete visiting card

  async function showDeleteVisitingCardModal(visitingCardUuid, onSuccess = () => {}) {
    setModalContent({
      title: <>⚠️ ATTENTION</>,
      content: <DeleteVisitingCardModal visitingCardUuid={visitingCardUuid} onSuccess={onSuccess} />,
    });

    setModalVisible(true);
  }

  // Set default visiting card

  async function setDefaultVisitingCard(visitingCardUuid, shouldSetDefault, visitingCards, onSuccess = () => {}) {
    try {
      await updateVisitingCardApi(visitingCardUuid, { is_default: shouldSetDefault ? 1 : 0 });

      if (shouldSetDefault) {
        const defaultVisitingCard = visitingCards.find(visitingCard => visitingCard.is_default);
        if (defaultVisitingCard) await updateVisitingCardApi(defaultVisitingCard.uuid, { is_default: 0 });
      }

      onSuccess();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour de la carte de visite. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  // Download resource

  async function downloadResource(resource, resourceDescription) {
    const type = getResourceTypeFromDescription(resourceDescription);
    if (!type) return false;

    try {
      setIsDownloadNotificationLoading(true);

      const res = await type.downloadFunction(resource.uuid);

      const link = createDownloadLink(res.data);
      if (!link) throw new Error("");

      link.click();
      link.remove();
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du téléchargement de votre ressource. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsDownloadNotificationLoading(false);
    }
  }

  // Filter resource

  function filterResources(resources, filterConfig = {}) {
    let result = resources;

    if (filterConfig.searchValue)
      result = result.filter(resource =>
        normalizeString(resource.name).includes(normalizeString(filterConfig.searchValue)),
      );

    if (filterConfig.typeId) {
      result = result.filter(resource => resource.type.id === filterConfig.typeId);
    }
  }

  // Format resources

  function formatFolder(folder) {
    let result = {
      ...folder.data,
      name: folder.data.folder_name,
      uuid: folder.data.folder_id,
      date_creation: folder.data.date_creation.slice(0, 16),
      date_last_update: folder.data.date_last_update?.slice(0, 16),
      creditSimulationsCount: folder.credit_simulators.length,
      deliverablesCount: folder.data.total_deliverables,
      estimationsCount:
        folder.estimations.fonds_de_commerce.length +
        folder.estimations.murs_commerciaux.length +
        folder.estimations.titres_de_societe.length,
      financingPlansCount:
        folder.financing_plans.financing_plan_fdc.length + folder.financing_plans.financing_plan_murs.length,
      estimations: [
        ...folder.estimations.fonds_de_commerce,
        ...folder.estimations.titres_de_societe,
        ...folder.estimations.murs_commerciaux,
      ].map(estimation => formatEstimation(estimation)),
      financingPlans: [...folder.financing_plans.financing_plan_fdc, ...folder.financing_plans.financing_plan_murs].map(
        financingPlan => formatFinancingPlan(financingPlan.financing_plan),
      ),
      creditSimulations: folder.credit_simulators
        ? folder.credit_simulators.map(creditSimulation => formatCreditSimulation(creditSimulation.credit_simulator))
        : [],
    };

    delete result.data;
    delete result.folder_id;
    delete result.folder_name;

    return result;
  }

  function formatEstimation(estimation) {
    let result = {
      ...estimation,
      name: estimation.infos.enseigne ?? estimation.infos.nom,
      uuid: estimation.id,
      date_creation: estimation.date_creation_estimation.slice(0, 16),
      date_last_update: estimation.date_last_update_estimation?.slice(0, 16),
    };

    delete result.id;
    delete result.date_creation_estimation;
    delete result.date_last_update_estimation;

    return result;
  }

  function formatFinancingPlan(financingPlan) {
    let result = {
      ...financingPlan,
      date_creation: financingPlan.date_creation.slice(0, 16),
      date_last_update: financingPlan.date_last_update?.slice(0, 16),
      estimation_name: financingPlan.estimation_data?.estimation_name || "-",
      uuid: financingPlan.financing_plan_id,
      name: financingPlan.financing_plan_name,
      type:
        financingPlan.financing_plan_type === "fdc"
          ? {
              id: 1,
              description: "Fonds de commerce",
            }
          : {
              id: 3,
              description: "Murs commerciaux",
            },
      archived: financingPlan.archived,
    };

    delete result.financing_plan_id;
    delete result.financing_plan_name;

    return result;
  }

  function formatCreditSimulation(creditSimulation) {
    let result = {
      ...creditSimulation,
      uuid: creditSimulation.credit_id,
      name: creditSimulation.credit_name,
      date_creation: creditSimulation.date_creation.slice(0, 16),
    };

    delete result.credit_name;
    delete result.credit_id;

    return result;
  }

  function formatDeliverable(deliverable) {
    let result = {
      uuid: deliverable.data.deliverable_id,
      name: deliverable.data.deliverable_name,
      estimations: [
        ...deliverable.estimations.fonds_de_commerce,
        ...deliverable.estimations.titres_de_societe,
        ...deliverable.estimations.murs_commerciaux,
      ],
      financingPlans: [
        ...deliverable.financing_plans.financing_plan_fdc,
        ...deliverable.financing_plans.financing_plan_murs,
      ],
      creditSimulations: deliverable.credit_simulators.map(simulator =>
        formatCreditSimulation(simulator.credit_simulator),
      ),
    };

    result.estimations = result.estimations.map(estimation => formatEstimation(estimation));
    result.financingPlans = result.financingPlans.map(financing_plan =>
      formatFinancingPlan(financing_plan.financing_plan),
    );

    return result;
  }

  // Resource tables

  function formatTableColumns(columns, width) {
    if (!columns || !width) return [];

    return columns.map(columns => ({ ...columns, visible: columns.default <= width }));
  }

  return (
    <ResourcesContext.Provider
      value={{
        // Utils
        getTypesTable, // resourcesIndex, withIcon
        isResourceMasculine, // resourceDescription
        getResourceLink, // resource, resourceDescription
        getResourceName, // resourceDescription, isPlural, withSpecifier
        filterResources, // resources, filterConfig
        formatTableColumns, // columns, width
        copyUuidToClipboard, // event, uuid
        // Fetch
        fetchUserAllResources, // userUuid, resourcesDescription
        fetchUserResource, // userUuid, resourceDescription
        fetchUserSingleResource, // resourceUuid, resourceDescription
        // Resource functions
        showDuplicateResourceModal, // resource, resourceDescription, onSuccess
        setDefaultVisitingCard, //visitingCardUuid, shouldSetDefault, visitingCards, onSuccess
        showDeleteVisitingCardModal, // visitingCardUuid, onSuccess
        archiveResource, // resource, shouldArchive, resourceDescription, onSuccess
        downloadResource, // resource, resourceDescription
        // Folders
        importResourceToFolder, //resourceUuid, folderUuid, resourceDescription, onSuccess
        showRemoveResourceFromFolderModal, // resource, folderUuid, resourceDescription, onSuccess
        showRemoveAllResourcesFromFolderModal, //folderUuid, resourceDescription, onSuccess
        // Deliverables
        importResourceToDeliverable, //resourceUuid, deliverableUuid, resourceDescription, onSuccess
        showRemoveResourceFromDeliverableModal, //resource, deliverableUuid, resourceDescription)
        showRemoveAllResourcesFromDeliverableModal, //deliverableUuid, resourceDescription, onSuccess
        removeAllResourcesFromDeliverable, // deliverableUuid, resourceType, onSuccess
      }}>
      {children}
    </ResourcesContext.Provider>
  );
}
