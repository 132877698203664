import React, { useContext, useEffect, useState } from "react";
import Input from "../../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../../context/AppContext";
import { defaultValidation } from "../../../../../../utils/formValidation/FormValidation";
import { useForm } from "react-hook-form";
import { duplicateEstimationApi } from "../../../../../../api/EstimationApi";

function DuplicateEstimationModal({ resourceUuid, defaultValue, onSuccess = async () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    setValue("enseigne", defaultValue);
  }, [defaultValue]);

  async function duplicateEstimation(values) {
    setIsButtonLoading(true);
    try {
      await duplicateEstimationApi(resourceUuid, values.enseigne);

      createNotification(<>L'estimation a été dupliquée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la duplication de votre estimation. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      onSuccess();
      setModalVisible(false);
      setIsButtonLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(duplicateEstimation)}>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors?.enseigne?.message}
        name='enseigne'
        label="Nom de l'enseigne"
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton isLoading={isButtonLoading} padding='10px 25px' type='submit'>
          Dupliquer l'estimation
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateEstimationModal;
