import { Link, useLocation } from "react-router-dom";
import "./Breadcrumbs.css";
import React, { Fragment, useContext, useEffect, useState } from "react";
import AppContext from "../../../context/AppContext";
import { breadcrumbsCases } from "../../../uploads/breadcrumbsCases.js";
import { fetchFolderByIdApi } from "../../../api/FolderApi.js";

function Breadcrumbs({ className = "", specialValue }) {
  const location = useLocation();
  const { setBreadcrumbsSpecialValue } = useContext(AppContext);

  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    updateBreadcrumb(location);
  }, [location]);

  async function updateBreadcrumb(location) {
    const previousLocation = updatePreviousLocation();

    let routesTmp = [
      {
        to: "/",
        name: "Accueil",
      },
    ];

    let matchedCase = breadcrumbsCases.find(row => location.pathname.includes(row.pattern));

    if (!matchedCase) return console.warn("Aucun fil d'ariane / bannière pour cette page");

    if (matchedCase.isSpecial) {
      routesTmp.push(...(await getSpecialBreadcrumbs(matchedCase, previousLocation)));
    }

    routesTmp.push(...matchedCase.value);

    if (!matchedCase.useSpecialValue && specialValue) setBreadcrumbsSpecialValue(null);

    setRoutes(routesTmp);
  }

  async function getSpecialBreadcrumbs(matchedCase, previousLocation) {
    let result = [];

    if (previousLocation?.includes("/mon-compte/mes-dossiers/")) {
      const folderName = await fetchFolderName(previousLocation?.split("/").pop());
      result.push(
        {
          name: "Mon compte : Mes dossiers",
          to: "/mon-compte/mes-outils/mes-estimations",
        },
        {
          name: folderName,
          to: previousLocation,
        },
      );

      return result;
    }

    if (matchedCase.condition.reduce((acc, value) => acc || previousLocation?.includes(value), false))
      result.push(...matchedCase.conditionalValue);

    return result;
  }

  function updatePreviousLocation() {
    let previousLocation = sessionStorage.getItem("previous-location");
    let actualLocation = sessionStorage.getItem("actual-location");

    if (!actualLocation) return sessionStorage.setItem("actual-location", location.pathname);

    if (actualLocation !== location.pathname) {
      previousLocation = actualLocation;
      actualLocation = location.pathname;

      sessionStorage.setItem("previous-location", previousLocation);
      sessionStorage.setItem(
        "actual-location",
        location.pathname.includes("/synthese") || location.pathname.includes("/debut-parcours")
          ? previousLocation
          : actualLocation,
      );
    }

    return previousLocation;
  }

  async function fetchFolderName(folderUuid) {
    try {
      const folder = (await fetchFolderByIdApi(folderUuid)).data;

      return folder.folder.data.folder_name;
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour du fil d'ariane</>,
        "var(--red)",
        "var(--dark-blue)",
      );

      return "Mon dossier";
    }
  }

  return (
    <div className={"breadcrumbs-container " + className}>
      {location.pathname !== "/" &&
        routes.map((route, key) => {
          return (
            <Fragment key={key}>
              {key > 0 && <> {">"} </>}
              <Link to={key == routes.length - 1 && specialValue?.to ? route.to + specialValue.to : route.to}>
                {key == routes.length - 1 && specialValue?.name ? route.name + specialValue.name : route.name}
              </Link>
            </Fragment>
          );
        })}
    </div>
  );
}

export default Breadcrumbs;
