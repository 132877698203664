import AppContext from "../../../context/AppContext";
import Download from "../../atoms/icons/general/download/Download";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import "./PracticalTool.css";
import React, { useContext } from "react";
import { useModals } from "../../../context/ModalsContext";
import { useAuth } from "../../../context/AuthContext";
import { createDownloadLink } from "../../../utils/Utils";

function PracticalTool({ tool }) {
  const { showTarifsModal, showRegisterModal } = useModals();
  const { createNotification } = useContext(AppContext);
  const { hasResourcesAccess, getUuid } = useAuth();

  function showUnauthorizedModal() {
    if (!getUuid()) return showUnloggedInModal();

    if (!hasResourcesAccess.tools) return showUnsubModal();

    const link = createDownloadLink(
      `${process.env.PUBLIC_URL}/assets/OutilsPratiques/${tool.article_url}/file/${tool.file_name}`,
    );
    if (!link) return createNotification(<>Impossible de télécharger l'outil. Veuillez réessayer.</>);

    link.click();
    link.remove();
  }

  function showUnsubModal() {
    showTarifsModal(
      tool.name,
      <p>Profitez de tous nos outils pratiques avec l'offre la mieux adaptée à vos besoins.</p>,
      "/outils-pratiques",
    );
  }

  function showUnloggedInModal() {
    showRegisterModal("pour accéder à nos outils pratiques", "pour accéder à nos outils pratiques");
  }

  return (
    <div className='practical-tool-container'>
      <p className='practical-tool-description' dangerouslySetInnerHTML={{ __html: tool.content }}></p>
      <div className='practical-tool-preview-container'>
        <FilledButton padding='10px 20px' onClick={showUnauthorizedModal}>
          Télécharger
          <Download className='clickable-icon' />
        </FilledButton>
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = `${process.env.REACT_APP_API_URL}/assets/Misc/no_image.svg`;
            currentTarget.alt = "Image non disponible";
          }}
          src={`${process.env.REACT_APP_API_URL}/assets/OutilsPratiques/${tool.article_url}/preview/${tool.img_ex}`}
          alt={tool.previewAlt}
        />
      </div>
    </div>
  );
}

export default PracticalTool;
