import Duplicate from "../../atoms/icons/account/duplicate/Duplicate";
import Pen from "../../atoms/icons/account/pen/Pen";
import Download from "../../atoms/icons/general/download/Download";
import Eye from "../../atoms/icons/general/eye/Eye";
import HiddenEye from "../../atoms/icons/general/hiddenEye/HiddenEye";
import Trash from "../../atoms/icons/general/trash/Trash";

export const DeliverablesDropdownMenu = ({
  activeDeliverable,
  isDeliverableOpened,
  openDeliverable,
  showRenameDeliverableModal,
  showDuplicateDeliverableModal,
  showDeleteDeliverableModal,
}) => (
  <>
    <li>
      <button onClick={() => openDeliverable(activeDeliverable?.item?.uuid)}>
        {isDeliverableOpened ? (
          <>
            <HiddenEye color='var(--dark-blue)' width='22px' /> Fermer
          </>
        ) : (
          <>
            <Eye color='var(--dark-blue)' width='22px' /> Accéder
          </>
        )}
      </button>
    </li>
    <li>
      <button onClick={() => showRenameDeliverableModal(activeDeliverable?.item)}>
        <Pen width='22px' /> Renommer
      </button>
    </li>
    <li>
      <button onClick={() => downloadDeliverable(activeDeliverable?.item)}>
        <Download color='var(--dark-blue)' width='22px' /> Télécharger
      </button>
    </li>
    <li>
      <button onClick={() => showDuplicateDeliverableModal(activeDeliverable?.item)}>
        <Duplicate width='22px' /> Dupliquer
      </button>
    </li>
    <li>
      <button onClick={() => showDeleteDeliverableModal(activeDeliverable?.item)}>
        <Trash width='22px' /> Supprimer
      </button>
    </li>
  </>
);
