import "./AccountToolsTab.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "../../../../../context/AppContext";
import { useWindowSize } from "@uidotdev/usehooks";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import Loader from "../../../../../components/organisms/loader/Loader";
import { useAuth } from "../../../../../context/AuthContext";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import {
  EstimationCreateButton,
  EstimationReviewButton,
  EstimationTableColumns,
  getEstimationColumnContent,
  EstimationsDropdownMenu,
  filterEstimations,
} from "../../../../../components/organisms/resourcesComponents/EstimationsComponents";
import {
  FinancingPlanCreateButton,
  FinancingPlansReviewButton,
  FinancingPlanTableColumns,
  getFinancingPlanColumnContent,
  FinancingPlanDropdownMenu,
  filterFinancingPlans,
} from "../../../../../components/organisms/resourcesComponents/FinancingPlansComponents";
import {
  CreditSimulationCreateButton,
  CreditSimulationTableColumns,
  CreditSimulatorReviewButton,
  getCreditSimulationColumnContent,
  CreditSimulationDropdownMenu,
  filterCreditSimulations,
} from "../../../../../components/organisms/resourcesComponents/CreditSimulationsComponents";
import { useResources } from "../../../../../context/ResourcesContext";
import ResourceTable from "../../../../../components/organisms/resourceTable/ResourceTable";

//type = estimations / financingPlans / creditSimulations
function AccountToolsTab({ type, resources, refreshResource = () => {}, isLoaderVisible }) {
  const [searchValue, setSearchValue] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [visibleItems, setVisibleItems] = useState([]);
  const [dropdownResource, setDropdownResource] = useState(null);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);

  const { hasResourcesAccess } = useAuth();
  const { formatTableColumns, downloadResource, showDuplicateResourceModal, archiveResource } = useResources();
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const itemsComponents = {
    tableColumns: {
      estimations: EstimationTableColumns,
      financingPlans: FinancingPlanTableColumns,
      creditSimulations: CreditSimulationTableColumns,
    },
    filter: {
      estimations: filterEstimations,
      financingPlans: filterFinancingPlans,
      creditSimulations: filterCreditSimulations,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
    },
    dropdownMenu: {
      estimations: EstimationsDropdownMenu,
      financingPlans: FinancingPlanDropdownMenu,
      creditSimulations: CreditSimulationDropdownMenu,
    },
    createButton: {
      estimations: EstimationCreateButton,
      financingPlans: FinancingPlanCreateButton,
      creditSimulations: CreditSimulationCreateButton,
    },
    reviewButton: {
      estimations: EstimationReviewButton,
      financingPlans: FinancingPlansReviewButton,
      creditSimulations: CreditSimulatorReviewButton,
    },
  };

  const { width } = useWindowSize();

  useEffect(() => {
    refreshResource();
  }, []);

  useEffect(() => {
    setTableColumns(
      formatTableColumns(itemsComponents.tableColumns[type.description], width).filter(
        column => column.key !== "archived",
      ),
    );
  }, [type, width]);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
  }, [activeTab, type]);

  useEffect(() => {
    setVisibleItems(getFilteredItems(resources, type.description));
  }, [activeTab, searchValue, resources, type]);

  useEffect(() => {
    setDropdownResource(null);
  }, [visibleItems]);

  function getFilteredItems(items, typeDescription) {
    if (!items || !typeDescription) return [];
    return items[typeDescription].filter(item => item.archived === !!activeTab);
  }

  function showCantDownloadResourceModal() {
    setModalContent({
      title: "Non abonné",
      content: (
        <>
          <p>Vous devez être abonné pour télécharger cette ressource.</p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton padding='10px 25px' onClick={() => setModalVisible(false)} to='/tarifs'>
              Voir les offres
            </FilledButton>
          </div>
        </>
      ),
    });

    return setModalVisible(true);
  }

  function setActiveResource(e, item, index, itemsContainerRef) {
    if (!e) return setDropdownResource(null);

    e.stopPropagation();
    e.preventDefault();

    if (item === dropdownResource?.item) return setDropdownResource(null);

    setDropdownResource({
      item: item,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 80,
    });
  }

  //Quick actions

  async function downloadItemPdf(item) {
    downloadResource(item, type.description);
  }

  function showDuplicateItemModal(item) {
    showDuplicateResourceModal(item, type.description, () => refreshResource(type.description));
  }

  async function archiveItem(item, shouldArchive) {
    archiveResource(item, shouldArchive, type.description, () => refreshResource(type.description));
  }

  // Table functions

  function getOrderedResources(resources, orderBy) {
    let result = itemsComponents.filter[type.description](resources, searchValue);

    if (!orderBy.key) return result;

    result.sort((a, b) => {
      let valA = itemsComponents.getColumnContent[type.description](orderBy.key, a);
      let valB = itemsComponents.getColumnContent[type.description](orderBy.key, b);

      if (orderBy.key.includes("date_")) {
        valA = valA === "-" ? new Date() : stringToDate(valA + ":00");
        valB = valB === "-" ? new Date() : stringToDate(valB + ":00");
        return valA - valB;
      }

      if (typeof valA === "number" && typeof valB === "number") {
        return valA - valB;
      }

      return String(valA).localeCompare(String(valB), "fr", { numeric: true });
    });

    if (orderBy.order === "desc") result.reverse();

    return result;
  }

  return (
    <>
      <section className='container'>
        <div className='head-row-container'>
          {itemsComponents.createButton[type.description]({ width })}
          {itemsComponents.reviewButton[type.description](
            type.description === "estimations"
              ? {
                  text: "Donnez votre avis sur nos estimations",
                  link: "https://docs.google.com/forms/d/e/1FAIpQLSd_JMXir1jfCfMBZf8fnTK2DcWWw-8MlL8vQ4epWd_pJ4xWbA/viewform?usp=pp_url",
                }
              : type.description === "financingPlans"
                ? {
                    text: "Donnez votre avis sur nos plans de financement",
                    link: "https://docs.google.com/forms/d/e/1FAIpQLSf9YmzgyjBxWJnmTLHHSukHcw9dvK1Kscos1jkxdyYaJOrkHA/viewform?usp=pp_url",
                  }
                : type.description === "creditSimulations"
                  ? {
                      text: "Donnez votre avis sur notre simulateur de prêt",
                      link: "https://docs.google.com/forms/d/e/1FAIpQLScUqDnORP1LLZ4TFgRnNNTRzh_eeHgPY2-5kmPfmwXyQXG5mg/viewform?usp=pp_url",
                    }
                  : {},
          )}
        </div>
        <div className='items-container'>
          <div className='items-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              {type.name}
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              {type.name} archivé{!type.isMasculine && "e"}s
            </p>
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor='var(--white)'
              className='items-search-bar'
            />
          </div>
          <ResourceTable
            dropdownMenu={itemsComponents.dropdownMenu[type.description]({
              activeItem: dropdownResource?.item,
              downloadItemPdf,
              showDuplicateItemModal,
              archiveItem,
            })}
            resourceTypeDescription={type.description}
            resources={visibleItems}
            selectedResource={dropdownResource}
            tableColumns={tableColumns}
            useCheckbox={false}
            getOrderedResourcesFunction={getOrderedResources}
            getColumnContentFunction={itemsComponents.getColumnContent[type.description]}
            setSelectedResourceFunction={setActiveResource}
          />
          <Loader visible={isLoaderVisible} className='tools-loader' preventScrolling={false} />
        </div>
      </section>
    </>
  );
}

export default AccountToolsTab;
