import "./AccountProfile.css";
import React, { useContext, useEffect, useState } from "react";
import Card from "../../../../../components/molecules/cards/card/Card";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import ExpendableGroup from "../../../../../components/organisms/expendableGroup/ExpendableGroup";
import VisitingCardForm from "../../../../../components/forms/account/visitingCardForm/VisitingCardForm";
import FileInput from "../../../../../components/molecules/formComponents/fileInput/FileInput";
import RapportLogo from "../../../../../components/atoms/icons/account/rapportLogo/RapportLogo";
import Lock from "../../../../../components/atoms/icons/account/lock/Lock";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ArrowSm from "../../../../../components/atoms/icons/general/arrowSm/ArrowSm";
import ProfileForm from "../../../../../components/forms/account/profileForm/ProfileForm";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../context/AppContext";
import ForgotPasswordModal from "../../../../../components/forms/modals/account/forgotPasswordModal/ForgotPasswordModal";
import { useAuth } from "../../../../../context/AuthContext";
import {
  createVisitingCardApi,
  fetchVisitingCardsByUuidApi,
  updateVisitingCardApi,
} from "../../../../../api/VisitingCardApi";
import {
  deactivateAccountApi,
  deleteAccountApi,
  fetchUserPdfCustomisationApi,
  logoutUserApi,
} from "../../../../../api/AccountApi";
import UnfilledButton from "../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import EditPdfCustomisationForm from "../../../../../components/forms/account/editPdfCustomisationForm/EditPdfCustomisationForm";
import Modal from "../../../../../components/organisms/modal/Modal";
import ResourceTable from "../../../../../components/organisms/resourceTable/ResourceTable";
import {
  filterVisitingCards,
  getVisitingCardColumnContent,
  VisitingCardDropdownMenu,
  VisitingCardTableColumns,
} from "../../../../../components/organisms/resourcesComponents/VisitingCardsComponents";
import { useResources } from "../../../../../context/ResourcesContext";
import UpdateVisitingCardModal from "../../../../../components/forms/modals/account/tools/updateVisitingCardModal/UpdateVisitingCardModal";
import { stringToDate } from "../../../../../utils/Utils";
import Loader from "../../../../../components/organisms/loader/Loader";

function AccountProfile({ navbarRef }) {
  const { width } = useWindowSize();
  const { createNotification, setModalContent, setModalVisible } = useContext(AppContext);
  const { getUuid, userData, setUuid } = useAuth();
  const navigate = useNavigate();
  const { fetchUserResource, formatTableColumns, showDeleteVisitingCardModal, setDefaultVisitingCard } = useResources();
  const [searchParams, setSearchParams] = useSearchParams();

  const visitingCardComponents = {
    tableColumns: VisitingCardTableColumns,
    filter: filterVisitingCards,
    DropdownMenu: VisitingCardDropdownMenu,
    getColumnContent: getVisitingCardColumnContent,
  };

  const [visitingCards, setVisitingCards] = useState([]);
  const [isVisitingCardsLoaderVisible, setIsVisitingCardsLoaderVisible] = useState(true);
  const [visitingCardsTableColumns, setVisitingCardsTableColumns] = useState([]);
  const [dropdownItem, setDropdownItem] = useState(null);
  const [pdfCustomisation, setPdfCustomisation] = useState();
  const [customisationModalVisible, setCustomisationModalVisible] = useState(false);

  useEffect(() => {
    fetchVisitingCards();
    fetchPdfCustomisation();
  }, []);

  useEffect(() => {
    setVisitingCardsTableColumns(formatTableColumns(visitingCardComponents.tableColumns, width));
  }, [width]);

  useEffect(() => {
    if (!searchParams.get("visiting_card_id")) return;
    showVisitingCardModal(searchParams.get("visiting_card_id"));

    searchParams.delete("visiting_card_id");
    setSearchParams(searchParams);
  }, [searchParams, visitingCards]);

  // Visiting cards

  async function fetchVisitingCards(shouldOpenNewVisitingCard) {
    const res = await fetchUserResource(getUuid(), "visitingCards");
    setVisitingCards(res);
    if (shouldOpenNewVisitingCard)
      setTimeout(() => {
        navigate(`?visiting_card_id=${res[0].uuid}`);
      }, 500);

    setIsVisitingCardsLoaderVisible(false);
  }

  function showVisitingCardModal(visitingCardUuid) {
    const visitingCard = visitingCards.find(visitingCard => visitingCard.uuid == visitingCardUuid);
    if (!visitingCard) return;

    setModalContent({
      title: "Carte de visite",
      content: <UpdateVisitingCardModal visitingCard={visitingCard} onSuccess={fetchVisitingCards} />,
      className: visitingCard.card_type === "created_card" ? "profile-modal" : "medium-modal",
    });

    setModalVisible(true);
  }

  function generateVisitingCardName(visitingCards, imported) {
    const genericNamesCount = visitingCards.filter(card => /^carte \d+$/.test(card.card_name)).length;
    return `carte ${genericNamesCount + 1}${imported ? " (importée)" : ""}`;
  }

  async function createVisitingCard(compressedFile) {
    try {
      const card = (
        await createVisitingCardApi(
          generateVisitingCardName(visitingCards, !!compressedFile),
          compressedFile ? "imported_card" : "created_card",
        )
      ).data;

      if (compressedFile)
        await updateVisitingCardApi(card.id, {
          ...card,
          images: { card: compressedFile },
        });

      fetchVisitingCards(true);

      createNotification(<>Votre carte de visite a été créée avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de votre estimation</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function toggleVisitingCardDefault(e, visitingCard) {
    e.preventDefault();

    setDefaultVisitingCard(visitingCard.uuid, !visitingCard.is_default, visitingCards, fetchVisitingCards);
  }

  function deleteVisitingCard(visitingCard) {
    showDeleteVisitingCardModal(visitingCard.uuid, fetchVisitingCards);
  }

  function setActiveVisitingCard(e, visitingCard, index, itemsContainerRef) {
    if (!e) return setDropdownItem(null);

    e.stopPropagation();
    e.preventDefault();

    if (visitingCard === dropdownItem?.item) return setDropdownItem(false);

    setDropdownItem({
      item: visitingCard,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 80,
    });
  }

  function getOrderedVisitingCards(visitingCards, orderBy) {
    let result = visitingCardComponents.filter(visitingCards, "");

    if (!orderBy.key) return result;

    result.sort((a, b) => {
      let valA = visitingCardComponents.getColumnContent(orderBy.key, a);
      let valB = visitingCardComponents.getColumnContent(orderBy.key, b);

      if (orderBy.key.includes("date_")) {
        valA = valA === "-" ? new Date() : stringToDate(valA + ":00");
        valB = valB === "-" ? new Date() : stringToDate(valB + ":00");
        return valA - valB;
      }

      if (typeof valA === "number" && typeof valB === "number") {
        return valA - valB;
      }

      return String(valA).localeCompare(String(valB), "fr", { numeric: true });
    });

    if (orderBy.order === "desc") result.reverse();

    return result;
  }

  // Pdf customisation

  async function fetchPdfCustomisation() {
    try {
      const res = (await fetchUserPdfCustomisationApi(getUuid())).data;

      setPdfCustomisation(res);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de vos logos d'entête / bas de page. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showEditPdfCustomisationModal(pdfCustomisation) {
    if (pdfCustomisation) setCustomisationModalVisible(true);
  }
  // Buttons

  function showForgotPasswordModal() {
    setModalContent({ title: "Mot de passe oublié", content: <ForgotPasswordModal email={userData.email} /> });
    setModalVisible(true);
  }

  function showDeactivateAccountModal() {
    setModalContent({
      title: "Désactiver mon compte",
      content: (
        <>
          <p>
            Êtes-vous sûr de vouloir désactiver votre compte ?
            <br />
            <br />
            En cliquant sur "Valider", EstimerMonCommerce.fr n'utilisera plus vos données.
            <br />
            <br />
            Vous pourrez cependant réactiver votre compte à tout moment par une simple connexion.
          </p>
          <div className='modal-buttons-row mt-md'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={deactivateAccount}>Valider</FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function deactivateAccount() {
    try {
      setModalVisible();
      await deactivateAccountApi(getUuid());
      await logoutUserApi();
      setUuid(null);

      navigate("/");
      createNotification(<>Votre compte a été désactivé avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la désactivation de votre compte. Veuillez réessayer plus tard.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function showDeleteAccountModal() {
    setModalContent({
      title: "Supprimer mon compte",
      content: (
        <>
          <p>
            Êtes-vous sûr de vouloir supprimer votre compte ?
            <br />
            <br />
            En cliquant sur "Valider", EstimerMonCommerce.fr détruira vos données définitivement.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={deleteAccount}>Valider</FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function deleteAccount() {
    try {
      setModalVisible();
      await deleteAccountApi(getUuid());
      await logoutUserApi();
      setUuid(null);

      navigate("/");
      createNotification(<>Votre compte a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de votre compte. Veuillez réessayer plus tard.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <>
      <Modal
        className='profile-modal'
        canHideOnClickOutside
        title="Modifier le visuel de mes rapports d'estimation"
        visible={customisationModalVisible}
        setVisible={setCustomisationModalVisible}>
        <EditPdfCustomisationForm
          getUuid={getUuid}
          customisation={pdfCustomisation}
          onChange={setPdfCustomisation}
          setModalVisible={setCustomisationModalVisible}
        />
      </Modal>
      <section className='container'>
        <div className='mon-profil-container'>
          <div>
            <Card>
              <h2>MES CARTES DE VISITE ET MENTIONS LÉGALES</h2>
              <div className='visiting-card-buttons'>
                <FileInput
                  onChange={createVisitingCard}
                  name='import-visiting-card'
                  removeButton={false}
                  padding='10px 20px'
                  bgColor='var(--sky-blue)'
                  buttonText='Importer une carte de visite'
                />
                <FilledButton onClick={() => createVisitingCard()} padding='10px 20px'>
                  Créer&nbsp;une&nbsp;carte
                </FilledButton>
              </div>
              <div className='relative'>
                <ResourceTable
                  dropdownMenu={visitingCardComponents.DropdownMenu({
                    activeItem: dropdownItem?.item,
                    deleteItem: deleteVisitingCard,
                  })}
                  resources={visitingCards}
                  resourceTypeDescription='visitingCards'
                  selectedResource={dropdownItem}
                  tableColumns={visitingCardsTableColumns}
                  useCheckbox={false}
                  getOrderedResourcesFunction={getOrderedVisitingCards}
                  getColumnContentFunction={visitingCardComponents.getColumnContent}
                  toggleVisitingCardDefaultFunction={toggleVisitingCardDefault}
                  setSelectedResourceFunction={setActiveVisitingCard}
                />
                <Loader visible={isVisitingCardsLoaderVisible} className='folder-loader' />
              </div>
            </Card>
            <Card className='mt-lg mon-profil' bgColor='var(--light-blue)'>
              <h2 className='mb-lg'>MON PROFIL</h2>
              <ProfileForm userData={userData} />
            </Card>
            {width <= 992 && (
              <>
                <div className='mon-profil-responsive-buttons'>
                  {/*<ReviewButton modalTitle="Votre avis sur nos dossiers" modalText="un dossier" link="https://docs.google.com/forms/d/e/1FAIpQLSdRr400a39dx9vsAd8lVmpibeV2_1N1GmsdaA3zbvoMATffGQ/viewform?usp=pp_url">Donnez votre avis sur nos dossiers</ReviewButton>*/}
                  <FilledButton
                    bgColor='var(--light-blue)'
                    padding='20px'
                    color='var(--dark-blue)'
                    onClick={() => showEditPdfCustomisationModal(pdfCustomisation)}>
                    <RapportLogo />
                    Modifier le visuel de mes rapports d'estimation
                  </FilledButton>
                  <FilledButton
                    bgColor='var(--light-gold)'
                    padding='20px'
                    color='var(--dark-blue)'
                    onClick={showForgotPasswordModal}>
                    <Lock />
                    Modifier mon mot de passe
                  </FilledButton>
                </div>
                <div className='mon-profil-links'>
                  <div className='text-grey link-with-arrow' onClick={showDeactivateAccountModal}>
                    Désactiver mon compte
                    <ArrowSm color='var(--grey)' rotation='180deg' />
                  </div>
                  <div className='text-grey link-with-arrow' onClick={showDeleteAccountModal}>
                    Supprimer mon compte
                    <ArrowSm color='var(--grey)' rotation='180deg' />
                  </div>
                </div>
              </>
            )}
          </div>
          {width > 992 && (
            <div className='flex f-column gap-lg'>
              {/*<ReviewButton modalTitle="Votre avis sur nos dossiers" modalText="un dossier" link="https://docs.google.com/forms/d/e/1FAIpQLSdRr400a39dx9vsAd8lVmpibeV2_1N1GmsdaA3zbvoMATffGQ/viewform?usp=pp_url">Donnez votre avis sur nos dossiers</ReviewButton>*/}
              <FilledButton
                bgColor='var(--light-blue)'
                padding='20px'
                color='var(--dark-blue)'
                onClick={() => showEditPdfCustomisationModal(pdfCustomisation)}>
                <RapportLogo />
                Modifier le visuel de mes rapports d'estimation
              </FilledButton>
              <FilledButton
                bgColor='var(--light-gold)'
                padding='20px'
                color='var(--dark-blue)'
                onClick={showForgotPasswordModal}>
                <Lock />
                Modifier mon mot de passe
              </FilledButton>
              <div className='mon-profil-links'>
                <div className='text-grey link-with-arrow' onClick={showDeactivateAccountModal}>
                  Désactiver mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </div>
                <div className='text-grey link-with-arrow' onClick={showDeleteAccountModal}>
                  Supprimer mon compte
                  <ArrowSm color='var(--grey)' rotation='180deg' />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default AccountProfile;
