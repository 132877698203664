import "./ContributionCalculator.css";
import React, { useContext, useEffect } from "react";
import ContributionCalculatorForm from "../../../components/forms/contributionCalculatorForm/ContributionCalculatorForm";
import AppContext from "../../../context/AppContext";

function ContributionCalculator() {
  const { setBannerConfig } = useContext(AppContext);

  useEffect(() => {
    setBannerConfig({
      title: <>Calculateur de cotisations</>,
      subtitle: <>Estimez le montant des cotisations patronales</>,
    });
  }, []);

  return (
    <>
      <section className='container page-container'>
        <ContributionCalculatorForm />
      </section>
    </>
  );
}

export default ContributionCalculator;
