import "./CreateDeliverableModal.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useResources } from "../../../../../context/ResourcesContext";
import ResourceTable from "../../../../organisms/resourceTable/ResourceTable";
import {
  EstimationTableColumns,
  getEstimationColumnContent,
} from "../../../../organisms/resourcesComponents/EstimationsComponents";
import {
  FinancingPlanTableColumns,
  getFinancingPlanColumnContent,
} from "../../../../organisms/resourcesComponents/FinancingPlansComponents";
import {
  CreditSimulationTableColumns,
  getCreditSimulationColumnContent,
} from "../../../../organisms/resourcesComponents/CreditSimulationsComponents";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../context/AppContext";
import {
  addCreditSimulatorToFolderApi,
  addEstimationToFolderApi,
  addFinancingPlanToFolderApi,
  createDeliverableApi,
} from "../../../../../api/FolderApi";
import UnfilledButton from "../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../molecules/buttons/filledButton/FilledButton";
import Input from "../../../../molecules/formComponents/input/Input";
import Bracket from "../../../../atoms/icons/general/bracket/Bracket";
import { toggleCollapseElement } from "../../../../../utils/Utils";

function CreateDeliverableModal({
  folder,
  isUpdate,
  deliverableUuid,
  updatingTypes = [2, 3, 4],
  onSuccess = () => {},
}) {
  const { getResourceName, formatTableColumns, getTypesTable, importResourceToDeliverable } = useResources();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const { width } = useWindowSize();
  const tableComponents = {
    tabColumns: {
      estimations: EstimationTableColumns,
      financingPlans: FinancingPlanTableColumns,
      creditSimulations: CreditSimulationTableColumns,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
    },
    addResourceToFolder: {
      estimations: addEstimationToFolderApi,
      financingPlans: addFinancingPlanToFolderApi,
      creditSimulations: addCreditSimulatorToFolderApi,
    },
  };

  const typesTable = getTypesTable(updatingTypes);
  const categoriesRefs = useRef([]);

  const [selectedResources, setSelectedResources] = useState({
    estimations: [],
    financingPlans: [],
    creditSimulations: [],
  });
  const [tablesColumns, setTableColumns] = useState({
    estimations: [],
    financingPlans: [],
    creditSimulations: [],
  });
  const [deliverableName, setDeliverableName] = useState("");
  const [deliverableNameError, setDeliverableNameError] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [openedCategory, setOpenedCategory] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      if (folder?.estimations?.length && updatingTypes.includes(2)) toggleOpenedCategory(openedCategory, 0);
      else if (folder?.financingPlans?.length && updatingTypes.includes(3)) toggleOpenedCategory(openedCategory, 1);
      else if (folder?.creditSimulations?.length && updatingTypes.includes(4)) toggleOpenedCategory(openedCategory, 2);
    }, 100);
  }, [folder]);

  useEffect(() => {
    formatMultipleTablesColumns(width);
  }, [width]);

  function formatMultipleTablesColumns(width) {
    const result = {};

    typesTable.forEach(type => {
      result[type.description] = formatTableColumns(tableComponents.tabColumns[type.description], width).filter(
        column => column.key !== "archived",
      );
    });

    setTableColumns(result);
  }

  function handleSubmit() {
    if (isUpdate) return updateDeliverable();

    createDeliverable();
  }

  async function updateDeliverable() {
    try {
      setIsButtonLoading(true);
      await Promise.all(
        typesTable.flatMap(type =>
          selectedResources[type.description].map(resourceUuid =>
            importResourceToDeliverable(resourceUuid, deliverableUuid, type.description),
          ),
        ),
      );

      onSuccess();
      createNotification(<>Les ressources ont été importées avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'import des ressources. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setIsButtonLoading(false);
      setModalVisible(false);
    }
  }

  async function createDeliverable() {
    try {
      if (!deliverableName.length) return setDeliverableNameError("Ce champ est obligatoire");

      setIsButtonLoading(true);

      await createDeliverableApi(
        deliverableName,
        folder.uuid,
        selectedResources.estimations.map(id => ({ id: id })),
        selectedResources.financingPlans.map(id => ({ id: id })),
        selectedResources.creditSimulations.map(id => ({ id: id })),
      );

      onSuccess();
      createNotification(<>Votre livrable a été créé avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de votre livrable. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
      setIsButtonLoading(false);
    }
  }

  function toggleResourceSelection(e, resource, resourceTypeName) {
    e.preventDefault();

    setSelectedResources(selectedResources => {
      let resourcesUuid = selectedResources[resourceTypeName];

      if (resourcesUuid.includes(resource.uuid))
        resourcesUuid = resourcesUuid.filter(resourceUuid => resourceUuid !== resource.uuid);
      else resourcesUuid.push(resource.uuid);

      return {
        ...selectedResources,
        [resourceTypeName]: resourcesUuid,
      };
    });
  }

  function updateDeliverableName(e) {
    const value = e.target.value;
    setDeliverableName(value);
    if (value && deliverableNameError != "") setDeliverableNameError("");
  }

  function toggleOpenedCategory(openedCategory, category) {
    const categoryRef = categoriesRefs.current[category];
    const previewCategoryRef = categoriesRefs.current[openedCategory];

    if (previewCategoryRef) toggleCollapseElement(previewCategoryRef, false);

    if (openedCategory === category) return setOpenedCategory(-1);

    setOpenedCategory(category);

    if (!categoryRef) return;

    if (openedCategory === 0) return toggleCollapseElement(categoryRef, true);

    return toggleCollapseElement(categoryRef, true);
  }

  function isCreateButtonDisabled(selectedResources) {
    if (!isUpdate) return false;

    let result = true;

    Object.keys(selectedResources).forEach(key => {
      if (selectedResources[key].length) result = false;
    });

    return result;
  }

  return (
    <div className='import-resources-container'>
      {!isUpdate && (
        <>
          <Input
            label='Nom du livrable'
            error={deliverableNameError}
            value={deliverableName}
            onChange={updateDeliverableName}
          />
          <p className='text-grey mb-md'>
            Choisissez ci-dessous les estimations, plans de financement et simulations de crédit à importer dans votre
            livrable&nbsp;:
          </p>
        </>
      )}
      {typesTable.map(type => (
        <div key={type.description}>
          <h3 onClick={() => toggleOpenedCategory(openedCategory, type.index - 2)} className='create-deliverable-label'>
            Importer des {getResourceName(type.description)}
            <Bracket
              color='var(--dark-blue)'
              rotation={openedCategory === type.index - 2 ? "180deg" : "90deg"}
              width='15px'
            />
          </h3>
          <div className='create-deliverable-table' ref={el => (categoriesRefs.current[type.index - 2] = el)}>
            <ResourceTable
              checkboxesArray={selectedResources[type.description]}
              setCheckboxesArrayFunction={(e, resource) => toggleResourceSelection(e, resource, type.description)}
              resourceTypeDescription={type.description}
              onResourceClick={(e, resource) => toggleResourceSelection(e, resource, type.description)}
              resources={folder[type.description]}
              noResultText='Aucun résultat'
              getColumnContentFunction={tableComponents.getColumnContent[type.description]}
              tableColumns={tablesColumns[type.description]}
              useCheckbox
            />
          </div>
        </div>
      ))}
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton
          isLoading={isButtonLoading}
          padding='10px 25px'
          disabled={isCreateButtonDisabled(selectedResources)}
          onClick={handleSubmit}>
          Importer
        </FilledButton>
      </div>
    </div>
  );
}

export default CreateDeliverableModal;
