import React, { useState, useEffect, useContext } from "react";
import "./AccountTools.css";
import Hr from "../../../../../components/molecules/hr/Hr";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { normalizeString } from "../../../../../utils/Utils";
import { useAuth } from "../../../../../context/AuthContext";
import AppContext from "../../../../../context/AppContext";
import AccountToolsTab from "../accountsToolsTab/AccountToolsTab";
import { useResources } from "../../../../../context/ResourcesContext";
import CrmResourcesMenu from "../../../../../components/crmComponents/crmResourcesMenu/CrmResourcesMenu";

function AccountTools({ setIsTutoVideoVisible = () => {} }) {
  const [typesTable, setTypesTable] = useState([]);
  const [selectedTool, setSelectedTool] = useState({
    index: 2,
    description: "estimations",
    name: "Mes estimations",
    urlSegment: "mes-estimations",
  });
  const [refreshItems, setRefreshItems] = useState(null);
  const [toolsLoaderVisible, setToolsLoaderVisible] = useState(true);
  const [resources, setResources] = useState({
    estimations: [],
    financingPlans: [],
    creditSimulations: [],
  });
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  const navigate = useNavigate();
  const { subTabParam } = useParams();
  const { getUuid } = useAuth();
  const { createNotification, setBreadcrumbsSpecialValue } = useContext(AppContext);
  const { getResourceName, getTypesTable, fetchUserAllResources } = useResources();

  useEffect(() => {
    setupTypesTable();
  }, []);

  useEffect(() => {
    fetchResources();
  }, [refreshItems, typesTable]);

  useEffect(() => {
    if (!typesTable.length) return;

    const selectedTool = typesTable.find(tool => tool.urlSegment === subTabParam) || typesTable[0];

    setSelectedTool(selectedTool);

    setBreadcrumbsSpecialValue({
      to: "/mes-outils/" + selectedTool.urlSegment,
      name: selectedTool.name,
    });
  }, [subTabParam, typesTable]);

  function setupTypesTable() {
    const result = getTypesTable([2, 3, 4], true);

    setTypesTable(
      result.map(type => {
        const name = "Mes " + getResourceName(type.description, true, false);

        return {
          ...type,
          name: name,
          urlSegment: normalizeString(name).toLowerCase().replaceAll(" ", "-"),
        };
      }),
    );
  }

  async function fetchResources(resourceDescription) {
    if (!typesTable.length) return;

    const resourcesDescription = resourceDescription ? resourceDescription : typesTable.map(type => type.description);
    const res = await fetchUserAllResources(getUuid(), resourcesDescription);

    setResources(prev => ({ ...prev, ...res }));
    if (toolsLoaderVisible) setToolsLoaderVisible(false);
  }

  function showAccountTutoModal() {
    setIsTutoVideoVisible(true);
  }

  function navigateTo(e, tool) {
    e.preventDefault();

    setSelectedTool(tool);

    navigate(`/mon-compte/mes-outils/${tool.urlSegment}`);
  }

  return (
    <section className='container'>
      <div className='crm-client-view-container'>
        <CrmResourcesMenu
          title='Mes outils'
          bonusSection={
            <div className='account-tuto-thumbnail'>
              <h2 className='tools-title'>Tutoriel</h2>
              <Hr margin='10px 0 15px' />
              <img
                src={require("../../../../../assets/account/account_tuto_thumbnail.svg").default}
                alt='Tuto mon compte'
                onClick={showAccountTutoModal}
              />
            </div>
          }>
          {typesTable.map(tool => (
            <div
              key={tool.index}
              className={`crm-resource-menu-item ${selectedTool.index === tool.index ? "active" : ""}`}
              onClick={e => navigateTo(e, tool)}>
              <tool.Icon width='30px' />
              {tool.name}
            </div>
          ))}
        </CrmResourcesMenu>
        <div className='tool-content'>
          {selectedTool?.description ? (
            <AccountToolsTab
              isLoaderVisible={toolsLoaderVisible}
              type={selectedTool}
              resources={resources}
              refreshResource={fetchResources}
            />
          ) : (
            <Navigate to='/404' />
          )}
        </div>
      </div>
    </section>
  );
}

export default AccountTools;
