import React, { useContext, useEffect, useState, Fragment } from "react";
import "./Cart.css";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import Card from "../../../components/molecules/cards/card/Card";
import { useCart } from "../../../context/CartContext";
import Trash from "../../../components/atoms/icons/general/trash/Trash";
import Hr from "../../../components/molecules/hr/Hr";
import Check from "../../../components/atoms/icons/general/check/Check";
import AppContext from "../../../context/AppContext";
import { useWindowSize } from "@uidotdev/usehooks";
import { useSearchParams } from "react-router-dom";
import { formatPrice } from "../../../utils/Utils";

function Cart() {
  const [detailsVisibility, setDetailsVisibility] = useState({});
  const [pricesDetail, setPricesDetail] = useState({
    totalHT: 0,
    totalTTC: 0,
    tva: 0,
    tvaRate: 0.2,
    totalReduction: 0,
  });
  const [orderLink, setOrderLink] = useState("/commande");

  const { cart, removeFromCart } = useCart();
  const { setModalContent, setModalVisible, setBannerConfig } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const { width } = useWindowSize();

  useEffect(() => {
    setBannerConfig({
      title: <>Panier</>,
      subtitle: (
        <>Validez ou modifiez le contenu de votre panier avant de commander et de recevoir votre rapport d'estimation</>
      ),
    });
  }, []);

  useEffect(() => {
    const fallbackUrl = searchParams.get("fallback-url");
    if (fallbackUrl) setOrderLink("/commande?fallback-url=" + fallbackUrl);
    else setOrderLink("/commande");
  }, [searchParams]);

  useEffect(() => {
    calculatePrices();
  }, [cart]);

  function calculatePrices() {
    if (!cart.length) return resetPrices();

    const detail = pricesDetail;

    detail.totalReduction = cart[0].discount ? cart[0].discount.discounted_price_by_month : 0;
    detail.totalHT = cart[0].base_price_by_month - detail.totalReduction;
    detail.tva = detail.totalHT * detail.tvaRate;
    detail.totalTTC = detail.tva + detail.totalHT;

    setPricesDetail(detail);
  }

  function resetPrices() {
    setPricesDetail({
      totalHT: 0,
      totalTTC: 0,
      tva: 0,
      tvaRate: 0.2,
      totalReduction: 0,
    });
  }

  function toggleDetails(index) {
    setDetailsVisibility(prevState => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  }

  function showDetailModal(item) {
    setModalContent({
      title: "Détails de l'offre " + item.price_name,
      content: (
        <div className='details-content'>
          <ul>
            <li className='flex details-1-cart'>
              <Check width='15px' className='check-min-width' />
              Nombre illimité d'estimations avec rapport détaillé
            </li>
            <li className='flex details-1-cart'>
              <Check width='15px' className='check-min-width' />
              Accès aux ressources suivantes :
            </li>
            <ul className='ml-md dots-list details-2-cart'>
              <li>Outils pratiques à télécharger</li>
              <li>Conseils de pros</li>
              <li>Nos guides pour agents immos</li>
              <li>Plan de financement</li>
              <li>Simulateur de crédit</li>
              <li>Calculateur de cotisations</li>
            </ul>
          </ul>
        </div>
      ),
    });

    setModalVisible(true);
  }

  return (
    <>
      <section className='container page-container'>
        <div className='panier-container'>
          {cart.length > 0 ? (
            <Fragment>
              <div className='panier-remplie-container'>
                <Card bgColor='var(--light-blue)' className='abonnement-card'>
                  <h2>Mon Panier ({cart.length} offre)</h2>
                  <Hr />
                  <table className='cart-table'>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Nom de l'offre</th>
                        <th>Inclus dans votre offre</th>
                        <th className='quantite-offre-cart'>{width <= 576 ? "Qté" : "Quantité"}</th>
                        <th className='prix-ht-offre-cart'>Prix (HT)</th>
                      </tr>
                      <tr>
                        <td colSpan='5'>
                          <Hr />
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {cart.map(item => (
                        <Fragment key={item.price_id}>
                          <tr>
                            <td>
                              <Trash onClick={() => removeFromCart()} />
                            </td>
                            <td className='selection-offre-cart'>{item.price_name}</td>
                            <td className='inclus-offre-cart'>
                              {width <= 576 ? (
                                <span className='mobile-details-link' onClick={() => showDetailModal(item)}>
                                  Détails
                                </span>
                              ) : (
                                ""
                              )}
                              <ul className={width <= 576 ? "d-none" : ""}>
                                <li className='flex details-1-cart'>
                                  <Check width='15px' className='check-min-width' />
                                  Nombre illimité d'estimations avec rapport détaillé
                                </li>
                                <li className='flex details-1-cart'>
                                  <Check width='15px' className='check-min-width' />
                                  Accès aux ressources suivantes :
                                </li>
                                <ul
                                  className={`ml-md dots-list details-2-cart ${
                                    detailsVisibility[item.price_id] ? "show" : ""
                                  }`}>
                                  <li>Outils pratiques à télécharger</li>
                                  <li>Conseils de pros</li>
                                  <li>Nos guides pour agents immos</li>
                                  <li>Plan de financement</li>
                                  <li>Simulateur de crédit</li>
                                  <li>Calculateur de cotisations</li>
                                </ul>
                                <span className='toggle-button' onClick={() => toggleDetails(item.price_id)}>
                                  {detailsVisibility[item.price_id] ? "Voir moins" : "Voir plus"}
                                  Voir plus
                                </span>
                              </ul>
                            </td>
                            <td className='quantite-offre-cart'>1</td>
                            <td className='prix-ht-offre-cart'>{formatPrice(item.base_price_by_month)}</td>
                          </tr>
                          {item.is_commitment ? (
                            <tr>
                              <td></td>
                              <td colSpan='4' className='engagement-abonnement-cart'>
                                Abonnement avec engagement de {item.commitment_duration_in_months} mois
                              </td>
                            </tr>
                          ) : null}
                          <tr>
                            <td colSpan='5'>
                              <Hr />
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </table>
                  <div className='prix-details-section-cart'>
                    <div className='prix-details-cart'>
                      {pricesDetail.totalReduction > 0 && (
                        <div className='total-reduction'>
                          <p>Réduction</p>
                          <p>{`-${formatPrice(pricesDetail.totalReduction)}`}</p>
                        </div>
                      )}
                      <div className='total-ht'>
                        <p>Total HT :</p>
                        <p>{formatPrice(pricesDetail.totalHT)}</p>
                      </div>
                      <div className='total-tva'>
                        <p>Total TVA 20% :</p>
                        <p>{formatPrice(pricesDetail.tva)}</p>
                      </div>
                      <Hr />
                      <div className='total-ttc'>
                        <p>Total TTC :</p>
                        <p>{formatPrice(pricesDetail.totalTTC)}</p>
                      </div>
                    </div>
                  </div>
                  <div className='button-suivant-cart'>
                    <FilledButton padding='10px 20px' fontSize='1.2rem' to={orderLink}>
                      Suivant
                    </FilledButton>
                  </div>
                </Card>
              </div>
            </Fragment>
          ) : (
            <Card bgColor='var(--light-blue)' className='panier-vide-card' padding={"60px"}>
              <h2>Votre panier est vide</h2>
              <p>Nous vous invitons à cliquer sur le bouton ci-dessous afin de consulter nos différentes offres</p>
              <FilledButton to='/tarifs' className='redirection-tarif-button'>
                Consulter nos offres
              </FilledButton>
            </Card>
          )}
        </div>
      </section>
    </>
  );
}

export default Cart;
