import { useCrm } from "../../../../context/CrmContext";
import Add from "../../../atoms/icons/general/add/Add";
import Download from "../../../atoms/icons/general/download/Download";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import Checkbox from "../../../molecules/formComponents/checkbox/Checkbox";
import Searchbar from "../../../molecules/searchbar/Searchbar";
import "./CrmClientItems.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import Bracket from "../../../atoms/icons/general/bracket/Bracket";
import Copy from "../../../atoms/icons/account/copy/Copy";
import { Link, useSearchParams } from "react-router-dom";
import Shop from "../../../atoms/icons/header/shop/Shop";
import Factory from "../../../atoms/icons/header/factory/Factory";
import Building from "../../../atoms/icons/header/building/Building";
import Unarchive from "../../../atoms/icons/account/unarchive/Unarchive";
import Archive from "../../../atoms/icons/account/archive/Archive";
import Check from "../../../atoms/icons/general/check/Check";
import ProgressCircle from "../../../atoms/progressCircle/ProgressCircle";
import Toggle from "../../../molecules/formComponents/toggle/Toggle";
import { stringToDate } from "../../../../utils/Utils";
import DropdownMenu from "../../../molecules/dropdownMenu/DropdownMenu";
import {
  EstimationsDropdownMenu,
  EstimationTableColumns,
  filterEstimations,
  getEstimationColumnContent,
} from "../../../organisms/resourcesComponents/EstimationsComponents";
import {
  filterFolders,
  folderDropdownMenu,
  FolderTableColumns,
  getFolderColumnContent,
} from "../../../organisms/resourcesComponents/FoldersComponents";
import {
  filterFinancingPlans,
  FinancingPlanDropdownMenu,
  FinancingPlanTableColumns,
  getFinancingPlanColumnContent,
} from "../../../organisms/resourcesComponents/FinancingPlansComponents";
import {
  CreditSimulationDropdownMenu,
  CreditSimulationTableColumns,
  filterCreditSimulations,
  getCreditSimulationColumnContent,
} from "../../../organisms/resourcesComponents/CreditSimulationsComponents";
import {
  filterVisitingCards,
  getVisitingCardColumnContent,
  VisitingCardDropdownMenu,
  VisitingCardTableColumns,
} from "../../../organisms/resourcesComponents/VisitingCardsComponents";
import { useResources } from "../../../../context/ResourcesContext";
import ResourceTable from "../../../organisms/resourceTable/ResourceTable";
import AppContext from "../../../../context/AppContext";
import UpdateVisitingCardModal from "../../../forms/modals/account/tools/updateVisitingCardModal/UpdateVisitingCardModal";

function CrmClientItems({ type, items, updateClientItemsTrigger }) {
  const [typeInfos, setTypeInfos] = useState({
    pageTitle: "",
    searchPlaceholder: "",
    createButtonLabel: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [resetSerchValueTrigger, setResetSerchValueTrigger] = useState(false);
  const [tabColumns, setTabColumns] = useState([]);
  const [dropdownItem, setDropdownItem] = useState(null);
  const [resetOrderByTrigger, setResetOrderByTrigger] = useState(false);

  const tabComponents = {
    tabColumns: {
      estimations: EstimationTableColumns,
      folders: FolderTableColumns,
      financingPlans: FinancingPlanTableColumns,
      creditSimulations: CreditSimulationTableColumns,
      visitingCards: VisitingCardTableColumns,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      folders: getFolderColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
      visitingCards: getVisitingCardColumnContent,
    },
    filter: {
      estimations: filterEstimations,
      financingPlans: filterFinancingPlans,
      folders: filterFolders,
      creditSimulations: filterCreditSimulations,
      visitingCards: filterVisitingCards,
    },
    dropdownMenu: {
      estimations: EstimationsDropdownMenu,
      financingPlans: FinancingPlanDropdownMenu,
      creditSimulations: CreditSimulationDropdownMenu,
      folders: folderDropdownMenu,
      visitingCards: VisitingCardDropdownMenu,
    },
  };

  const {
    showDuplicateResourceModal,
    archiveResource,
    downloadResource,
    showDeleteVisitingCardModal,
    setDefaultVisitingCard,
    formatTableColumns,
  } = useResources();
  const { setModalVisible, setModalContent } = useContext(AppContext);
  const { width } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();

  const typesTable = [
    {
      description: "folders",
      index: 3,
    },
    {
      description: "estimations",
      index: 4,
    },
    {
      description: "financingPlans",
      index: 5,
    },
    {
      description: "creditSimulations",
      index: 6,
    },
  ];

  useEffect(() => {
    if (!searchParams.get("visiting_card_id")) return;
    showVisitingCardModal(searchParams.get("visiting_card_id"));

    searchParams.delete("visiting_card_id");
    setSearchParams(searchParams);
  }, [searchParams]);

  useEffect(() => {
    switch (type) {
      case "estimations":
        setTypeInfos({
          pageTitle: "Estimations",
          searchPlaceholder: "Enseigne, UUID, type, adresse...",
          createButtonLabel: "Créer une estimation",
        });
        break;
      case "folders":
        setTypeInfos({
          pageTitle: "Dossiers",
          searchPlaceholder: "Nom, UUID...",
          createButtonLabel: "Créer un dossier",
        });
        break;
      case "financingPlans":
        setTypeInfos({
          pageTitle: "Plans de financement",
          searchPlaceholder: "Nom, UUID, type, estimation liée...",
          createButtonLabel: "Créer un plan de financement",
        });
        break;
      case "creditSimulations":
        setTypeInfos({
          pageTitle: "Simulations de prêt",
          searchPlaceholder: "Nom, UUID...",
          createButtonLabel: "Créer une simulation de prêt",
        });
        break;
      case "visitingCards":
        setTypeInfos({
          pageTitle: "Cartes de visite",
          searchPlaceholder: "Intitulé, UUID...",
          createButtonLabel: "Créer une carte de visite",
        });
        break;
    }

    setResetSerchValueTrigger(!resetSerchValueTrigger);
    setResetOrderByTrigger(!resetOrderByTrigger);
  }, [type]);

  useEffect(() => {
    setTabColumns(formatTableColumns(tabComponents.tabColumns[type], width));
  }, [type, width]);

  //Quick actions

  function downloadItemPdf(item) {
    downloadResource(item, type);
  }

  function showDuplicateItemModal(item) {
    const resourceTypeId = typesTable.find(i => i.description === type).index;

    showDuplicateResourceModal(item, type, () => updateClientItemsTrigger(resourceTypeId));
  }

  function archiveItem(item, shouldArchive) {
    const resourceTypeId = typesTable.find(i => i.description === type).index;

    archiveResource(item, shouldArchive, type, () => updateClientItemsTrigger(resourceTypeId));
  }

  function deleteItem(item) {
    showDeleteVisitingCardModal(item.uuid, () => updateClientItemsTrigger(7));
  }

  // General

  async function toggleVisitingCardDefault(e, visitingCard) {
    e.preventDefault();

    setDefaultVisitingCard(visitingCard.uuid, !visitingCard.is_default, items, () => updateClientItemsTrigger(7));
  }

  function setActiveItem(e, item, index, itemsContainerRef) {
    if (!e) return setDropdownItem(null);

    e.stopPropagation();
    e.preventDefault();

    if (item === dropdownItem?.item) return setDropdownItem(false);

    setDropdownItem({
      item: item,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 80,
    });
  }

  function getOrderedResources(resources, orderBy) {
    let result = tabComponents.filter[type](resources, searchValue);

    if (!orderBy.key) return result;

    result.sort((a, b) => {
      let valA = tabComponents.getColumnContent[type](orderBy.key, a);
      let valB = tabComponents.getColumnContent[type](orderBy.key, b);

      if (orderBy.key.includes("date_")) {
        valA = valA === "-" ? new Date() : stringToDate(valA + ":00");
        valB = valB === "-" ? new Date() : stringToDate(valB + ":00");
        return valA - valB;
      }

      if (typeof valA === "number" && typeof valB === "number") {
        return valA - valB;
      }

      return String(valA).localeCompare(String(valB), "fr", { numeric: true });
    });

    if (orderBy.order === "desc") result.reverse();

    return result;
  }

  function showVisitingCardModal(visitingCardUuid) {
    const visitingCard = items.find(item => item.uuid == visitingCardUuid);

    if (!visitingCard) return;

    setModalContent({
      title: "Carte de visite",
      content: <UpdateVisitingCardModal visitingCard={visitingCard} onSuccess={() => updateClientItemsTrigger(7)} />,
      className: visitingCard.card_type === "created_card" ? "profile-modal" : "medium-modal",
    });

    setModalVisible(true);
  }

  return (
    <>
      <h2 className='crm-h2'>{typeInfos.title}</h2>
      <div className='crm-action-row'>
        <Searchbar
          className='crm-searchbar'
          value
          placeholder={typeInfos.searchPlaceholder}
          onChange={value => setSearchValue(value)}
          resetValueTrigger={resetSerchValueTrigger}
        />
        <FilledButton disabled>
          <Download width='20px' />
          Exporter la sélection
        </FilledButton>
        <FilledButton disabled>
          <Add width='20px' /> {typeInfos.createButtonLabel}
        </FilledButton>
      </div>
      <ResourceTable
        dropdownMenu={tabComponents.dropdownMenu[type]({
          openInNewTab: true,
          activeItem: dropdownItem?.item,
          downloadItemPdf,
          showDuplicateItemModal,
          archiveItem,
          deleteItem,
        })}
        openResourceInNewTab
        resetOrderByTrigger={resetOrderByTrigger}
        resources={items}
        resourceTypeDescription={type}
        selectedResource={dropdownItem}
        setSelectedResourceFunction={setActiveItem}
        tableColumns={tabColumns}
        useCheckbox={true}
        getOrderedResourcesFunction={getOrderedResources}
        getColumnContentFunction={tabComponents.getColumnContent[type]}
        toggleVisitingCardDefaultFunction={toggleVisitingCardDefault}
      />
    </>
  );
}

export default CrmClientItems;
