import { useParams } from "react-router-dom";
import "./CrmDetail.css";
import React, { useEffect, useState } from "react";
import CrmClientView from "../crmClientView/CrmClientView";

function CrmDetail() {
  const [pageTitle, setPageTitle] = useState("");

  const { tabParam, uuid } = useParams();

  useEffect(() => {
    switch (tabParam) {
      case "clients":
        setPageTitle("Détail client");
        break;
    }
  }, [tabParam]);
  return (
    <>
      <section className='page-container container'>{tabParam === "clients" && <CrmClientView />}</section>
    </>
  );
}

export default CrmDetail;
