import { Link } from "react-router-dom";
import "./CrmMenu.css";
import React, { useContext, useEffect } from "react";
import AppContext from "../../../../context/AppContext";

function CrmMenu() {
  const { setBannerConfig } = useContext(AppContext);
  const menuItems = [
    {
      id: 1,
      label: "Clients",
      to: "/admin/crm/clients",
    },
    {
      id: 2,
      label: "Estimations",
      to: "/admin/crm/estimations",
    },
    {
      id: 3,
      label: "Statistiques",
      to: "/admin/crm/statistiques",
    },
    {
      id: 4,
      label: "Algorithme",
      to: "/admin/crm/algorithme",
    },
    {
      id: 5,
      label: "Conseils de pros",
      to: "/admin/crm/editeur-articles",
    },
    {
      id: 6,
      label: "Trucs & astuces",
      to: "/admin/crm/editeur-trucs-astuces",
    },
    {
      id: 7,
      label: "Lexique",
      to: "/admin/crm/lexique",
    },
    {
      id: 8,
      label: "F.A.Q",
      to: "/admin/crm/faq",
    },
    {
      id: 9,
      label: "Outils pratiques",
      to: "/admin/crm/outils-pratiques",
    },
  ];

  useEffect(() => {
    setBannerConfig({
      title: <>CRM - Menu</>,
      subtitle: <></>,
    });
  }, []);

  return (
    <div className='page-container container'>
      <div className='crm-menu-container'>
        {menuItems.map(item => (
          <Link to={item.to} className='crm-menu-item' key={item.id}>
            {item.label}
          </Link>
        ))}
      </div>
    </div>
  );
}

export default CrmMenu;
