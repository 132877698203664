import React, { useState, useContext, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import "./CreditSimulatorForm.css";
import NumberInput from "../../molecules/formComponents/numberInput/NumberInput";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import Input from "../../molecules/formComponents/input/Input";
import UnfilledButton from "../../molecules/buttons/unfilledButton/UnfilledButton";
import {
  createCreditSimulationApi,
  fetchCreditSimulationByIdApi,
  updateCreditSimulationApi,
} from "../../../api/CreditSimulatorApi";
import AppContext from "../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { CreditSimulatorContext } from "./creditSimulatorContext/CreditSimulatorContext";
import { removeSpaces, formatObjectForPosting } from "../../../utils/Utils";
import { decimalValidation, max100Validation } from "../../../utils/formValidation/FormValidation";
import { useModals } from "../../../context/ModalsContext";
import { useResources } from "../../../context/ResourcesContext";

function CreditSimulatorForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    unregister,
    watch,
    getValues,
  } = useForm();
  const inputForm = { register, setValue, unregister, watch };
  const {
    createNotification,
    setAppLoaderVisible,
    isDownloadNotificationLoading,
    setIsDownloadNotificationLoading,
    setModalVisible,
    setModalContent,
  } = useContext(AppContext);

  const { downloadResource, fetchUserSingleResource } = useResources();
  const { creditSimulationIdParam } = useParams();
  const { showRegisterModal, showTarifsModal } = useModals();
  const { getUuid, hasResourcesAccess } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (creditSimulationIdParam) {
      fetchCreditSimulator();
    }
  }, [creditSimulationIdParam]);

  async function fetchCreditSimulator() {
    try {
      const creditSimulation = await fetchUserSingleResource(creditSimulationIdParam, "creditSimulations");

      if (creditSimulation) {
        const formattedDate = formatDate(creditSimulation.date_depart);

        setValue("credit_simulator_name", creditSimulation.name);
        setValue("montant_pret", creditSimulation.montant_pret);
        setValue("taux_hors_assurance", creditSimulation.taux_hors_assurance);
        setValue("taux_assurance", creditSimulation.taux_assurance);
        setValue("duree", creditSimulation.duree);
        setValue("date_depart", formattedDate);
      }
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors du chargement de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setAppLoaderVisible(false);
    }
  }

  async function handleSaveCreditSimulator(download) {
    handleSubmit(async data => {
      await onSubmit(data, download);
    })();
  }

  async function onSubmit(data, download) {
    download = download === true;

    const formattedData = {
      credit_name: data.credit_simulator_name,
      montant_pret: parseInt(removeSpaces(data.montant_pret), 10),
      taux_hors_assurance: parseFloat(data.taux_hors_assurance.replace(",", ".")),
      taux_assurance: parseFloat(data.taux_assurance.replace(",", ".")),
      duree: parseInt(data.duree, 10),
      date_depart: data.date_depart,
    };

    if (!getUuid()) {
      localStorage.setItem("awaiting_credit_simulation", JSON.stringify(formattedData));

      return showRegisterModal(" et accéder à ma simulation de crédit");
    }

    const simulatorId = await saveCreditSimulator(formattedData, download);

    setTimeout(() => {
      if (!hasResourcesAccess.tools)
        return showTarifsModal(
          "Simulateur de crédit",
          <p>Vous devez être abonné pour télécharger votre simulation de prêt.</p>,
          `/simulateur-credit/${simulatorId}`,
        );

      if (download) downloadCreditSimulation(simulatorId);
    }, 200);
  }

  async function saveCreditSimulator(formattedData, hideNotification) {
    let creditSimulatorId = creditSimulationIdParam;

    try {
      if (creditSimulationIdParam) {
        await updateCreditSimulationApi(creditSimulationIdParam, formattedData);
      } else {
        const res = await createCreditSimulationApi(formattedData);
        const response = res.data;

        if (response.credit_simulator.credit_id) {
          creditSimulatorId = response.credit_simulator.credit_id;

          navigate(`/simulateur-credit/${creditSimulatorId}`, { replace: true });
        } else throw new Error();
      }

      if (!hideNotification) createNotification(<>La simulation de prêt a été sauvegardée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de la simulation de prêt. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      return creditSimulatorId;
    }
  }

  async function downloadCreditSimulation(simulatorId) {
    downloadResource({ uuid: simulatorId }, "creditSimulations");
  }

  function formatDate(dateString) {
    const [day, month, year] = dateString.split(" ")[0].split("/");
    return `${year}-${month}-${day}`;
  }

  function resetCreditSimulatorForm() {
    reset({
      credit_simulator_name: "",
      montant_pret: "",
      taux_hors_assurance: "",
      taux_assurance: "",
      duree: "",
      date_depart: "",
    });
  }

  return (
    <CreditSimulatorContext.Provider value={{ creditSimulationIdParam, fetchCreditSimulator }}>
      <form onSubmit={handleSubmit(onSubmit)} className='credit-simulator-form'>
        <fieldset className='borderless-fieldset full-page-form'>
          <Input
            useForm={inputForm}
            label='Intitulé de la simulation de prêt'
            bgColor='var(--pale-blue)'
            name='credit_simulator_name'
            error={errors.credit_simulator_name?.message}
          />
          <NumberInput
            useForm={inputForm}
            icon='euro'
            name='montant_pret'
            label='Montant du prêt *'
            error={errors.montant_pret?.message}
            validation={decimalValidation}
          />
          <div className='row-1000'>
            <NumberInput
              useForm={inputForm}
              icon='percent'
              name='taux_hors_assurance'
              label='Taux hors assurance *'
              error={errors.taux_hors_assurance?.message}
              validation={decimalValidation}
            />
            <NumberInput
              useForm={inputForm}
              icon='percent'
              name='taux_assurance'
              label='Taux assurance *'
              error={errors.taux_assurance?.message}
              validation={decimalValidation}
            />
          </div>
          <div className='row-1000'>
            <NumberInput
              useForm={inputForm}
              validation={max100Validation}
              name='duree'
              label='Durée (années) *'
              error={errors.duree?.message}
            />
            <Input
              type='date'
              useForm={inputForm}
              name='date_depart'
              label='Date de départ du prêt *'
              error={errors.date_depart?.message}
            />
          </div>
          <div className='form-legend'>* Obligatoire</div>
          <div className='credit-simulator-form-buttons'>
            <UnfilledButton type='submit' padding='10px 25px'>
              Enregistrer
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => handleSaveCreditSimulator(true)}
              isLoading={isDownloadNotificationLoading}>
              Télécharger
            </FilledButton>
          </div>
          <div className='reset-credit-simulator-form' onClick={resetCreditSimulatorForm}>
            <p>Réinitialiser le formulaire</p>
          </div>
        </fieldset>
      </form>
    </CreditSimulatorContext.Provider>
  );
}

export default CreditSimulatorForm;
