import axios from "axios";
import { logoutUserApi } from "./AccountApi";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

// Add Bearer token to all requests
axiosInstance.interceptors.request.use(
  config => {
    const uuid = localStorage.getItem("uuid");
    if (uuid) {
      config.headers["Authorization"] = "Bearer " + uuid;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    // no servor response
    if (!error?.response?.status) return window.location.replace("/503");
    if (error.response.status === 403) {
      switch (error.response.data.infos?.error_code) {
        //bad credentials
        case "05":
          await logoutUserApi();
          localStorage.removeItem("uuid");
          window.location.replace("/connexion?state=bad_credentials");
          break;
        // user don't have access
        case "06":
        case "07":
          window.location.replace("/403");
          break;
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
