import React, { useContext, useEffect, useState } from "react";
import "./FolderInfo.css";
import { useForm } from "react-hook-form";
import { defaultValidation, max2250Validation } from "../../../../../../../utils/formValidation/FormValidation";
import { updateFolderApi } from "../../../../../../../api/FolderApi";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Input from "../../../../../../../components/molecules/formComponents/input/Input";
import Textarea from "../../../../../../../components/molecules/formComponents/textarea/Textarea";
import AppContext from "../../../../../../../context/AppContext";
import Tuto from "../../../../../../../components/atoms/icons/general/tuto/Tuto";
import Dashboard from "../../../../../../../components/atoms/icons/folder/dashboard/Dashboard";

function FolderInfo({ folder, triggerFolderUpdate, selectTab }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { createNotification, showTooltip } = useContext(AppContext);
  const [folderContentTotals, setFolderContentTotals] = useState({
    estimations: 0,
    financingPlans: 0,
    creditSimulators: 0,
    delivrables: 0,
  });

  useEffect(() => {
    if (folder) {
      setValue("folder_name", folder.name);
      setValue("description", folder.description);

      setFolderContentTotals({
        estimations: calculateTotalEstimations(folder.estimations),
        financingPlans: calculateTotalFinancingPlans(folder.financing_plans),
        creditSimulators: calculateTotalCreditSimulators(folder.credit_simulators),
        delivrables: folder.deliverablesCount,
      });
    }
  }, [folder, setValue]);

  async function updateFolderInfo(data) {
    try {
      await updateFolderApi(folder.data.uuid, 1, data.folder_name, data.description);

      triggerFolderUpdate();

      createNotification(<>Le dossier a été mis à jour avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la mise à jour du dossier</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function calculateTotalEstimations(estimations) {
    if (!estimations) return 0;

    if (Array.isArray(estimations)) {
      return estimations.length;
    } else if (typeof estimations === "object") {
      return Object.values(estimations).reduce((acc, category) => {
        if (Array.isArray(category)) {
          return acc + category.length;
        }
        return acc;
      }, 0);
    }

    return 0;
  }

  function calculateTotalFinancingPlans(financingPlans) {
    if (!financingPlans) return 0;

    if (Array.isArray(financingPlans)) {
      return financingPlans.length;
    } else if (typeof financingPlans === "object") {
      return Object.values(financingPlans).reduce((acc, category) => {
        if (Array.isArray(category)) {
          return acc + category.length;
        }
        return acc;
      }, 0);
    }

    return 0;
  }

  function calculateTotalCreditSimulators(creditSimulators) {
    if (!creditSimulators) return 0;

    return creditSimulators.length;
  }

  return (
    <>
      <div className='tab-content-header'>
        <div className='title-ref-tab-header'>
          <h2>
            <Dashboard width='20px' />
            Contenu du dossier
            <span className='tuto-icon'>
              <Tuto onClick={() => showTooltip("infos_tab.webp")} />
            </span>
          </h2>
        </div>
        <p>Retrouvez ici toutes les informations relatives à votre dossier.</p>
      </div>
      <div className='crm-dashboard'>
        <form onSubmit={handleSubmit(updateFolderInfo)} className='crm-card form-card'>
          <div>
            <h4>
              Informations
              <span className='tooltip' data-tooltip='Informations générales sur votre dossier'>
                ?
              </span>
            </h4>
            <Input
              label='Nom du dossier'
              validation={defaultValidation}
              useForm={{ register, setValue }}
              error={errors.folder_name?.message}
              name='folder_name'
            />
            <Textarea
              label='Description du dossier'
              validation={max2250Validation}
              useForm={{ register, setValue }}
              error={errors.description?.message}
              name='description'
              resizable={false}
            />
            <p>Dossier créé le : {folder?.date_creation.split(" ")[0]}</p>
          </div>
          <div className='folder-info-buttons'>
            <FilledButton type='submit'>Sauvegarder</FilledButton>
          </div>
        </form>
        <div className='cards-stack'>
          <div className='crm-card cursor-pointer' onClick={() => selectTab("estimations")}>
            <h4>
              Estimation{folderContentTotals.estimations > 1 ? "s" : ""}
              <br />
              dans ce dossier
              <span className='tooltip' data-tooltip='Toutes les estimations liées à votre dossier'>
                ?
              </span>
            </h4>
            <div className='number-count'>{folderContentTotals.estimations}</div>
          </div>
          <div className='crm-card cursor-pointer' onClick={() => selectTab("financingPlans")}>
            <h4>
              Plan{folderContentTotals.financingPlans > 1 ? "s" : ""} de financement
              <br />
              dans ce dossier
              <span className='tooltip' data-tooltip='Tous les plans de financement liés à votre dossier'>
                ?
              </span>
            </h4>
            <div className='number-count'>{folderContentTotals.financingPlans}</div>
          </div>
          <div className='crm-card cursor-pointer' onClick={() => selectTab("creditSimulations")}>
            <h4>
              Simulation{folderContentTotals.creditSimulators > 1 ? "s" : ""} de prêt
              <br />
              dans ce dossier
              <span className='tooltip' data-tooltip='Toutes les simulations de prêt liées à votre dossier'>
                ?
              </span>
            </h4>
            <div className='number-count'>{folderContentTotals.creditSimulators}</div>
          </div>
          <div className='crm-card cursor-pointer' onClick={() => selectTab("deliverables")}>
            <h4>
              Livrable{folderContentTotals.delivrables > 1 ? "s" : ""}
              <br />
              dans ce dossier
              <span className='tooltip' data-tooltip='Tous les livrables créés à partir de ce dossier'>
                ?
              </span>
            </h4>
            <div className='number-count'>{folderContentTotals.delivrables}</div>
          </div>

          {/*
            TODO : ajouter la page d'aide pour les dossiers
            
            <div className='crm-card'>
              <h4>
                Aide
                <span className='tooltip' data-tooltip='Ressources pour vous aider à constituer votre dossier'>
                  ?
                </span>
              </h4>
              <p>Retrouvez nos aides pour constituer un dossier de qualité et augmenter vos chances de financement.</p>
            </div>
          */}
        </div>
      </div>
    </>
  );
}

export default FolderInfo;
