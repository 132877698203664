import "./UpdateVisitingCardModal.css";
import React, { useContext, useEffect, useState } from "react";
import VisitingCard from "../../../../../organisms/visitingCard/VisitingCard";
import { useForm } from "react-hook-form";
import Input from "../../../../../molecules/formComponents/input/Input";
import { updateVisitingCardApi } from "../../../../../../api/VisitingCardApi";
import AppContext from "../../../../../../context/AppContext";
import Textarea from "../../../../../molecules/formComponents/textarea/Textarea";
import FileInput from "../../../../../molecules/formComponents/fileInput/FileInput";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";
import Trash from "../../../../../atoms/icons/general/trash/Trash";

function UpdateVisitingCardModal({ onSuccess = () => {}, visitingCard }) {
  const { setModalVisible, createNotification } = useContext(AppContext);
  const { setValue, register, watch, handleSubmit } = useForm();
  const inputForm = { register, watch, setValue };

  const [images, setImages] = useState({
    avatar: null,
    logo: null,
  });

  useEffect(() => {
    fillVisitingCardValues(visitingCard);
  }, [visitingCard]);

  function fillVisitingCardValues(visitingCard) {
    Object.keys(visitingCard).forEach(key => setValue(key, visitingCard[key]));
  }

  async function formSubmit(values) {
    try {
      Object.keys(images).forEach(key => {
        if (images[key] && images[key] !== "none") {
          delete values[key + "_path"];
        } else if (images[key] === "none") {
          delete images[key];
          values[key + "_path"] = "none";
        } else {
          delete images[key];
        }
      });

      await updateVisitingCardApi(visitingCard.uuid, values);
      if (Object.keys(images).length) await updateVisitingCardApi(visitingCard.uuid, { images: images });
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la sauvegarde de la carte de visite. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      onSuccess();
      setModalVisible(false);
    }
  }

  async function updateVisitingCardImage(compressedFile, name) {
    if (compressedFile) setImages(images => ({ ...images, [name]: compressedFile }));
  }

  async function removeVisitingCardImage(name) {
    setImages(images => ({ ...images, [name]: "none" }));
  }

  async function closeModal() {
    setModalVisible(false);
  }

  function getImagesPath(visitingCard, images) {
    const result = {
      avatar_path: images.avatar ? images.avatar?.data : visitingCard.avatar_path,
      logo_path: images.logo ? images.logo?.data : visitingCard.logo_path,
    };

    return result;
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className='row-1000 update-visiting-card-container'>
        <div className='w-100'>
          <VisitingCard {...visitingCard} {...watch()} {...getImagesPath(visitingCard, images)} />
          <Input name='card_name' label='Nom de la carte de visite' useForm={inputForm} />
          {visitingCard.card_type === "created_card" && (
            <div className='customisation-pdf-buttons'>
              <FileInput
                name='avatar'
                buttonText='Modifier ma photo'
                onChange={e => updateVisitingCardImage(e, "avatar")}
                accept='image/*'
                removeButton={false}
                padding='10px 20px'
              />
              <div className='reset-customisation-button' onClick={() => removeVisitingCardImage("avatar")}>
                <Trash color='white' />
              </div>
              <FileInput
                name='logo'
                buttonText='Modifier mon logo'
                onChange={e => updateVisitingCardImage(e, "logo")}
                accept='image/*'
                removeButton={false}
                padding='10px 20px'
              />
              <div className='reset-customisation-button' onClick={() => removeVisitingCardImage("logo")}>
                <Trash color='white' />
              </div>
            </div>
          )}
          <Textarea name='mentions' label='Mentions légales' useForm={inputForm} />
        </div>
        {visitingCard.card_type === "created_card" && (
          <div className='w-100'>
            <div className='row-1000'>
              <Input validation={{}} useForm={inputForm} name='first_name' label='Prénom' />
              <Input validation={{}} name='last_name' label='Nom' useForm={inputForm} />
            </div>
            <div className='row-1000'>
              <Input validation={{}} name='position' useForm={inputForm} label='Poste' />
              <Input validation={{}} name='company' useForm={inputForm} label='Société' />
            </div>
            <Input validation={{}} name='slogan' useForm={inputForm} label='Slogan' />
            <Input validation={{}} name='primary_address' useForm={inputForm} label="Ligne d'adresse 1" />
            <Input validation={{}} name='secondary_address' useForm={inputForm} label="Ligne d'adresse 2" />
            <div className='row-1000'>
              <Input validation={{}} name='zip_code' useForm={inputForm} label='Code postal' />
              <Input validation={{}} name='city' label='Ville' useForm={inputForm} />
            </div>
            <div className='row-1000'>
              <Input validation={{}} name='email' useForm={inputForm} label='E-mail' />
              <Input validation={{}} name='website' label='Site web' useForm={inputForm} />
            </div>
            <div className='row-1000'>
              <Input validation={{}} name='mobile_phone' useForm={inputForm} label='Téléphone portable' />
              <Input validation={{}} name='fixed_phone' useForm={inputForm} label='Téléphone fixe' />
            </div>
            <div className='row-1000'>
              <Input validation={{}} name='rsac' label='RSAC' useForm={inputForm} />
              <Input validation={{}} name='siret' label='Siret' useForm={inputForm} />
            </div>
            <p className='visiting-card-error'>
              {watch("siret") && watch("rsac") ? "Vous ne pouvez renseigner qu'un seul Siret ou RSAC" : ""}
            </p>
          </div>
        )}
      </div>
      <div className='modal-buttons-row update-visiting-card-buttons'>
        <UnfilledButton onClick={closeModal} padding='10px 25px'>
          Annuler
        </UnfilledButton>
        <FilledButton type='submit' padding='10px 25px'>
          Enregistrer
        </FilledButton>
      </div>
    </form>
  );
}

export default UpdateVisitingCardModal;
