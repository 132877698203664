import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import "./CrmClientInfos.css";
import React, { useContext } from "react";
import Trash from "../../../atoms/icons/general/trash/Trash";
import Add from "../../../atoms/icons/general/add/Add";
import CrmNotesContainer from "../crmNotesContainer/CrmNotesContainer";
import Copy from "../../../atoms/icons/account/copy/Copy";
import { useCrm } from "../../../../context/CrmContext";
import CreateUserModal from "../../../forms/modals/crm/createUserModal/CreateUserModal";
import AppContext from "../../../../context/AppContext";
import { useParams } from "react-router-dom";
import { formatNumberWithSpaces } from "../../../../utils/Utils";
import { useResources } from "../../../../context/ResourcesContext";

function CrmClientInfos({ clientInfos, updateActiveClientTrigger, clientTarifs }) {
  const { rolesList } = useCrm();
  const { copyUuidToClipboard } = useResources();
  const { setModalContent, setModalVisible } = useContext(AppContext);

  const { uuid } = useParams();

  const infosArray = [
    {
      id: 1,
      label: "Adresse email",
      key: "email",
      copy: true,
      bold: true,
    },
    {
      id: 9,
      label: "Téléphone",
      key: "phone",
      bold: true,
    },
    {
      id: 2,
      label: "Nom",
      key: "last_name",
    },
    {
      id: 3,
      label: "Prénom",
      key: "first_name",
    },
    {
      id: 5,
      label: "Profession",
      key: "type",
    },
    {
      id: 4,
      label: "Raison sociale",
      key: "company_name",
    },
    {
      id: 6,
      label: "Adresse",
      key: "street",
    },
    {
      id: 7,
      label: "Code postal",
      key: "zip_code",
    },
    {
      id: 8,
      label: "Ville",
      key: "city",
    },
    {
      id: 10,
      label: "UUID",
      key: "uuid",
      copy: true,
    },
    {
      id: 11,
      label: "ID client Stripe",
      key: "customer_id",
      copy: true,
    },
    {
      id: 18,
      space: true,
    },
    {
      id: 12,
      label: "Réseau",
      key: "partner_name",
    },
    {
      id: 19,
      space: true,
    },
    {
      id: 13,
      label: "Statut de compte",
      key: "is_active",
    },
    {
      id: 14,
      label: "Rôles",
      key: "role",
    },
    {
      id: 15,
      label: "Date d’inscription",
      key: "date_creation",
    },
    {
      id: 16,
      label: "Denière modification",
      key: "date_last_update",
    },
    {
      id: 17,
      label: "Dernière connexion",
      key: "date_last_connexion",
    },
  ];

  function showUpdateClientModal() {
    setModalContent({
      title: "Modifier les informations client",
      content: (
        <CreateUserModal clientInfos={clientInfos} triggerUpdate={updateActiveClientTrigger} rolesList={rolesList} />
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  function getAccesses(rolesList, clientRole) {
    const role = rolesList.find(role => role.description === clientRole);

    if (!role || !role.accesses.length) return [{ id: 0, description: "Aucun accès" }];

    return role.accesses;
  }

  return (
    <>
      <h2 className='crm-h2'>Infos générales</h2>
      <div className='client-infos-block'>
        {infosArray.map(info =>
          info.space ? (
            <div className='client-infos-space' key={info.id} />
          ) : (
            <div key={info.id} className='client-infos-row'>
              <div>{info.label}&nbsp;:</div>
              <div
                className={`${info.bold ? "outfit-medium" : info.key === "role" || info.key === "partner_name" ? "crm-client-partner" : ""}`}>
                {clientInfos?.[info.key] ? (
                  <>
                    {/* Statut de compte */}
                    {info.key === "is_active"
                      ? clientInfos?.[info.key] === 1
                        ? "Actif"
                        : "Non confirmé"
                      : clientInfos?.[info.key]}
                    {/* Icone pour copier */}
                    {info.copy && (
                      <Copy
                        width='20px'
                        className='ml-xs'
                        onClick={e => copyUuidToClipboard(e, clientInfos?.[info.key])}
                      />
                    )}
                    {/* Affichage des tarifs */}
                    {info.key === "partner_name" && (
                      <div className='crm-client-partner-container'>
                        Voir les tarifs
                        <div className='crm-client-tarifs-container'>
                          Accès aux réductions&nbsp;: {clientInfos?.use_partner_discount ? "Oui" : "Non"}
                          {clientTarifs?.map(tarif => (
                            <div className='crm-tarif-row' key={tarif.price_id}>
                              {tarif.price_name}&nbsp;:
                              {tarif?.discount ? (
                                <div className='crm-client-discounted-tarif'>
                                  <div className='crm-base-tarif'>
                                    {formatNumberWithSpaces(tarif.base_price_by_month, 1, "€")}
                                  </div>
                                  <div className='crm-discount'>
                                    -{formatNumberWithSpaces(tarif.discount.discount_value, 1)}%
                                  </div>
                                  <div className='crm-final'>
                                    {formatNumberWithSpaces(tarif.discount.discounted_price_by_month, 1, "€")}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div className='crm-base-tarif'>
                                    {formatNumberWithSpaces(tarif.base_price_by_month, 1, "€")}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {/* Affichage des accès */}
                    {info.key === "role" && (
                      <div className='crm-client-partner-container'>
                        Voir les accès
                        <div className='crm-client-tarifs-container'>
                          {getAccesses(rolesList, clientInfos?.role)?.map(access => (
                            <div className='crm-tarif-row' key={access.id}>
                              {access.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          ),
        )}
        <FilledButton className='crm-update-client-button crm-button' onClick={showUpdateClientModal}>
          Modifier
        </FilledButton>
      </div>
      <h2 className='crm-h2'>Notes</h2>
      <CrmNotesContainer
        notes={clientInfos?.notes}
        clientUuid={clientInfos?.uuid}
        updateNotesTrigger={updateActiveClientTrigger}
      />
    </>
  );
}

export default CrmClientInfos;
