import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUserDataApi } from "../api/AccountApi";
import AppContext from "./AppContext";
import { createFinancingPlanApi } from "../api/FinancingPlanApi";
import { createCreditSimulationApi } from "../api/CreditSimulatorApi";
import { updateEstimationAPI, validateEstimationApi } from "../api/EstimationApi";
import { isOnErrorPage } from "../utils/Utils";
import axiosInstance from "../api/AxiosConfig";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [userData, setUserData] = useState(null);
  const [accesses, setAccesses] = useState("not fetched");
  const [subscription, setSubscription] = useState(null);
  const [hasResourcesAccess, setHasResourcesAccess] = useState(false);

  const { createNotification } = useContext(AppContext);

  useEffect(() => {
    console.log("estimation", hasResourcesAccess.estimations, "tools", hasResourcesAccess.tools);
  }, [hasResourcesAccess]);

  useEffect(() => {
    fetchUserData();
  }, []);

  async function fetchUserData() {
    // console.log((await axiosInstance.get("/test/payload")).data)
    try {
      if (isOnErrorPage()) return;

      const uuid = getUuid();

      if (!uuid) {
        setUserData(null);
        setAccesses([]);
        return;
      }

      makeAwaitingQueries();

      const res = (await fetchUserDataApi(uuid)).data;

      if (typeof res?.role_accesses?.length) setAccesses(res.role_accesses);

      setSubscription(res.estimation_subscription);

      setSubscriptionData(res.estimation_subscription);

      delete res.uuid;
      delete res.accesses;

      setUserData(res);
      return res;
    } catch (error) {
      createNotification(
        <>Erreur lors de la récupération des informations utilisateur</>,
        "var(--red)",
        "var(--dark-blue)",
      );
      setUserData(null);
    }
  }

  function makeAwaitingQueries() {
    makeFinancingPlanAwaitingQuery();
    makeCreditSimulationAwaitingQuery();
    makeEstimationAwaitingQuery();
  }

  async function makeFinancingPlanAwaitingQuery() {
    let financingPlan = localStorage.getItem("awaiting_financing_plan");
    if (!financingPlan) return;

    const financingPlanType = JSON.parse(localStorage.getItem("awaiting_financing_plan_type"));

    financingPlan = JSON.parse(financingPlan);

    const res = (await createFinancingPlanApi(financingPlan, financingPlanType)).data.financing_plan;

    window.location.pathname = `/plan-financement/${financingPlanType}/${res.financing_plan_id}`;

    localStorage.removeItem("awaiting_financing_plan");
    localStorage.removeItem("awaiting_financing_plan_type");
  }

  async function makeCreditSimulationAwaitingQuery() {
    let creditSimulation = localStorage.getItem("awaiting_credit_simulation");
    if (!creditSimulation) return;

    creditSimulation = JSON.parse(creditSimulation);

    const res = (await createCreditSimulationApi(creditSimulation)).data.credit_simulator;

    window.location.pathname = `/simulateur-credit/${res.credit_id}`;

    localStorage.removeItem("awaiting_credit_simulation");
  }

  async function makeEstimationAwaitingQuery() {
    let estimation = localStorage.getItem("awaiting_estimation");
    if (!estimation) return;

    estimation = JSON.parse(estimation);

    let res = (await updateEstimationAPI(estimation)).data;
    estimation.id = res.estimation.id;

    await updateEstimationAPI(estimation);
    await validateEstimationApi(res.estimation.id);

    window.location.pathname = `/synthese/${res.estimation.id}`;

    localStorage.removeItem("awaiting_estimation");
  }

  function canAccess(access, accesses) {
    if (accesses === "not fetched") return -1;
    return accesses.includes(access) || accesses.includes("unrestricted");
  }

  function refreshUserData() {
    fetchUserData();
  }

  function setSubscriptionData(subscription) {
    if (!subscription) {
      setHasResourcesAccess({
        estimations: false,
        tools: false,
      });

      return;
    }

    if (subscription.free_trial) {
      setHasResourcesAccess({ tools: subscription.has_tools_access, estimations: subscription.nb_estimation !== 0 });
    } else {
      setHasResourcesAccess({ tools: true, estimations: true });
    }
  }

  function setUuid(value) {
    if (value) {
      localStorage.setItem("uuid", value);
    } else localStorage.removeItem("uuid");
    fetchUserData();
  }

  function getUuid() {
    return localStorage.getItem("uuid");
  }

  return (
    <AuthContext.Provider
      value={{
        setUuid,
        getUuid,
        userData,
        canAccess,
        accesses,
        refreshUserData,
        subscription,
        hasResourcesAccess,
      }}>
      {children}
    </AuthContext.Provider>
  );
}
