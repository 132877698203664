import "./CreditSimulator.css";
import React, { useContext, useEffect, useMemo } from "react";
import CreditSimulatorForm from "../../../components/forms/creditSimulatorForm/CreditSimulatorForm";
import { useAuth } from "../../../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";
import AppContext from "../../../context/AppContext";

function CreditSimulator() {
  const { getUuid, canAccess, accesses } = useAuth();
  const { setBannerConfig, setBreadcrumbsSpecialValue } = useContext(AppContext);
  const { simulatorIdParam } = useParams();

  const uuid = useMemo(() => getUuid(), [getUuid]);

  useEffect(() => {
    setBannerConfig({
      title: <>Simulateur de crédit</>,
      subtitle: uuid ? (
        <Link className='link-with-arrow' to='/mon-compte/mes-outils/mes-simulations-de-pret'>
          <ArrowSm /> Accéder à mes outils (simulations de prêt)
        </Link>
      ) : (
        <>Simulation de prêt personnalisé pour votre plan de financement</>
      ),
    });
  }, [uuid]);

  useEffect(() => {
    setBreadcrumbsSpecialValue(simulatorIdParam ? { to: "/" + simulatorIdParam } : null);
  }, [simulatorIdParam]);

  return (
    <>
      <section className='container page-container'>
        <CreditSimulatorForm />
        {canAccess("credipro_form_access", accesses) && (
          <>
            <h2>Obtenir un financement professionnel</h2>
            <p>
              Vous recherchez un financement professionnel ? Notre partenaire vous permet de connaître la finançabilité
              du projet et de vous accompagner intégralement sur l'obtention du prêt professionnel.{" "}
              <Link to='/obtenir-financement' className='link'>
                Cliquez ici pour formuler votre demande
              </Link>
              .
            </p>
          </>
        )}
      </section>
    </>
  );
}

export default CreditSimulator;
