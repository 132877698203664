import { fetchAllOutilsPratiquesApi } from "../../../api/ToolsApi";
import StickySearchbar from "../../../components/molecules/stickySearchbar/StickySearchbar";
import TextHr from "../../../components/molecules/textHr/TextHr";
import ExpendableGroup from "../../../components/organisms/expendableGroup/ExpendableGroup";
import PracticalTool from "../../../components/organisms/practicalTool/PracticalTool";
import AppContext from "../../../context/AppContext";
import { useAuth } from "../../../context/AuthContext";
import { normalizeString } from "../../../utils/Utils";
import "./PracticalTools.css";
import React, { Fragment, useContext, useEffect, useState } from "react";

function PracticalTools() {
  const { createNotification, setBannerConfig } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState("");
  const [tools, setTools] = useState([]);
  const [visibleTools, setVisibleTools] = useState([]);

  useEffect(() => {
    fetchPracticalTools();
    setBannerConfig({
      title: <>Outils pratiques</>,
      subtitle: <>Téléchargez nos outils exclusifs pour vous aider à optimiser, céder ou acquérir une affaire</>,
    });
  }, []);

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue]);

  async function fetchPracticalTools() {
    try {
      const tmp = {};
      const res = (await fetchAllOutilsPratiquesApi()).data;
      const tools = [];

      res.forEach(tool => {
        if (tmp[tool.tag_id]) {
          tmp[tool.tag_id].push(tool);
        } else {
          tmp[tool.tag_id] = [tool];
        }
      });

      for (const [key, value] of Object.entries(tmp)) {
        tools.push({
          tagId: key,
          tools: value,
          tagName: value[0].tag_name,
        });
      }

      setTools(tools);
      setVisibleTools(tools);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des outils pratiques. Veuillez réessayer</>,
        "var(--red)",
        "var(--dark-blue",
      );
    } finally {
      //setAppLoaderVisible(false);
    }
  }

  function onSearch(searchValue) {
    if (!searchValue.length) return setVisibleTools(tools);

    const filteredTools = tools
      .map(category => ({
        ...category,
        tools: category.tools.filter(tool => normalizeString(tool.name).includes(normalizeString(searchValue))),
      }))
      .filter(category => category.tools.length);

    setVisibleTools(filteredTools);
  }

  return (
    <>
      <div className='page-container container'>
        <StickySearchbar onChange={setSearchValue} />
        {visibleTools.map(category => (
          <Fragment key={category.tagId}>
            <TextHr className='practical-tools-hr'>{category.tagName}</TextHr>
            <ExpendableGroup
              key={category.id}
              data={category.tools.map(tool => ({
                title: tool.name,
                content: <PracticalTool tool={tool} />,
              }))}
            />
          </Fragment>
        ))}
      </div>
    </>
  );
}

export default PracticalTools;
