import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import Checkbox from "../../../molecules/formComponents/checkbox/Checkbox";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { defaultValidation } from "../../../../utils/formValidation/FormValidation";
import FilledButton from "../../../molecules/buttons/filledButton/FilledButton";
import { createPaymentLink, createStripeCustomerApi } from "../../../../api/TarifsApi";
import { useAuth } from "../../../../context/AuthContext";
import AppContext from "../../../../context/AppContext";
import { useCart } from "../../../../context/CartContext";

function OrderForm({ offerUuid }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { cart } = useCart();
  const [searchParams] = useSearchParams();
  const { getUuid, userData } = useAuth();
  const { createNotification } = useContext(AppContext);

  async function onSubmit(hasAlreadyCreatedCustomer) {
    try {
      const fallbackUrl = searchParams.get("fallback-url");
      let redirection = fallbackUrl ?? "/mon-compte/mes-outils";

      redirection = process.env.REACT_APP_URL + redirection;
      const stripeLink = (await createPaymentLink(getUuid(), cart[0].price_id, redirection)).data;
      if (
        stripeLink ==
        {
          error: "user_already_has_subscription",
        }
      )
        throw new Error();
      window.location.href = stripeLink;
    } catch (error) {
      if (hasAlreadyCreatedCustomer !== true) await createCustomer();
      else
        createNotification(
          <>Une erreur est survenue lors de votre commande. Veuillez réessayer.</>,
          "var(--red)",
          "var(--dark-blue)",
        );
    }
  }

  async function createCustomer() {
    try {
      const res = await createStripeCustomerApi(userData.email, userData.first_name, userData.last_name);
      onSubmit(true);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de votre commande. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='commande-form'>
      <Checkbox
        label={
          <p>
            En cochant cette case, je reconnais avoir pris connaissance et accepté pleinement les{" "}
            <Link to='/cgv'>Conditions Générales de Vente</Link> de GALAXICO, les{" "}
            <Link to='/cgu'>Conditions Générales d'utilisation</Link> et la{" "}
            <Link to='/politique-confidentialite'>Politique de Confidentialité du Site</Link>.&nbsp;*
          </p>
        }
        name='conditions_generales'
        useForm={{ register }}
        validation={defaultValidation}
        error={errors?.conditions_generales?.message}
      />
      <Checkbox
        label={
          <p>
            En cochant cette case, j’accepte expressément que la fourniture du contenu numérique puisse commencer
            immédiatement après l’envoi de l’e-mail de confirmation de GALAXICO et{" "}
            <span className='text-underline'>renonce donc expressément à mon droit de rétractation.</span>&nbsp;*
          </p>
        }
        name='droit_retractation'
        useForm={{ register }}
        validation={defaultValidation}
        error={errors?.droit_retractation?.message}
      />
      <Checkbox
        label={<p>Je m'inscris à la newsletter de EstimerMonCommerce.fr</p>}
        name='inscription_newsletter'
        useForm={{ register }}
      />
      <div className='obligatoire-checkbox-form'>
        <p>* Obligatoire</p>
      </div>
      <div className='button-suivant-recap'>
        <FilledButton padding='10px 20px' fontSize='1.2rem' to='/panier'>
          Retour
        </FilledButton>
        <FilledButton padding='10px 20px' fontSize='1.2rem' type='submit'>
          Commander
        </FilledButton>
      </div>
    </form>
  );
}

export default OrderForm;
