import React, { useContext } from "react";
import "./CreateFolderModal.css";
import Input from "../../../../../molecules/formComponents/input/Input";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../../context/AppContext";
import { useForm } from "react-hook-form";
import { createFolderApi } from "../../../../../../api/FolderApi";
import { defaultValidation, max2250Validation } from "../../../../../../utils/formValidation/FormValidation";
import Textarea from "../../../../../molecules/formComponents/textarea/Textarea";
import { useNavigate } from "react-router-dom";

function CreateFolderModal({ fetchFoldersFunction = async () => {}, getUuid }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const navigate = useNavigate();

  async function createFolder(data) {
    try {
      const userId = getUuid();
      const response = await createFolderApi(data.folder_name, data.description, 1, userId);
      const newFolderId = response.data.folder.data.folder_id;

      navigate(`/mon-compte/mes-dossiers/${newFolderId}`);

      await fetchFoldersFunction();

      createNotification(<>Votre dossier a été créé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la création de votre dossier. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(createFolder)}>
      <div className='info-create-folder'>
        <p>
          Un dossier vous permet de regrouper vos documents (estimations, plans de financement...). Vous pouvez créer
          autant de dossiers que vous le souhaitez.
        </p>
      </div>
      <Input
        validation={defaultValidation}
        useForm={{ register, setValue }}
        error={errors.folder_name?.message}
        name='folder_name'
        label='Nom du dossier'
      />
      <Textarea
        validation={max2250Validation}
        useForm={{ register, setValue }}
        error={errors.description?.message}
        name='description'
        label='Description du dossier (facultatif)'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton padding='10px 25px' type='submit'>
          Créer le dossier
        </FilledButton>
      </div>
    </form>
  );
}

export default CreateFolderModal;
