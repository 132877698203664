import React, { useContext, useState } from "react";
import AppContext from "../../../../../../context/AppContext";
import { deleteVisitingCardApi } from "../../../../../../api/VisitingCardApi";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";

function DeleteVisitingCardModal({ visitingCardUuid, onSuccess = () => {} }) {
  const { setModalVisible, createNotification } = useContext(AppContext);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  async function deleteVisitingCard() {
    setIsButtonLoading(true);
    try {
      await deleteVisitingCardApi(visitingCardUuid);
      createNotification(<>La carte de visite a été supprimée avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression de la carte de visite. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
      onSuccess();
      setIsButtonLoading(false);
    }
  }

  return (
    <>
      <p>Êtes-vous sûr de vouloir supprimer cette carte de visite&nbsp;? Cette action est irréversible.</p>
      <div className='modal-buttons-row'>
        <UnfilledButton onClick={() => setModalVisible(false)} padding='10px 25px'>
          Annuler
        </UnfilledButton>
        <FilledButton isLoading={isButtonLoading} onClick={deleteVisitingCard} padding='10px 25px'>
          Supprimer la carte
        </FilledButton>
      </div>
    </>
  );
}

export default DeleteVisitingCardModal;
