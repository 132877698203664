import "./ArticlesEditor.css";
import React, { useContext, useEffect, useState } from "react";
import ArticlesEditorForm from "../../../components/forms/articlesEditorForm/ArticlesEditorForm";
import { fetchAllArticlesApi, fetchArticleByUrlApi, publishArticleApi } from "../../../api/ArticlesEditorApi";
import { normalizeString } from "../../../utils/Utils";
import FilledButton from "../../../components/molecules/buttons/filledButton/FilledButton";
import UnfilledButton from "../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import Bracket from "../../../components/atoms/icons/general/bracket/Bracket";
import Searchbar from "../../../components/molecules/searchbar/Searchbar";
import { ArticlesEditorContextProvider } from "../../../context/ArticlesEditorContext";
import AppContext from "../../../context/AppContext";
import CreateArticleModal from "../../../components/forms/modals/admin/createArticleModal/CreateArticleModal";
import { useAuth } from "../../../context/AuthContext";
import { Link } from "react-router-dom";
import ArrowSm from "../../../components/atoms/icons/general/arrowSm/ArrowSm";

function ArticleEditor() {
  const [articles, setArticles] = useState([]);
  const [currentArticle, setCurrentArticle] = useState({});
  const [visibleArticles, setVisibleArticles] = useState([]);
  const [isExpended, setIsExpended] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortingParams, setSortingParams] = useState({ type: "", order: "desc" });
  const { createNotification, setModalVisible, setModalContent, setBannerConfig } = useContext(AppContext);
  const { canAccess, accesses } = useAuth();

  useEffect(() => {
    fetchAllArticles();

    setBannerConfig({
      title: <>Editeur d'articles</>,
      subtitle: (
        <Link to='/admin/crm' className='link-with-arrow'>
          <ArrowSm />
          Retour au menu
        </Link>
      ),
    });
  }, []);

  useEffect(() => {
    setVisibleArticles(articles);
    setSearchValue("");
  }, [articles]);

  useEffect(() => {
    filterVisibleArticles(searchValue);
  }, [searchValue]);

  async function fetchAllArticles() {
    try {
      setArticles((await fetchAllArticlesApi()).data.conseils_pros);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération des articles</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  async function selectArticle(url) {
    try {
      if (url) setCurrentArticle((await fetchArticleByUrlApi(url)).data);
      else setCurrentArticle({});
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la récupération de l'article</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    }
  }

  function filterVisibleArticles(value) {
    if (!value) return setVisibleArticles(articles);
    setVisibleArticles(
      articles.filter(article => normalizeString(article.title ?? "Sans titre").includes(normalizeString(value))),
    );
  }

  function setSelectedArticle(url) {
    selectArticle(url);
    setIsExpended(false);
  }

  function createArticle(e) {
    e.preventDefault();
    setModalContent({
      title: "Créer un article",
      content: <CreateArticleModal selectArticle={selectArticle} setArticles={setArticles} />,
    });
    setModalVisible(true);
  }

  function showPublishArticleModal(article) {
    setModalContent({
      title: article.is_published ? "Dépublier l'article" : "Publier l'article",
      content: (
        <>
          <p className='mb-lg'>
            Êtes-vous sûr de vouloir {article.is_published ? "dépublier" : "publier"} l'article "{article.title}" ?
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton onClick={() => setModalVisible(false)}>Annuler</UnfilledButton>
            <FilledButton onClick={() => toggleArticlePublication(article)}>Confirmer</FilledButton>
          </div>
        </>
      ),
    });
    setModalVisible(true);
  }

  async function toggleArticlePublication(article) {
    try {
      setModalVisible(false);
      await publishArticleApi(article.id, !article.is_published);

      article.is_published = !article.is_published;

      setArticles(articles.map(row => (row.id === article.id ? article : row)));

      createNotification(<>L'article a été {article.is_published ? "publié" : "dépublié"} avec succès.</>);
    } catch (error) {
      createNotification(<>Une erreur est survenue</>, "var(--red)", "var(--dark-blue)");
    }
  }

  function sortArticles(a, b, sortingParams) {
    if (!sortingParams?.type) return 0;

    if (!a[sortingParams.type] && !b[sortingParams.type]) return 0;
    if (!a[sortingParams.type] && b[sortingParams.type]) return 1;
    if (a[sortingParams.type] && !b[sortingParams.type]) return -1;

    if (sortingParams.type === "id" || sortingParams.type === "title")
      return normalizeString(a[sortingParams.type]) > normalizeString(b[sortingParams.type]) &&
        sortingParams.order === "asc"
        ? 1
        : -1;

    if (!a[sortingParams.type].length && !b[sortingParams.type].length) return 0;
    if (!a[sortingParams.type].length && b[sortingParams.type].length) return 1;
    if (!a[sortingParams.type].length && !b[sortingParams.type].length) return -1;

    const parts = [a[sortingParams.type].split("-"), b[sortingParams.type].split("-")];
    const dates = parts.map(part => new Date(part.reverse().join("-")));

    return dates[0].getTime() > dates[1].getTime() && sortingParams.order === "asc" ? 1 : -1;
  }

  function setSortingType(type) {
    if (sortingParams.type === type) {
      setSortingParams({ ...sortingParams, order: sortingParams.order === "asc" ? "desc" : "asc" });
    } else {
      setSortingParams({ type, order: "desc" });
    }
  }

  function getBracketRotation(type, sortingParams) {
    if (type !== sortingParams.type) return "90deg";
    return sortingParams.order === "desc" ? "0deg" : "180deg";
  }

  return (
    <ArticlesEditorContextProvider>
      <div className='articles-editor-page-container'>
        <div className='articles-viewer-container-background'>
          <FilledButton
            className={`articles-viewer-toggle mobile-toggle ${isExpended ? "" : "closed"}`}
            onClick={() => setIsExpended(!isExpended)}>
            <Bracket rotation={isExpended ? "270deg" : "90deg"} color='var(--white)' />
          </FilledButton>
          <section className={`articles-viewer-container ${isExpended ? "" : "closed"}`}>
            <div className='articles-viewer-header'>
              <div className='articles-viewer-buttons'>
                {canAccess("article_create", accesses) && (
                  <FilledButton onClick={createArticle}>Ajouter un article</FilledButton>
                )}
                <UnfilledButton className='articles-viewer-toggle' onClick={() => setIsExpended(!isExpended)}>
                  <Bracket rotation={isExpended ? "270deg" : "90deg"} color='var(--blue)' />
                </UnfilledButton>
              </div>
              <Searchbar
                className='articles-editor-searchbar'
                placeholder='Rechercher un article...'
                onChange={setSearchValue}
              />
              <div className='article-row first-row'>
                <div onClick={() => setSortingType("id")}>
                  ID{" "}
                  <Bracket rotation={getBracketRotation("id", sortingParams)} color='var(--dark-blue)' width='10px' />
                </div>
                <div onClick={() => setSortingType("title")}>
                  Titre{" "}
                  <Bracket
                    rotation={getBracketRotation("title", sortingParams)}
                    color='var(--dark-blue)'
                    width='10px'
                  />
                </div>
                {isExpended && (
                  <div onClick={() => setSortingType("created_at")}>
                    Publication{" "}
                    <Bracket
                      rotation={getBracketRotation("created_at", sortingParams)}
                      color='var(--dark-blue)'
                      width='10px'
                    />
                  </div>
                )}
                {isExpended && (
                  <div onClick={() => setSortingType("updated_at")}>
                    Mis à jour{" "}
                    <Bracket
                      rotation={getBracketRotation("updated_at", sortingParams)}
                      color='var(--dark-blue)'
                      width='10px'
                    />
                  </div>
                )}
                <div />
                {isExpended && <div />}
              </div>
            </div>
            {visibleArticles
              .sort((a, b) => sortArticles(a, b, sortingParams))
              .map(article => (
                <div className='article-row' key={article.id}>
                  <div>{article.id}</div>
                  <div>{article.title ? article.title.replaceAll("&nbsp;", " ") : "Sans titre"}</div>
                  {isExpended && <div>{article.created_at}</div>}
                  {isExpended && <div>{article.updated_at}</div>}
                  {isExpended &&
                    (article.is_finished ? <div></div> : <div className='article-status-draft'>Brouillon</div>)}
                  {isExpended &&
                    (article.is_published ? (
                      canAccess("article_unpublish", accesses) ? (
                        <UnfilledButton bgColor='var(--red)' onClick={() => showPublishArticleModal(article)}>
                          Dépublier
                        </UnfilledButton>
                      ) : (
                        <div className='article-status-published'>Publié</div>
                      )
                    ) : canAccess("article_publish", accesses) ? (
                      <UnfilledButton bgColor='var(--green)' onClick={() => showPublishArticleModal(article)}>
                        Publier
                      </UnfilledButton>
                    ) : (
                      <div className='article-status-unpublished'>Non publié</div>
                    ))}
                  <FilledButton onClick={() => setSelectedArticle(article.url)}>
                    {accesses.update ? "Modifier" : "Consulter"}
                  </FilledButton>
                </div>
              ))}
          </section>
        </div>
        <ArticlesEditorForm
          createArticle={createArticle}
          fetchAllArticles={fetchAllArticles}
          currentArticle={currentArticle}
          setCurrentArticle={setCurrentArticle}
        />
      </div>
    </ArticlesEditorContextProvider>
  );
}

export default ArticleEditor;
