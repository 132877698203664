import { Link } from "react-router-dom";
import { normalizeString } from "../../../utils/Utils";
import Pen from "../../atoms/icons/account/pen/Pen";
import Download from "../../atoms/icons/general/download/Download";
import Duplicate from "../../atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../atoms/icons/account/unarchive/Unarchive";
import Archive from "../../atoms/icons/account/archive/Archive";
import Trash from "../../atoms/icons/general/trash/Trash";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import Add from "../../atoms/icons/general/add/Add";
import ReviewButton from "../../molecules/buttons/reviewButton/ReviewButton";

export const CreditSimulationTableColumns = [
  {
    id: 1,
    label: "UUID",
    key: "uuid",
    default: 0,
  },
  {
    id: 2,
    label: "Intitulé",
    key: "name",
    default: 0,
    showBracket: true,
  },
  {
    id: 4,
    label: "Création",
    key: "date_creation",
    default: 992,
    showBracket: true,
  },
  {
    id: 5,
    label: "Mise à jour",
    key: "date_last_update",
    default: 992,
    showBracket: true,
  },
  {
    id: 7,
    label: "",
    key: "archived",
    default: 576,
  },
];

export function getCreditSimulationColumnContent(key, item) {
  switch (key) {
    case "date_last_update":
    case "date_creation":
      return item[key] || "-";
    default:
      return item[key];
  }
}

export function filterCreditSimulations(items, searchValue) {
  let normalizedSearchValue = normalizeString(searchValue);

  return items?.filter(item => {
    if (item.uuid === searchValue || normalizeString(item.name).includes(normalizedSearchValue)) return true;

    return false;
  });
}

export const CreditSimulationDropdownMenu = ({
  activeItem,
  openInNewTab,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  showRemoveResourceFromFolderModal,
  showRemoveResourceFromDeliverableModal,
}) => (
  <>
    <li>
      <Link
        to={`/simulateur-credit/${activeItem?.uuid}`}
        onClick={e => {
          if (!openInNewTab) return;

          e.preventDefault();
          window.open(`/simulateur-credit/${activeItem?.uuid}`);
        }}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {downloadItemPdf && (
      <li>
        <button onClick={() => downloadItemPdf(activeItem)}>
          <Download color='var(--dark-blue)' width='18px' />
          Télécharger
        </button>
      </li>
    )}
    {showDuplicateItemModal && (
      <li>
        <button onClick={() => showDuplicateItemModal(activeItem)}>
          <Duplicate width='18px' />
          Dupliquer
        </button>
      </li>
    )}
    {showRemoveResourceFromFolderModal && (
      <li>
        <button onClick={() => showRemoveResourceFromFolderModal(activeItem)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {showRemoveResourceFromDeliverableModal && (
      <li>
        <button onClick={() => showRemoveResourceFromDeliverableModal(activeItem)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (activeItem?.archived ? (
        <li>
          <button onClick={() => archiveItem(activeItem, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(activeItem, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);

export const CreditSimulationCreateButton = () => (
  <FilledButton className='mid-page-button items-button' to='/simulateur-credit'>
    <Add width='30px' /> Créer une simulation de prêt
  </FilledButton>
);

export const CreditSimulatorReviewButton = ({ text, link }) => (
  <ReviewButton modalTitle='Votre avis sur nos simulateurs de crédit' modalText='un simulateur de crédit' link={link}>
    {text}
  </ReviewButton>
);
