import React, { useEffect, useState } from "react";
import "./CrmResourcesMenu.css";

function CrmResourcesMenu({ children, title, bonusSection = <></> }) {
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleCloseMobileMenu);

    return () => {
      document.removeEventListener("click", handleCloseMobileMenu);
    };
  }, []);

  function handleCloseMobileMenu() {
    setIsMobileMenuOpened(false);
  }

  function showMobileMenu(e) {
    e.stopPropagation();

    setIsMobileMenuOpened(value => !value);
  }
  return (
    <div className={`crm-resource-menu-container ${isMobileMenuOpened ? "active" : ""}`}>
      <div className='crm-resource-menu'>
        <div className={`crm-client-view-mobile-menu ${isMobileMenuOpened ? "active" : ""}`} onClick={showMobileMenu}>
          <div />
          <div />
          <div />
        </div>
        <div className='crm-resource-menu-header'>
          <h2 className='crm-h2'>{title}</h2>
        </div>
        {children}
      </div>
      {bonusSection}
    </div>
  );
}

export default CrmResourcesMenu;
