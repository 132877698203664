import "./ImportResourcesToFolderModal.css";
import React, { useContext, useEffect, useState } from "react";
import { useResources } from "../../../../../../context/ResourcesContext";
import ResourceTable from "../../../../../organisms/resourceTable/ResourceTable";
import {
  EstimationTableColumns,
  getEstimationColumnContent,
} from "../../../../../organisms/resourcesComponents/EstimationsComponents";
import {
  FinancingPlanTableColumns,
  getFinancingPlanColumnContent,
} from "../../../../../organisms/resourcesComponents/FinancingPlansComponents";
import {
  CreditSimulationTableColumns,
  getCreditSimulationColumnContent,
} from "../../../../../organisms/resourcesComponents/CreditSimulationsComponents";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../../context/AppContext";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";

function ImportResourcesToFolderModal({ resourceTypes = [], resources, folderUuid, onSuccess = () => {} }) {
  const { getResourceName, formatTableColumns, importResourceToFolder } = useResources();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const { width } = useWindowSize();
  const tableComponents = {
    tabColumns: {
      estimations: EstimationTableColumns,
      financingPlans: FinancingPlanTableColumns,
      creditSimulations: CreditSimulationTableColumns,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
    },
  };

  const [selectedResources, setSelectedResources] = useState({
    estimations: [],
    financingPlans: [],
    creditSimulations: [],
  });
  const [tablesColumns, setTableColumns] = useState({
    estimations: [],
    financingPlans: [],
    creditSimulations: [],
  });
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    formatMultipleTablesColumns(resourceTypes, width);
  }, [resourceTypes, width]);

  function formatMultipleTablesColumns(resourceTypes, width) {
    const result = {};

    resourceTypes.forEach(resourceType => {
      result[resourceType.name] = formatTableColumns(tableComponents.tabColumns[resourceType.name], width).filter(
        column => column.key !== "archived",
      );
    });

    setTableColumns(result);
  }

  function isImportButtonDisabled(selectedResources) {
    let result = true;
    Object.keys(selectedResources).forEach(key => {
      if (selectedResources[key].length) result = false;
    });

    return result;
  }

  async function importResources() {
    try {
      setIsButtonLoading(true);
      await Promise.all(
        Object.keys(selectedResources).flatMap(key =>
          selectedResources[key].map(resourceUuid => importResourceToFolder(resourceUuid, folderUuid, key)),
        ),
      );

      onSuccess();
      createNotification(<>Vos ressources ont été importées avec succès.</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de l'importation de vos ressources. Veuillez réessayer.</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
      setIsButtonLoading(false);
    }
  }

  function toggleResourceSelection(e, resource, resourceTypeName) {
    e.preventDefault();

    setSelectedResources(selectedResources => {
      let resourcesUuid = selectedResources[resourceTypeName];

      if (resourcesUuid.includes(resource.uuid))
        resourcesUuid = resourcesUuid.filter(resourceUuid => resourceUuid !== resource.uuid);
      else resourcesUuid.push(resource.uuid);

      return {
        ...selectedResources,
        [resourceTypeName]: resourcesUuid,
      };
    });
  }

  function getTitleContent(resourcesTypes, resources) {
    const filteredResourcesNames = getFilteredResourcesTypes(resourcesTypes, resources).map(resourceType =>
      getResourceName(resourceType.name, true),
    );
    if (!filteredResourcesNames.length) return "les ressources";

    let result = "les " + filteredResourcesNames[0];

    if (filteredResourcesNames.length === 2) result += " et " + filteredResourcesNames[1];
    else if (filteredResourcesNames.length === 3)
      result += ", " + filteredResourcesNames[1] + " et " + filteredResourcesNames[2];

    return result;
  }

  function getFilteredResourcesTypes(resourcesTypes, resources) {
    return resourcesTypes.filter(resourceType => resources[resourceType.name].length);
  }

  return (
    <div className='import-resources-container'>
      <p className='text-grey mb-md'>
        Choisissez ci-dessous {getTitleContent(resourceTypes, resources)} à importer dans votre dossier.
      </p>
      {resourceTypes.map(resourceType => (
        <div key={resourceType.name}>
          <ResourceTable
            checkboxesArray={selectedResources[resourceType.name]}
            setCheckboxesArrayFunction={(e, resource) => toggleResourceSelection(e, resource, resourceType.name)}
            resourceTypeDescription={resourceType.name}
            onResourceClick={(e, resource) => toggleResourceSelection(e, resource, resourceType.name)}
            resources={resources[[resourceType.name]]}
            noResultText='Aucun résultat'
            getColumnContentFunction={tableComponents.getColumnContent[resourceType.name]}
            tableColumns={tablesColumns[resourceType.name]}
            useCheckbox
          />
        </div>
      ))}
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton
          isLoading={isButtonLoading}
          padding='10px 25px'
          onClick={importResources}
          disabled={isImportButtonDisabled(selectedResources)}>
          Importer
        </FilledButton>
      </div>
    </div>
  );
}

export default ImportResourcesToFolderModal;
