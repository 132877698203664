import React, { useContext, useState } from "react";
import AppContext from "../../../../../../context/AppContext";
import { useResources } from "../../../../../../context/ResourcesContext";
import UnfilledButton from "../../../../../molecules/buttons/unfilledButton/UnfilledButton";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";

function RemoveResourceFromDeliverableModal({ removeFunction, resourceDescription, onSuccess = () => {} }) {
  const { setModalVisible } = useContext(AppContext);
  const { isResourceMasculine, getResourceName } = useResources();

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  async function removeResourceFromDeliverable() {
    setIsButtonLoading(true);

    await removeFunction();

    onSuccess();
    setModalVisible(false);
    setIsButtonLoading(false);
  }

  return (
    <>
      <p>
        Êtes-vous sûr de vouloir retirer ce{isResourceMasculine(resourceDescription) ? "" : "te"}{" "}
        {getResourceName(resourceDescription)} du livrable&nbsp;?
      </p>
      <div className='modal-buttons-row'>
        <UnfilledButton onClick={() => setModalVisible(false)} padding='10px 25px'>
          Annuler
        </UnfilledButton>
        <FilledButton isLoading={isButtonLoading} onClick={removeResourceFromDeliverable} padding='10px 25px'>
          Retirer du livrable
        </FilledButton>
      </div>
    </>
  );
}

export default RemoveResourceFromDeliverableModal;
