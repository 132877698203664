import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { duplicateDeliverableApi } from "../../../../../../api/FolderApi";
import AppContext from "../../../../../../context/AppContext";
import Input from "../../../../../molecules/formComponents/input/Input";
import FilledButton from "../../../../../molecules/buttons/filledButton/FilledButton";

function DuplicateDeliverableModal({ resourceUuid, defaultValue, onSuccess = () => {} }) {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const { setModalVisible, createNotification } = useContext(AppContext);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  useEffect(() => {
    setValue("deliverable_name", defaultValue);
  }, [defaultValue]);

  async function duplicateDeliverable(values) {
    setIsButtonLoading(true);
    try {
      await duplicateDeliverableApi(resourceUuid, values.deliverable_name);

      createNotification(<>Le livrable a été dupliqué avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la duplication du livrable. Veuillez réessayer plus tard</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      onSuccess();
      setModalVisible(false);
      setIsButtonLoading(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(duplicateDeliverable)}>
      <Input
        useForm={{ register, setValue }}
        error={errors?.deliverable_name?.message}
        name='deliverable_name'
        label='Nom du livrable'
      />
      <div className='modal-buttons-row'>
        <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
          Annuler
        </UnfilledButton>
        <FilledButton isLoading={isButtonLoading} padding='10px 25px' type='submit'>
          Dupliquer le livrable
        </FilledButton>
      </div>
    </form>
  );
}

export default DuplicateDeliverableModal;
