import "./FolderResources.css";
import React, { useContext, useEffect, useState } from "react";
import { useResources } from "../../../../../../../context/ResourcesContext";
import { useWindowSize } from "@uidotdev/usehooks";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import ResourceTable from "../../../../../../../components/organisms/resourceTable/ResourceTable";
import {
  EstimationsDropdownMenu,
  EstimationTableColumns,
  filterEstimations,
  getEstimationColumnContent,
} from "../../../../../../../components/organisms/resourcesComponents/EstimationsComponents";
import {
  filterFinancingPlans,
  FinancingPlanDropdownMenu,
  FinancingPlanTableColumns,
  getFinancingPlanColumnContent,
} from "../../../../../../../components/organisms/resourcesComponents/FinancingPlansComponents";
import {
  CreditSimulationDropdownMenu,
  CreditSimulationTableColumns,
  filterCreditSimulations,
  getCreditSimulationColumnContent,
} from "../../../../../../../components/organisms/resourcesComponents/CreditSimulationsComponents";
import ImportResourcesToFolderModal from "../../../../../../../components/forms/modals/account/folders/importResourcesToFolderModal/ImportResourcesToFolderModal";
import { useAuth } from "../../../../../../../context/AuthContext";
import AppContext from "../../../../../../../context/AppContext";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";

function FolderResources({ folder, triggerFolderUpdate = () => {}, activeTab, resources }) {
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const {
    getResourceName,
    isResourceMasculine,
    formatTableColumns,
    fetchUserResource,
    downloadResource,
    showRemoveResourceFromFolderModal,
    showRemoveAllResourcesFromFolderModal,
  } = useResources();
  const { getUuid } = useAuth();
  const { width } = useWindowSize();

  const tabComponents = {
    tableColumns: {
      estimations: EstimationTableColumns,
      financingPlans: FinancingPlanTableColumns,
      creditSimulations: CreditSimulationTableColumns,
    },
    getColumnContent: {
      estimations: getEstimationColumnContent,
      financingPlans: getFinancingPlanColumnContent,
      creditSimulations: getCreditSimulationColumnContent,
    },
    filter: {
      estimations: filterEstimations,
      financingPlans: filterFinancingPlans,
      creditSimulations: filterCreditSimulations,
    },
    dropdownMenu: {
      estimations: EstimationsDropdownMenu,
      financingPlans: FinancingPlanDropdownMenu,
      creditSimulations: CreditSimulationDropdownMenu,
    },
  };

  const [dropdownItem, setDropdownItem] = useState(null);
  const [notImportedResources, setNotImportedResources] = useState([]);
  const [resetOrderByTrigger, setResetOrderByTrigger] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    setResetOrderByTrigger(!resetOrderByTrigger);
  }, [activeTab]);

  useEffect(() => {
    fetchNotImportedResources(activeTab, folder);
  }, [activeTab, folder]);

  useEffect(() => {
    setTableColumns(
      formatTableColumns(tabComponents.tableColumns[activeTab.name], width).filter(column => column.key !== "archived"),
    );
  }, [activeTab, width]);

  async function fetchNotImportedResources(activeTab) {
    if (!activeTab || !folder) return;

    let res = await fetchUserResource(getUuid(), activeTab.name);

    res = res.filter(
      resource =>
        !resource.archived &&
        resource.validated !== false &&
        !folder[activeTab.name].filter(item => item.uuid === resource.uuid).length,
    );

    setNotImportedResources({ [activeTab.name]: res });
  }

  function setActiveItem(e, item, index, itemsContainerRef) {
    if (!e) return setDropdownItem(null);

    e.stopPropagation();
    e.preventDefault();

    if (item === dropdownItem?.item) return setDropdownItem(false);

    setDropdownItem({
      item: item,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 80,
    });
  }

  function showImportResourceModal(resourceType) {
    setModalContent({
      title: "Importer des " + getResourceName(resourceType.name, true),
      content: (
        <ImportResourcesToFolderModal
          folderUuid={folder.uuid}
          resources={notImportedResources}
          resourceTypes={[resourceType]}
          onSuccess={triggerFolderUpdate}
        />
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  function downloadItemPdf(resource) {
    downloadResource(resource, activeTab.name);
  }

  function getCreateLink(resourceType) {
    switch (resourceType) {
      case "estimations":
        return "/debut-parcours";
      case "financingPlans":
        return "/plan-financement/fdc";
      case "creditSimulations":
        return "/simulateur-credit";
      default:
        return "/";
    }
  }

  return (
    <>
      <div className='tab-content-header'>
        <h2>
          <activeTab.Icon />
          {activeTab.label}
        </h2>
        <p>
          Retrouvez ici tout{isResourceMasculine(activeTab.name) ? "" : "e"}s les{" "}
          {getResourceName(activeTab.name, true)} lié{isResourceMasculine(activeTab.name) ? "" : "e"}s à votre dossier.
        </p>
      </div>
      <div className='folder-resources-buttons'>
        <FilledButton to={getCreateLink(activeTab.name)}>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un{isResourceMasculine(activeTab.name) ? "" : "e"}{" "}
          {getResourceName(activeTab.name)}
        </FilledButton>
        <FilledButton onClick={() => showImportResourceModal(activeTab)}>
          <Download width={width > 576 ? "30px" : "20px"} /> Importer un{isResourceMasculine(activeTab.name) ? "" : "e"}{" "}
          {getResourceName(activeTab.name)}
        </FilledButton>
      </div>
      <div className='folder-resources-container'>
        {resources && (
          <ResourceTable
            dropdownMenu={tabComponents.dropdownMenu[activeTab.name]({
              openInNewTab: true,
              activeItem: dropdownItem?.item,
              downloadItemPdf,
              showRemoveResourceFromFolderModal: resource =>
                showRemoveResourceFromFolderModal(resource, folder.uuid, activeTab.name, triggerFolderUpdate),
            })}
            resetOrderByTrigger={resetOrderByTrigger}
            resources={resources}
            resourceTypeDescription={activeTab.name}
            selectedResource={dropdownItem}
            setSelectedResourceFunction={setActiveItem}
            tableColumns={tableColumns}
            getColumnContentFunction={tabComponents.getColumnContent[activeTab.name]}
          />
        )}
        {resources.length > 0 && (
          <UnfilledButton
            className='folder-delete-resources-button'
            bgColor='var(--dark-red)'
            onClick={() => showRemoveAllResourcesFromFolderModal(folder.uuid, activeTab.name, triggerFolderUpdate)}>
            Retirer tou{isResourceMasculine(activeTab.name) ? "" : "te"}s les{" "}
            {getResourceName(activeTab.name, true, true)}
          </UnfilledButton>
        )}
      </div>
    </>
  );
}

export default FolderResources;
