import "./AccountFolders.css";
import FilledButton from "../../../../../components/molecules/buttons/filledButton/FilledButton";
import Searchbar from "../../../../../components/molecules/searchbar/Searchbar";
import Add from "../../../../../components/atoms/icons/general/add/Add";
import React, { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import AppContext from "../../../../../context/AppContext";
import { useAuth } from "../../../../../context/AuthContext";
import CreateFolderModal from "../../../../../components/forms/modals/account/folders/createFolderModal/CreateFolderModal";
import Tuto from "../../../../../components/atoms/icons/general/tuto/Tuto";
import ReviewButton from "../../../../../components/molecules/buttons/reviewButton/ReviewButton";
import { stringToDate } from "../../../../../utils/Utils";
import { useResources } from "../../../../../context/ResourcesContext";
import ResourceTable from "../../../../../components/organisms/resourceTable/ResourceTable";
import {
  filterFolders,
  folderDropdownMenu,
  FolderTableColumns,
  getFolderColumnContent,
} from "../../../../../components/organisms/resourcesComponents/FoldersComponents";
import Loader from "../../../../../components/organisms/loader/Loader";

function AccountFolders() {
  const { setModalContent, setModalVisible, showTooltip } = useContext(AppContext);
  const { formatTableColumns, downloadResource, archiveResource, showDuplicateResourceModal, fetchUserResource } =
    useResources();
  const { getUuid } = useAuth();
  const { width } = useWindowSize();

  const folderComponents = {
    dropdownMenu: folderDropdownMenu,
    tableColumns: FolderTableColumns,
    getColumnContent: getFolderColumnContent,
    filter: filterFolders,
  };

  const [searchValue, setSearchValue] = useState("");
  const [tableColumns, setTableColumns] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [folders, setFolders] = useState([]);
  const [visibleFolders, setVisibleFolders] = useState([]);
  const [resetSearchValueTrigger, setResetSearchValueTrigger] = useState(false);
  const [dropdownFolder, setDropdownFolder] = useState(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    setTableColumns(
      formatTableColumns(folderComponents.tableColumns, width).filter(column => column.key !== "archived"),
    );
  }, [width]);

  useEffect(() => {
    setResetSearchValueTrigger(!resetSearchValueTrigger);
    setVisibleFolders(getFilteredFolders(folders));
  }, [activeTab]);

  async function fetchFolders() {
    const folders = await fetchUserResource(getUuid(), "folders");

    setFolders(folders);
    setVisibleFolders(folders.filter(folder => folder.archived === !!activeTab));
    setIsLoaderVisible(false);
  }

  function getFilteredFolders(folders) {
    return folders.filter(folder => folder.archived === !!activeTab);
  }

  function showCreateFolderModal() {
    setModalContent({
      title: "Créer un nouveau dossier",
      content: <CreateFolderModal fetchFoldersFunction={fetchFolders} getUuid={getUuid} />,
    });
    setModalVisible(true);
  }

  function setActiveFolder(e, folder, index, itemsContainerRef) {
    if (!e) return setDropdownFolder(null);

    e.stopPropagation();
    e.preventDefault();

    if (folder === dropdownFolder?.item) return setDropdownFolder(null);

    setDropdownFolder({
      item: folder,
      itemRef: itemsContainerRef.current.children[index],
      top: itemsContainerRef.current.children[index].offsetTop + 80,
    });
  }

  function getOrderedFolders(folders, orderBy) {
    let result = folderComponents.filter(folders, searchValue);

    if (!orderBy.key) return result;

    result.sort((a, b) => {
      let valA = folderComponents.getColumnContent(orderBy.key, a);
      let valB = folderComponents.getColumnContent(orderBy.key, b);

      if (orderBy.key.includes("date_")) {
        valA = valA === "-" ? new Date() : stringToDate(valA + ":00");
        valB = valB === "-" ? new Date() : stringToDate(valB + ":00");
        return valA - valB;
      }

      if (typeof valA === "number" && typeof valB === "number") {
        return valA - valB;
      }

      return String(valA).localeCompare(String(valB), "fr", { numeric: true });
    });

    if (orderBy.order === "desc") result.reverse();

    return result;
  }

  // Quick actions

  function downloadItemPdf(folder) {
    downloadResource(folder, "folders");
  }

  function showDuplicateItemModal(folder) {
    showDuplicateResourceModal(folder, "folders", () => fetchFolders());
  }

  function archiveItem(folder, shouldArchive) {
    archiveResource(folder, shouldArchive, "folders", fetchFolders);
  }

  return (
    <>
      <section className='container'>
        <div className='head-row-container'>
          <FilledButton onClick={showCreateFolderModal} className='mid-page-button folders-button'>
            <Add width='30px' /> Créer un nouveau dossier
          </FilledButton>
          <ReviewButton
            modalTitle='Votre avis sur nos dossiers'
            modalText='un dossier'
            link='https://docs.google.com/forms/d/e/1FAIpQLSdRr400a39dx9vsAd8lVmpibeV2_1N1GmsdaA3zbvoMATffGQ/viewform?usp=pp_url'>
            Donnez votre avis sur nos dossiers
          </ReviewButton>
        </div>
        <div className='folders-container'>
          <div className='folders-container-header'>
            <p className={activeTab === 0 ? "active" : ""} onClick={() => setActiveTab(0)}>
              Mes dossiers
            </p>
            <p className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
              Mes dossiers archivés
            </p>
            <Tuto className='folder-icon-tuto' onClick={() => showTooltip("infos_tab.webp")} />
          </div>
          <div className='search-input-container'>
            <Searchbar
              resetValueTrigger={resetSearchValueTrigger}
              onChange={value => setSearchValue(value)}
              placeholder='Rechercher'
              bgColor='var(--white)'
              className='folders-search-bar'
            />
          </div>
          <ResourceTable
            dropdownMenu={folderComponents.dropdownMenu({
              activeItem: dropdownFolder?.item,
              downloadItemPdf,
              showDuplicateItemModal,
              archiveItem,
            })}
            resourceTypeDescription='folders'
            resources={visibleFolders}
            tableColumns={tableColumns}
            useCheckbox={false}
            selectedResource={dropdownFolder}
            setSelectedResourceFunction={setActiveFolder}
            getColumnContentFunction={folderComponents.getColumnContent}
            getOrderedResourcesFunction={getOrderedFolders}
          />
          <Loader visible={isLoaderVisible} className='tools-loader' preventScrolling={false} />
        </div>
      </section>
    </>
  );
}

export default AccountFolders;
