import React, { createContext, useContext } from "react";
import AppContext from "./AppContext";
import RegisterForm from "../components/forms/account/registerForm/RegisterForm";
import FilledButton from "../components/molecules/buttons/filledButton/FilledButton";
import LoginForm from "../components/forms/account/loginForm/LoginForm";
import UnfilledButton from "../components/molecules/buttons/unfilledButton/UnfilledButton";
import { useAuth } from "./AuthContext";

const ModalsContext = createContext();

export function useModals() {
  return useContext(ModalsContext);
}

export function ModalsProvider({ children }) {
  const { setModalContent, setModalVisible } = useContext(AppContext);
  const { getUuid } = useAuth();

  function showRegisterModal(
    buttonText,
    title = "pour enregistrer votre travail",
    callback = () => {
      setModalVisible(false);
    },
    showLoginModalOverride,
  ) {
    const switchToLogin = showLoginModalOverride || showLoginModal;

    setModalContent({
      title: "Inscrivez-vous " + title,
      content: (
        <>
          <div className='flex f-center gap-md'>
            <h2 className='h2-uppercase text-center'>Vous avez déjà un compte ?</h2>
            <FilledButton padding='10px 25px' onClick={() => switchToLogin(buttonText, title)} className='mb-sm'>
              Se connecter
            </FilledButton>
          </div>
          <RegisterForm isModal={true} buttonText={buttonText} onSuccess={callback} />
        </>
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  function showLoginModal(
    buttonText,
    title = "pour enregistrer votre travail",
    callback = () => {
      setModalVisible(false);
    },
    showRegisterModalOverride,
  ) {
    const switchToRegister = showRegisterModalOverride || showRegisterModal;

    setModalContent({
      title: "Connectez-vous " + title,
      content: (
        <>
          <div className='flex f-center gap-md'>
            <h2 className='h2-uppercase text-center'>Pas encore de compte ?</h2>
            <FilledButton padding='10px 25px' onClick={() => switchToRegister(buttonText, title)} className='mb-sm'>
              S'inscrire
            </FilledButton>
          </div>
          <LoginForm isModal={true} buttonText={buttonText} onSuccess={callback} />
        </>
      ),
      className: "big-modal",
    });

    setModalVisible(true);
  }

  function showTarifsModal(title, content, fallbackURL) {
    setModalContent({
      title: title,
      content: (
        <>
          {content}
          {!getUuid() && (
            <p className='link text-blue centered cursor-pointer mt-lg' onClick={() => showLoginModal()}>
              Déjà abonné ? Je me connecte.
            </p>
          )}
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton to={`/tarifs?${fallbackURL ? "fallback-url=" + fallbackURL : ""}`} padding='10px 25px'>
              Consulter les offres
            </FilledButton>
          </div>
        </>
      ),
    });

    setModalVisible(true);
  }

  return (
    <ModalsContext.Provider value={{ showRegisterModal, showTarifsModal, showLoginModal, showTarifsModal }}>
      {children}
    </ModalsContext.Provider>
  );
}
