import { useWindowSize } from "@uidotdev/usehooks";
import Deliverable from "../../../../../../../components/atoms/icons/folder/deliverable/Deliverable";
import Add from "../../../../../../../components/atoms/icons/general/add/Add";
import Download from "../../../../../../../components/atoms/icons/general/download/Download";
import CreateDeliverableModal from "../../../../../../../components/forms/modals/account/createDeliverableModal/CreateDeliverableModal";
import FilledButton from "../../../../../../../components/molecules/buttons/filledButton/FilledButton";
import AppContext from "../../../../../../../context/AppContext";
import "./FolderDeliverables.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import Bracket from "../../../../../../../components/atoms/icons/general/bracket/Bracket";
import DropdownMenu from "../../../../../../../components/molecules/dropdownMenu/DropdownMenu";
import { DeliverablesDropdownMenu } from "../../../../../../../components/organisms/resourcesComponents/DeliverablesComponents";
import UpdateDeliverableModal from "../../../../../../../components/forms/modals/account/deliverables/updateDeliverableModal/UpdateDeliverableModal";
import { useResources } from "../../../../../../../context/ResourcesContext";
import UnfilledButton from "../../../../../../../components/molecules/buttons/unfilledButton/UnfilledButton";
import { deleteDeliverableApi } from "../../../../../../../api/FolderApi";
import DeliverableContent from "../../../../../../../components/organisms/deliverableContent/DeliverableContent";
import { toggleCollapseElement } from "../../../../../../../utils/Utils";

function FolderDeliverables({ folder, triggerFolderUpdate = () => {} }) {
  const { setModalContent, setModalVisible, createNotification } = useContext(AppContext);
  const { width } = useWindowSize();
  const { showDuplicateResourceModal, downloadResource } = useResources();

  const deliverableContentRefs = useRef({});
  const deliverablesContainerRef = useRef(null);
  const [openedDeliverableUuid, setOpenedDeliverableUuid] = useState(null);
  const [dropdownDeliverable, setDropdownDeliverable] = useState(null);
  const [openedDeliverableMaxHeight, setOpenedDeliverableMaxheight] = useState("");

  useEffect(() => {}, [openedDeliverableUuid]);

  function openCreateDeliverableModal() {
    if (!folder) return;

    setModalContent({
      title: "Créer un livrable",
      content: <CreateDeliverableModal folder={folder} onSuccess={triggerFolderUpdate} />,
      className: "big-modal",
    });
    setModalVisible(true);
  }

  function downloadFolder() {
    downloadResource(folder, "folders");
  }

  function toggleDropdownDeliverable(e, deliverable, index) {
    if (!e) return setDropdownDeliverable(null);

    e.stopPropagation();
    e.preventDefault();

    if (deliverable === dropdownDeliverable?.item) return setDropdownDeliverable(false);

    setDropdownDeliverable({
      item: deliverable,
      itemRef: deliverablesContainerRef.current.children[index],
      top: deliverablesContainerRef.current.children[index].offsetTop + 70,
    });
  }

  function toggleOpenedDeliverable(deliverableUuid) {
    if (openedDeliverableUuid) {
      deliverableContentRefs.current[deliverableUuid].style.maxHeight = openedDeliverableMaxHeight;
      setTimeout(() => {
        toggleCollapseElement(deliverableContentRefs.current[openedDeliverableUuid], false);
      }, 10);
    }
    if (deliverableUuid == openedDeliverableUuid) {
      return setOpenedDeliverableUuid(null);
    }

    if (deliverableUuid) {
      toggleCollapseElement(deliverableContentRefs.current[deliverableUuid], true);
      setOpenedDeliverableMaxheight(deliverableContentRefs.current[deliverableUuid].style.maxHeight);
      setTimeout(() => {
        deliverableContentRefs.current[deliverableUuid].style.maxHeight = "unset";
      }, 300);
    }
    setOpenedDeliverableUuid(deliverableUuid);
  }

  function showRenameDeliverableModal(deliverable) {
    setModalContent({
      title: "Renommer le livrable",
      content: <UpdateDeliverableModal deliverable={deliverable} onSuccess={triggerFolderUpdate} />,
    });

    setModalVisible(true);
  }

  function showDuplicateDeliverableModal(deliverable) {
    showDuplicateResourceModal(deliverable, "deliverables", triggerFolderUpdate);
  }

  function showDeleteDeliverableModal(deliverable) {
    setModalContent({
      title: "⚠️ Attention",
      content: (
        <>
          <p>
            Êtes-vous sûr de vouloir supprimer ce livrable ?<br />
            Cette action est irréversible.
          </p>
          <div className='modal-buttons-row'>
            <UnfilledButton padding='10px 25px' onClick={() => setModalVisible(false)}>
              Annuler
            </UnfilledButton>
            <FilledButton
              padding='10px 25px'
              onClick={() => {
                deleteDeliverable(deliverable);
              }}>
              Retirer
            </FilledButton>
          </div>
        </>
      ),
    });

    setModalVisible(true);
  }

  async function deleteDeliverable(deliverable) {
    try {
      await deleteDeliverableApi(deliverable.uuid);

      triggerFolderUpdate();

      createNotification(<>Le livrable a été supprimé avec succès</>);
    } catch (error) {
      createNotification(
        <>Une erreur est survenue lors de la suppression du livrable</>,
        "var(--red)",
        "var(--dark-blue)",
      );
    } finally {
      setModalVisible(false);
    }
  }

  function setupRef(el, deliverableUuid) {
    if (el) deliverableContentRefs.current[deliverableUuid] = el;
    else delete deliverableContentRefs.current[deliverableUuid];
  }

  return (
    <>
      <div className='tab-content-header'>
        <h2>
          <Deliverable />
          Livrables
        </h2>
        <p>
          Créez votre / vos livrable(s) pour vos vendeurs, acquéreurs ou clients, à partir des éléments de ce dossier.
          <br />
          Vous pouvez créer autant de livrables que vous le souhaitez.
        </p>
      </div>
      <div className='folder-resources-buttons'>
        <FilledButton onClick={openCreateDeliverableModal}>
          <Add width={width > 576 ? "30px" : "20px"} /> Créer un livrable
        </FilledButton>
        <FilledButton bgColor='#B8C7FF' color='var(--dark-blue)' onClick={downloadFolder}>
          <Download color='var(--dark-blue)' width={width > 576 ? "30px" : "20px"} /> Télécharger tous les livrables
        </FilledButton>
      </div>
      <div className='deliverables-container'>
        {folder?.deliverables?.length ? (
          <>
            <DropdownMenu
              itemsContainerRef={deliverablesContainerRef}
              selectedResource={dropdownDeliverable}
              closeDropdown={() => toggleDropdownDeliverable(null)}>
              <DeliverablesDropdownMenu
                activeDeliverable={dropdownDeliverable}
                openDeliverable={toggleOpenedDeliverable}
                isDeliverableOpened={
                  openedDeliverableUuid === dropdownDeliverable?.item?.uuid && openedDeliverableUuid !== null
                }
                showRenameDeliverableModal={showRenameDeliverableModal}
                showDuplicateDeliverableModal={showDuplicateDeliverableModal}
                showDeleteDeliverableModal={showDeleteDeliverableModal}
              />
            </DropdownMenu>
            <div className='folder-deliverables-container' ref={deliverablesContainerRef}>
              {folder.deliverables.map((deliverable, index) => (
                <div key={deliverable.uuid} className='deliverable-container'>
                  <div className='deliverable-head' onClick={() => toggleOpenedDeliverable(deliverable.uuid)}>
                    <h3>
                      <Deliverable />
                      {deliverable.name}
                    </h3>
                    <div
                      className='deliverable-bracket'
                      onClick={e => toggleDropdownDeliverable(e, deliverable, index)}>
                      <Bracket
                        color='var(--black)'
                        width='20px'
                        rotation={dropdownDeliverable?.item?.uuid === deliverable.uuid ? "0deg" : "180deg"}
                      />
                    </div>
                  </div>
                  <div className='deliverable-body' ref={el => setupRef(el, deliverable.uuid)}>
                    <DeliverableContent
                      folder={folder}
                      triggerFolderUpdate={triggerFolderUpdate}
                      deliverable={deliverable}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <p className='centered text-lg m-auto'>Aucun livrable dans ce dossier</p>
        )}
      </div>
    </>
  );
}

export default FolderDeliverables;
