import { Link } from "react-router-dom";
import { normalizeString } from "../../../utils/Utils";
import Pen from "../../atoms/icons/account/pen/Pen";
import Download from "../../atoms/icons/general/download/Download";
import Duplicate from "../../atoms/icons/account/duplicate/Duplicate";
import Unarchive from "../../atoms/icons/account/unarchive/Unarchive";
import Archive from "../../atoms/icons/account/archive/Archive";
import Trash from "../../atoms/icons/general/trash/Trash";
import Eye from "../../atoms/icons/general/eye/Eye";
import FilledButton from "../../molecules/buttons/filledButton/FilledButton";
import Add from "../../atoms/icons/general/add/Add";
import ReviewButton from "../../molecules/buttons/reviewButton/ReviewButton";

export const EstimationTableColumns = [
  {
    id: 1,
    label: "UUID",
    key: "uuid",
    default: 0,
  },
  {
    id: 2,
    label: "Enseigne",
    key: "name",
    default: 0,
    showBracket: true,
  },
  {
    id: 3,
    label: "Type",
    key: "type",
    default: 576,
  },
  {
    id: 4,
    label: "Adresse",
    key: "address",
    default: 992,
    showBracket: true,
  },
  {
    id: 6,
    label: "Code postal",
    key: "zip_code",
  },
  {
    id: 11,
    label: "Activité",
    key: "activite",
    showBracket: true,
  },
  {
    id: 9,
    label: "Création",
    key: "date_creation_estimation",
    default: 1400,
    showBracket: true,
  },
  {
    id: 10,
    default: 992,
    label: "Mise à jour",
    key: "date_last_update_estimation",
    showBracket: true,
  },
  {
    id: 7,
    label: "",
    key: "archived",
    default: 576,
  },
  {
    id: 8,
    label: "",
    key: "validated",
    default: 576,
  },
];

export function filterEstimations(items, searchValue) {
  let normalizedSearchValue = normalizeString(searchValue);

  return items?.filter(item => {
    if (
      item.uuid == searchValue ||
      normalizeString(item.name).includes(normalizedSearchValue) ||
      normalizeString(item.infos?.ville).includes(normalizedSearchValue) ||
      normalizeString(item.infos?.rue).includes(normalizedSearchValue) ||
      normalizeString(item.type?.description).includes(normalizedSearchValue)
    )
      return true;

    return false;
  });
}

export function getEstimationColumnContent(key, item) {
  switch (key) {
    case "activite":
      return item.infos?.activite?.description ?? "";
    case "enseigne":
      return item.infos?.enseigne ?? "" + item.infos?.nom ?? "";
    case "address":
      return `${item.infos?.rue} ${item.infos?.ville}`;
    case "date_last_update_estimation":
    case "date_creation_estimation":
      return item[key] || "-";
    default:
      return item[key];
  }
}

export const EstimationsDropdownMenu = ({
  activeItem,
  openInNewTab,
  downloadItemPdf,
  showDuplicateItemModal,
  archiveItem,
  showRemoveResourceFromFolderModal,
  showRemoveResourceFromDeliverableModal,
}) => (
  <>
    <li>
      <Link
        to={`/synthese/${activeItem?.uuid}`}
        onClick={e => {
          if (!openInNewTab) return;

          e.preventDefault();
          window.open(`/synthese/${activeItem?.uuid}`);
        }}>
        <Pen width='18px' />
        Modifier
      </Link>
    </li>
    {activeItem?.validated ? (
      <>
        {downloadItemPdf && (
          <li>
            <button onClick={() => downloadItemPdf(activeItem)}>
              <Download color='var(--dark-blue)' width='18px' />
              Télécharger
            </button>
          </li>
        )}
        <li>
          <Link to={`/pdf/${activeItem?.uuid}`} target='_blank'>
            <Eye color='var(--dark-blue)' width='18px' />
            Prévisualiser
          </Link>
        </li>
        {showDuplicateItemModal && (
          <li>
            <button onClick={() => showDuplicateItemModal(activeItem)}>
              <Duplicate width='18px' />
              Dupliquer
            </button>
          </li>
        )}
      </>
    ) : (
      <>
        {downloadItemPdf && (
          <li>
            <button className='disabled-button-dropdown-item tooltip-item' data-tooltip='Estimation non terminée'>
              <Download width='18px' color='var(--dark-blue)' />
              Télécharger
            </button>
          </li>
        )}
        {showDuplicateItemModal && (
          <li>
            <button className='disabled-button-dropdown-item tooltip-item' data-tooltip='Estimation non terminée'>
              <Duplicate width='18px' />
              Dupliquer
            </button>
          </li>
        )}
      </>
    )}
    {showRemoveResourceFromFolderModal && (
      <li>
        <button onClick={() => showRemoveResourceFromFolderModal(activeItem)}>
          <Trash width='18px' />
          Retirer du dossier
        </button>
      </li>
    )}
    {showRemoveResourceFromDeliverableModal && (
      <li>
        <button onClick={() => showRemoveResourceFromDeliverableModal(activeItem)}>
          <Trash width='18px' />
          Retirer du livrable
        </button>
      </li>
    )}
    {archiveItem &&
      (activeItem?.archived ? (
        <li>
          <button onClick={() => archiveItem(activeItem, false)}>
            <Unarchive width='18px' />
            Désarchiver
          </button>
        </li>
      ) : (
        <li>
          <button onClick={() => archiveItem(activeItem, true)}>
            <Archive width='18px' />
            Archiver
          </button>
        </li>
      ))}
  </>
);

export const EstimationCreateButton = () => (
  <FilledButton to='/debut-parcours' className='mid-page-button items-button'>
    <Add width='30px' /> Démarrer une nouvelle estimation
  </FilledButton>
);

export const EstimationReviewButton = ({ text, link }) => (
  <ReviewButton modalTitle='Votre avis sur nos estimations' modalText='une estimation' link={link}>
    {text}
  </ReviewButton>
);
