import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import GuidesAgentsImmos from "../pages/articles/guidesAgentsImmos/GuidesAgentsImmos";
import { HelmetProvider } from "react-helmet-async";
import "../stylesheets/main.css";
import Home from "../pages/autres/home/Home";
import ConseilsPros from "../pages/articles/conseilsPros/ConseilsPros";
import ConseilsProsArticle from "../pages/articles/conseilsProsArticle/ConseilsProsArticle";
import { getScrollbarWidth } from "../utils/Utils";
import Faq from "../pages/tools/faq/Faq";
import AppContext from "../context/AppContext";
import { CartProvider } from "../context/CartContext";
import { AuthProvider } from "../context/AuthContext";
import Modal from "../components/organisms/modal/Modal";
import GuidesAgentsImmosArticle from "../pages/articles/guidesAgentsImmosArticle/GuidesAgentsImmosArticle";
import ScrollToTop from "../utils/ScrollToTop";
import Tarifs from "../pages/autres/tarifs/Tarifs";
import Contact from "../pages/autres/contact/Contact";
import Notification from "../components/organisms/notification/Notification";
import Test from "../pages/test/Test";
import DevelopmentPage from "../pages/errors/developmentPage/DevelopmentPage";
import PolitiqueConfidentialite from "../pages/legal/politiqueConfidentialite/PolitiqueConfidentialite";
import MentionsLegales from "../pages/legal/mentionsLegales/MentionsLegales";
import Cgu from "../pages/legal/cgu/Cgu";
import Cgv from "../pages/legal/cgv/Cgv";
import Loader from "../components/organisms/loader/Loader";
import Header from "../components/organisms/header/Header";
import Footer from "../components/organisms/footer/Footer";
import ErrorPage from "../pages/errors/errorPage/ErrorPage";
import { useWindowSize } from "@uidotdev/usehooks";
import DetailParcours from "../pages/estimations/parcours/detailParcours/DetailParcours";
import ParcoursStarter from "../pages/estimations/parcours/parcoursStarter/ParcoursStarter";
import Overview from "../pages/estimations/parcours/overview/Overview";
import { CookiesProvider } from "react-cookie";
import CookieConsentModal from "../components/forms/cookies/cookiesModal/CookieConsentModal";
import ArticlesEditor from "../pages/admin/articlesEditor/ArticlesEditor";
import Access from "../pages/autres/access/Access";
import RequireCode from "../pages/autres/access/RequireCode";
import ProtectedPage from "../utils/ProtectedPage";
import Method from "../pages/estimations/method/Method";
import ReportExamples from "../pages/estimations/reportExamples/ReportExamples";
import ActivityList from "../pages/estimations/activityList/ActivityList";
import Glossary from "../pages/tools/glossary/Glossary";
import CreditSimulator from "../pages/calculators/creditSimulator/CreditSimulator";
import ContributionCalculator from "../pages/calculators/contributionCalculator/ContributionCalculator";
import Account from "../pages/account/account/Account";
import Register from "../pages/account/register/Register";
import Cart from "../pages/cart/cart/Cart";
import Order from "../pages/cart/order/Order";
import CookiesSetup from "../pages/legal/cookiesSetup/CookiesSetup";
import Login from "../pages/account/login/Login";
import Pdf from "../pages/estimations/pdf/Pdf";
import FinancingPlan from "../pages/calculators/financingPlan/FinancingPlan";
import NotificationDownloadPdf from "../components/pdfComponents/notificationDownloadPdf/NotificationDownloadPdf";
import PracticalTools from "../pages/tools/practicalTools/PracticalTools";
import TooltipModal from "../components/organisms/tooltipModal/TooltipModal";
import GlobalReviewNotification from "../components/organisms/notification/GlobalReviewNotification";
import CrmViewer from "../pages/admin/crm/crmViewer/CrmViewer";
import CrmDetail from "../pages/admin/crm/crmDetail/CrmDetail";
import CrmMenu from "../pages/admin/crm/crmMenu/CrmMenu";
import { CrmProvider } from "../context/CrmContext";
import ObtainFinancing from "../pages/calculators/obtainFinancing/ObtainFinancing";
import Breadcrumbs from "../components/molecules/breadcrumbs/Breadcrumbs";
import Banner from "../components/molecules/banner/Banner";
import { ModalsProvider } from "../context/ModalsContext";
import HeaderSEO from "../components/organisms/headerSEO/HeaderSEO";
import { ResourcesProvider } from "../context/ResourcesContext";

function App() {
  const [modalVisible, _setModalVisible] = useState(false);
  const [canHideOnClickOutside, setCanHideOnClickOutside] = useState(true);
  const [modalContent, setModalContent] = useState({ title: "", content: <></>, buttons: <></>, className: "" });
  const [isDownloadNotificationLoading, setIsDownloadNotificationLoading] = useState(false); // chargement de la page
  const [appLoaderVisible, setAppLoaderVisible] = useState(false);
  const [mustAppLoaderShow, setMustAppLoaderShow] = useState(false);
  const [tooltipModalVisible, setTooltipModalVisible] = useState(false);
  const [tooltipModalContent, setTooltipModalContent] = useState("");
  const [bannerConfig, setBannerConfig] = useState({ title: <></>, subtitle: <></> });
  const [breadcrumbsSpecialValue, setBreadcrumbsSpecialValue] = useState(null);
  const [headerSEOSpecialValue, setHeaderSEOSpecialValue] = useState(null);

  const createNotificationFunction = useRef(null);
  const tooltipModalRef = useRef(null);

  const { width } = useWindowSize();

  // DARK MODE DETECTION
  // useEffect(() => {
  //   let isDarkModeEnabled = false;

  //   setTimeout(() => {
  //     const styleTags = document.querySelectorAll("style");
  //     styleTags.forEach(styleTag => {
  //       if (styleTag.id.includes("dark-mode")) {
  //         isDarkModeEnabled = true;
  //       }
  //     });

  //     if (!isDarkModeEnabled) return;

  //     setModalContent({
  //       title: "Extension détectée",
  //       content: (
  //         <>
  //           <p>
  //             Une extension de mode sombre pouvant altérer l'apparence du site a été détectée.
  //             <br />
  //             Pensez à la désactiver si vous rencontrez des problèmes de navigation.
  //           </p>
  //           <div className='modal-buttons-row'>
  //             <FilledButton onClick={() => setModalVisible(false)}>OK</FilledButton>
  //           </div>
  //         </>
  //       ),
  //     });
  //     setModalVisible(true);
  //   }, 500);
  // }, []);

  // useEffect(() => {
  //   const styleTags = document.querySelectorAll("style");
  //   styleTags.forEach(styleTag => {
  //     if (styleTag.id.includes("dark-mode")) {
  //       styleTag.parentNode.removeChild(styleTag);
  //     }
  //   });
  // }, [window.location.pathname]);

  useEffect(() => {
    // if (appLoaderVisible || (!appLoaderVisible && !mustAppLoaderShow)) togglePageScroll();
  }, [appLoaderVisible, mustAppLoaderShow]);

  useEffect(() => {
    document.body.style.setProperty("--scrollbar-width", getScrollbarWidth() + "px");
  }, [width]);

  useEffect(() => {
    if (appLoaderVisible) {
      setMustAppLoaderShow(true);
      setTimeout(() => {
        setMustAppLoaderShow(false);
      }, 800);
    }
  }, [appLoaderVisible]);

  useEffect(() => {
    if (!modalVisible) {
      setCanHideOnClickOutside(true);
    }
  }, [modalVisible]);

  useEffect(() => {
    showReviewNotificationAfterDelay();
  }, [createNotificationFunction]);

  function showReviewNotificationAfterDelay(delay = 600000) {
    setTimeout(() => {
      if (createNotificationFunction.current) {
        createNotificationFunction.current(
          <GlobalReviewNotification />,
          "var(--white)",
          "var(--dark-blue)",
          null,
          "review-notification",
          () => showReviewNotificationAfterDelay(),
        );
      }
    }, delay);
  }

  function setModalVisible(visible, canClickOutside = true) {
    setCanHideOnClickOutside(canClickOutside);
    _setModalVisible(visible);
    if (!visible) setModalContent({ title: "", content: <></>, buttons: <></> });
  }

  function showTooltip(name) {
    if (tooltipModalRef.current) tooltipModalRef.current.scrollTo({ top: 0, behavior: "instant" });

    setTooltipModalContent(name);
    setTooltipModalVisible(true);
  }

  function togglePageScroll() {
    if (appLoaderVisible) {
      document.body.classList.add("loading-page");
    } else {
      document.body.classList.remove("loading-page");
    }
  }

  function toggleBodyScroll(enable) {
    if (enable) document.body.classList.remove("non-scrollable-body");
    else document.body.classList.add("non-scrollable-body");
  }

  function createNotification(content, bgColor = "var(--blue)", color = "var(--white)", visibleTime = 5000) {
    if (createNotificationFunction.current) createNotificationFunction.current(content, bgColor, color, visibleTime);
  }

  return (
    <CookiesProvider>
      <AppContext.Provider
        value={{
          setModalContent,
          showTooltip,
          setModalVisible,
          createNotification,
          setAppLoaderVisible,
          toggleBodyScroll,
          setIsDownloadNotificationLoading,
          isDownloadNotificationLoading,
          setBannerConfig,
          setBreadcrumbsSpecialValue,
          setHeaderSEOSpecialValue,
        }}>
        <AuthProvider>
          <ModalsProvider>
            <ResourcesProvider>
              <Notification createNotificationFunction={createNotificationFunction} />
              <NotificationDownloadPdf isLoading={isDownloadNotificationLoading} />
              <Loader visible={appLoaderVisible || mustAppLoaderShow} className='app-loader' />
              <HelmetProvider>
                <BrowserRouter
                  future={{
                    v7_startTransition: true,
                    v7_relativeSplatPath: true,
                  }}>
                  <HeaderSEO specialValue={headerSEOSpecialValue} setSpecialValue={setHeaderSEOSpecialValue} />
                  <Modal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    title={modalContent.title}
                    className={modalContent.className}
                    canHideOnClickOutside={canHideOnClickOutside}>
                    {modalContent.content}
                    {modalContent.buttons && <div className='modal-buttons-row'>{modalContent.buttons}</div>}
                  </Modal>
                  <TooltipModal
                    isVisible={tooltipModalVisible}
                    tooltip={tooltipModalContent}
                    setTooltip={setTooltipModalContent}
                    setIsVisible={setTooltipModalVisible}
                    ref={tooltipModalRef}
                  />
                  <CookieConsentModal />
                  <CartProvider>
                    <CrmProvider>
                      <ScrollToTop />
                      <Routes>
                        {/* Autres */}
                        <Route path='/access' element={<Access />} />
                        <Route
                          path='/*'
                          element={
                            <RequireCode>
                              <Header />
                              {bannerConfig && (
                                <section className='container'>
                                  <Breadcrumbs specialValue={breadcrumbsSpecialValue} />
                                  <Banner
                                    className={bannerConfig.className ?? ""}
                                    title={bannerConfig.title}
                                    subtitle={bannerConfig.subtitle}
                                  />
                                </section>
                              )}
                              <Routes>
                                <Route path='/' element={<Home />} />

                                {/* Liens légaux */}
                                <Route path='/politique-confidentialite' element={<PolitiqueConfidentialite />} />
                                <Route path='/mentions-legales' element={<MentionsLegales />} />
                                <Route path='/parametrage-cookies' element={<CookiesSetup />} />
                                <Route path='/cgu' element={<Cgu />} />
                                <Route path='/cgv' element={<Cgv />} />

                                {/* Estimations */}
                                <Route path='/debut-parcours' element={<ParcoursStarter />} />
                                <Route path='/synthese/:estimationIdParam' element={<Overview />} />
                                <Route
                                  path='/detail-parcours/:parcoursTypeParam/:estimationIdParam'
                                  element={<DetailParcours />}
                                />
                                <Route path='/detail-parcours/:parcoursTypeParam' element={<DetailParcours />} />
                                <Route path='/philosophie' element={<Method />} />
                                <Route path='/exemples-rapports' element={<ReportExamples />} />
                                <Route path='/liste-activites' element={<ActivityList />} />
                                <Route path='/pdf/:estimationIdParam' element={<Pdf />} />

                                {/* Outils */}
                                <Route path='/outils-pratiques' element={<PracticalTools />} />
                                <Route path='/calculateur-cotisations' element={<ContributionCalculator />} />
                                <Route path='/lexique' element={<Glossary />} />
                                <Route path='/annonces' element={<DevelopmentPage />} />
                                <Route path='/faq' element={<Faq />} />

                                {/* Conseils */}
                                <Route path='/conseils-pros' element={<ConseilsPros />} />
                                <Route path='/conseils-pros/:articleUrl' element={<ConseilsProsArticle />} />
                                <Route path='/guides-agents-immos' element={<GuidesAgentsImmos />} />
                                <Route path='/guides-agents-immos/:articleUrl' element={<GuidesAgentsImmosArticle />} />

                                {/* Simulateurs */}
                                <Route path='/simulateur-credit' element={<CreditSimulator />} />
                                <Route
                                  path='/simulateur-credit/:creditSimulationIdParam'
                                  element={<CreditSimulator />}
                                />
                                <Route
                                  path='/plan-financement'
                                  element={<Navigate to='/plan-financement/fdc' replace />}
                                />
                                <Route path='/plan-financement/:planTypeParam' element={<FinancingPlan />} />
                                <Route
                                  path='/plan-financement/:planTypeParam/:planIdParam'
                                  element={<FinancingPlan />}
                                />
                                <Route
                                  path='/obtenir-financement'
                                  element={
                                    <ProtectedPage allowedAccess='credipro_form_access'>
                                      <ObtainFinancing />
                                    </ProtectedPage>
                                  }
                                />

                                {/* Compte */}
                                <Route
                                  path='/mon-compte'
                                  element={
                                    <ProtectedPage needLogin redirection='/connexion'>
                                      <Navigate to='/mon-compte/mes-outils' replace />
                                    </ProtectedPage>
                                  }
                                />
                                <Route
                                  path='/mon-compte/:tabParam'
                                  element={
                                    <ProtectedPage needLogin redirection='/connexion'>
                                      <Account />
                                    </ProtectedPage>
                                  }
                                />
                                <Route
                                  path='/mon-compte/:tabParam/:subTabParam'
                                  element={
                                    <ProtectedPage needLogin redirection='/connexion'>
                                      <Account />
                                    </ProtectedPage>
                                  }
                                />
                                <Route path='/connexion' element={<Login />} />
                                <Route path='/inscription' element={<Register />} />
                                <Route
                                  path='/panier'
                                  element={
                                    <ProtectedPage needLogin redirection='/connexion'>
                                      <Cart />
                                    </ProtectedPage>
                                  }
                                />
                                <Route
                                  path='/commande'
                                  element={
                                    <ProtectedPage needLogin redirection='/connexion'>
                                      <Order />
                                    </ProtectedPage>
                                  }
                                />
                                <Route path='/tarifs' element={<Tarifs />} />

                                {/* Contact */}
                                <Route path='/contact' element={<Contact />} />

                                {/* Nouveautés */}
                                {/* <Route path='/news' element={<News />} />
                          <Route
                            path='/news/update/:newsId'
                            element={
                              <ProtectedPage allowedAccess={("dev", "admin")}>
                                <NewsUpdateForm />
                              </ProtectedPage>
                            }
                          />
                          <Route
                            path='/news-add'
                            element={
                              <ProtectedPage allowedAccess={("dev", "admin")}>
                                <NewsAdd />
                              </ProtectedPage>
                            }
                          /> */}

                                {/* Errors */}
                                <Route path='/301' element={<ErrorPage code={301} />} />
                                <Route path='/302' element={<ErrorPage code={302} />} />
                                <Route path='/401' element={<ErrorPage code={401} />} />
                                <Route path='/402' element={<ErrorPage code={402} />} />
                                <Route path='/403' element={<ErrorPage code={403} />} />
                                <Route path='/404' element={<ErrorPage code={404} />} />
                                <Route path='/500' element={<ErrorPage code={500} />} />
                                <Route path='/503' element={<ErrorPage code={503} />} />
                                <Route path='/504' element={<ErrorPage code={504} />} />

                                {/* Admin */}
                                <Route
                                  path='/admin/crm/editeur-articles'
                                  element={
                                    <ProtectedPage allowedAccess='article_editor_access'>
                                      <ArticlesEditor />
                                    </ProtectedPage>
                                  }
                                />
                                <Route
                                  path='/admin/crm'
                                  element={
                                    <ProtectedPage allowedAccess='crm_access'>
                                      <CrmMenu />
                                    </ProtectedPage>
                                  }
                                />
                                <Route
                                  path='/admin/crm/:tabParam'
                                  element={
                                    <ProtectedPage allowedAccess='crm_access'>
                                      <CrmViewer />
                                    </ProtectedPage>
                                  }
                                />
                                <Route
                                  path='/admin/crm/:tabParam/:uuid'
                                  element={
                                    <ProtectedPage allowedAccess='crm_access'>
                                      <CrmDetail />
                                    </ProtectedPage>
                                  }
                                />
                                <Route path='/test' element={<Test />} />

                                <Route path='*' element={<Navigate to='/404' replace />} />
                              </Routes>
                              <Footer />
                            </RequireCode>
                          }
                        />
                      </Routes>
                    </CrmProvider>
                  </CartProvider>
                </BrowserRouter>
              </HelmetProvider>
            </ResourcesProvider>
          </ModalsProvider>
        </AuthProvider>
      </AppContext.Provider>
    </CookiesProvider>
  );
}

export default App;
