import React, { useContext, useEffect } from "react";
import "./CookiesSetup.css";
import CookiesSetupForm from "../../../components/forms/cookies/cookiesSetupForm/CookiesSetupForm";
import AppContext from "../../../context/AppContext";

function CookiesSetup() {
  const { setBannerConfig } = useContext(AppContext);

  useEffect(() => {
    setBannerConfig({
      title: <>Gestion de vos préférences sur les cookies 🍪</>,
      subtitle: <>Les cookies permettent d'améliorer votre expérience de navigation</>,
    });
  }, []);

  return (
    <>
      <section className='container page-container'>
        <CookiesSetupForm />
      </section>
    </>
  );
}

export default CookiesSetup;
